<div class="location-wrapper">
  <div *ngFor="let location of locations" class="location-outer-wrapper">
    <div class="location-inner-wrapper">
      <app-location
        [value]="location.value ? (location.value | json) : ''"
        [locationDatasetName]="locationDatasetName"
        [disabled]="disabled"
        [label]="label"
        [hideKeys]="hideKeys"
        [fieldNames]="fieldNames"
        [required]="required"
        (valueChange)="locationValueChange($event, location)"
      ></app-location>
      <div *ngIf="locations.length > 1 && location.index !== 0" class="button-remove-wrap">
        <button
          (click)="removeLocation(location)"
          ref="removeRow"
          class="button-icon-round icon-w-fill"
          type="button"
          [disabled]="disabled"
          aria-label="Remove additional location"
        >
          <span class="material-icons" aria-hidden="true">delete</span>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="maxSelection > 1 && !disabled">
    <div>
      <button
        (click)="addLocation()"
        [disabled]="locations.length >= maxSelection"
        ref="addButton"
        class="button button-primary button-primary--medium"
        aria-label="Add additional location"
      >
        <i class="fa fa-plus" aria-hidden="true"></i> Add Another
      </button>
      <span class="table-message" *ngIf="locations.length >= maxSelection">
        No more locations can be added
      </span>
    </div>
  </div>
</div>
