import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IGridConfig } from "../model/grid-config.model";
import { toQueryParams } from "../utils/http.util";
import { PublicConfigService } from "./public.config.service";

@Injectable({ 
    providedIn: 'root' 
})
export class GridService {

    constructor(private httpClient: HttpClient, private publicConfigService: PublicConfigService) {

    }

    GetGridConfig(gridName: string, stageId?: string): Observable<IGridConfig> {
        return this.httpClient.get<IGridConfig>(`${this.publicConfigService.rpBaseUrl}/api/grid-config`, {
            params: toQueryParams({ gridName, stageId })
        });
    }

    SaveGridConfig(grid: any): Observable<IGridConfig> {
        return this.httpClient.post<IGridConfig>(`${this.publicConfigService.rpBaseUrl}/api/grid-config`, grid);
    }
}
