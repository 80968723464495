export interface printPageContent {
  pageType: PrintPageTypes;
  stageName: string;
  fullName: string;
}

export enum PrintPageTypes {
  PeerReviewResultsForStaff = "P2P Results For Staff",
  EvaliationReviewResultsForStaff = "EP Results For Staff",
  PeerReviewResultsForParticipants = "P2P Results For Participants",
  EvaluationReviewResultsForParticipants = "EP Results For Participants",
  Form = "Form"
}