import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html"
})
export class InputComponent implements OnInit {
  @Input() model: string;
  @Input() name: string = "Input";
  @Input() type: string = 'text';
  @Input() icon: string;
  @Input() identifier: string;
  @Input() maxLength: string = '256';
  @Input() disabled: boolean = false;
//   object? 
  @Input() inputError: boolean = false;
  @Input() noError: boolean = false;
  @Output() iconClickedEmitter = new EventEmitter<string>();
  @Output() focusOutEmitter = new EventEmitter<string>();
  @Output() keyUpEmitter = new EventEmitter<string>();
  @Output() enterUpEmitter = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  LogoAltText(value) {
    // this.unsavedDataToggle();
  }

  emitIconClick($event) {
    // TODO: this should emit event for autogenerate 
    this.iconClickedEmitter.emit(this.model);
  }

  focusOutFunction($event) {
    this.focusOutEmitter.emit(this.model);
  }

  keyUpFunction($event: KeyboardEvent){
    this.keyUpEmitter.emit(this.model);

    if (['Enter', 'NumpadEnter'].includes($event.key)) {
      this.enterUpEmitter.emit(true);
    };
  }

}
