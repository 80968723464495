import { Component, OnInit } from "@angular/core";
import { GeneralSetupService } from "../shared/services/general-setup.service";
import { DomSanitizer } from "@angular/platform-browser";
import { PublicConfigService } from "../shared/services/public.config.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  private generalSetupService: any;
  private platformFooterLinksStatic = [];

  platformFooterLinksDinamic: Array<any>;

  year = new Date().getFullYear();

  constructor(
    general: GeneralSetupService,
    private publicConfigService: PublicConfigService,
    private sanitizer: DomSanitizer
  ) {
    this.generalSetupService = general;
  }

  ngOnInit() {
    this.platformFooterLinksDinamic = this.platformFooterLinksStatic;
    this.getPlatformFooterLinks();
    this.generalSetupService.platformFooterLinksListener().subscribe((res) => {
      this.platformFooterLinksDinamic = res.length
        ? res
        : this.platformFooterLinksStatic;
    });
  }

  getPlatformFooterLinks() {
    this.generalSetupService.GetPlatformFooterLinks().subscribe(
      (response) => {
        this.generalSetupService.updatePlatformFooterLinks(response.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  challengeLink(href: string) {
    if (href.startsWith("/")) {
      return this.sanitizer.bypassSecurityTrustUrl(
        `${this.publicConfigService.feBaseUrl}${href}`
      );
    } else {
      return this.sanitizer.bypassSecurityTrustUrl(href);
    }
  }
}
