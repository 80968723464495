import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormioAppConfig,
  FormioCustomComponent,
  FormioEvent,
} from "@formio/angular";
import { BehaviorSubject } from "rxjs";
import { validationMessages } from "./model/youtube.video";
import { PlayerExtended } from "./player.extended";
import { FormioForm } from '@formio/angular';

@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"],
})
export class VideoComponent implements FormioCustomComponent<string>, OnInit {
  private initialized = false;

  private _value: string;
  @Input()
  public set value(v: string) {
    this._value = v;
    this.init();
  }

  public get value(): string {
    return this._value;
  }

  public get isValid(): boolean {
    let result = false;
    if (!this.required && this.isControlEmpty) {
      // if not required and empty - is valid
      result = true;
    } else if (!this.required && !this.isControlEmpty && this.videoId && this.isValidAfterUrlChecks && this.isValidAfterVideoChecks) {
      // if not required and not empty - follow all other checks and then - is valid
      result = true;
    } else if (this.required && !this.isControlEmpty && this.videoId && this.isValidAfterUrlChecks && this.isValidAfterVideoChecks) {
      // if required and not empty - follow all other checks and then - is valid
      result = true;
    }
    return result;
  }

  // used to check if the control is empty or not
  public isControlEmpty: boolean;

  @Input()
  input: boolean;

  @Input()
  disabled: boolean;

  @Input()
  label: string;

  @Input()
  maxVideoLength: string;

  @Input()
  required: boolean;

  @Output()
  valueChange = new EventEmitter<string>();

  @Output()
  formioEvent = new EventEmitter<FormioEvent>();

  public visible = false;
  public videoId;
  public error;

  private _loading$ = new BehaviorSubject<boolean>(false);
  private _player: PlayerExtended;
  private _videoUrl;

  // used for onApiChange event
  private onApiChangeHasPassed: boolean = false;

  // used to pass video length to different methods
  private videoDuration: number;

  // used to check if the value is valid after the URL is checked
  // valid youtube URL, length, etc..
  private isValidAfterUrlChecks: boolean;

  // used to check if the video is valid after it's information is fetched
  // only after the player loads - info checked is video length, captions, id
  private isValidAfterVideoChecks: boolean;

  // used to check if the control has been interacted with
  // prevents emitting change events on initial load of the component when video ID is available
  private isControlTouched: boolean = false;

  // a flag if the video was stopped once (after playing it)
  // needed in order to play the video for a millisecond and pull all required information about it
  private stoppedMeOnce: boolean = false;

  get loading$() {
    return this._loading$.asObservable();
  }

  get videoUrl() {
    return this._videoUrl;
  }

  set videoUrl(url: string) {
    this._videoUrl = url;
    this.videoId = null;
    this.visible = false;
    this._loading$.next(true);
    this.isControlTouched = true;
    setTimeout(() => this._setUrl(url), 200);
  }

  constructor(public config: FormioAppConfig) {
  }

  ngOnInit() {
  }

  ngOnDestroy() { }

  private _setValue(shouldEmit: boolean) {
    const videoData = {
      value: { videoId: this.videoId, videoUrl: this.videoUrl },
      valid: this.isValid,
      validationMessage: this.error,
    };
    this.value = JSON.stringify(videoData);
    // if we don't have video url, hide the loader
    if (!this.videoUrl) {
      this._loading$.next(false);
    }
    if (shouldEmit) {
      this.valueChange.emit(this.value);
      this.formioEvent.emit({ eventName: "change", data: { isChanged: true } });
    }
  }

  private _validateUrl(url: string) {
    this.error = ""; //reset before validation

    if (!url && this.required) {
      this.error = validationMessages.required(this.label);
      this.isValidAfterUrlChecks = false;
      return false;
    }

    if (!url && !this.required) {
      // empty url and not required control
      // url is not valid after checks
      this.isValidAfterUrlChecks = false;
      return false;
    }

    if (url.length > 256) {
      this.error = validationMessages.invalidUrlOrId();
      // handles properly hiding loading state when url is not valid
      this._loading$.next(false);
      this.isValidAfterUrlChecks = false;
      return false;
    }

    this.videoId = this._extractYoutubeVideoId(url);

    if (url && !this.videoId) {
      this.error = validationMessages.invalidUrlOrId();
      // handles properly hiding loading state when url is not valid
      this._loading$.next(false);
      this.isValidAfterUrlChecks = false;
      return false;
    }

    this.isValidAfterUrlChecks = true;
    return true;
  }

  private _setUrl(url: string) {
    if (url) {
      this.isControlEmpty = false;
    } else {
      this.isControlEmpty = true;
    }

    this._videoUrl = url;

    if (this._validateUrl(url)) {
      // real youtube URL

      // this is not used, remove? - this.videoId is always null in this case
      if (url && url.length < 43 && this.videoId) {
        //videoId is entered
        url = `https://www.youtube.com/watch?v=${this.videoId}`;
      }

      // prevent this from making API calls to save the video, because the player will be rendered
      // and it will emit a save call
      this._setValue(false);
    } else {
      // not a real youtube URL
      // allow this to make API calls, because the youtube player will not be rendered
      // thus value will not be emitted from video cued
      this._setValue(true);
    }
  }

  clearField() {
    this.videoUrl = '';
    // call set value to properly propagate the removal of the input and emit event, to do an autosave
    this._setValue(true);
    this._loading$.next(false);
  }

  private _extractYoutubeVideoId(url: string) {
    const re =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const matches = url.match(re);
    return matches && matches[7].length === 11
      ? matches[7]
      : url && url.length === 11
        ? url
        : "";
  }

  private _validateAfterLoad() {
    this.error = ""; //reset before validation

    if (!this.videoDuration) {
      // check if video duration is 0 - video is probably not embeddable
      if (this.videoDuration === 0) {
        if (this.disabled) {
          // if video input is disabled - we are in view mode
          this.error = `Please open the video URL in a new tab to view its content.`;
        } else {
          // if video input is not disabled - we are in edit mode
          this.error = validationMessages.noEmbedding();
        }
      } else {
        // if video duration is not 0, then url or id is not correct
        this.error = validationMessages.invalidUrlOrId();
      }
      // handles properly hiding loading state when url is not valid
      this._loading$.next(false);
      this.isValidAfterVideoChecks = false;
      return false;
    }

    if (this.maxVideoLength && this.videoDuration > Number(this.maxVideoLength)) {
      this.error = validationMessages.maxVideoLength();
      this.isValidAfterVideoChecks = false;
      return false;
    }

    let hasCaptions = this._player.getOptions().indexOf("captions") !== -1;
    if (!hasCaptions) {
      this.error = validationMessages.noCaptions();
      this.isValidAfterVideoChecks = false;
      return false;
    }

    this.isValidAfterVideoChecks = true;
    return true;
  }

  playerReady(event: any) {
    this._player = event.target;
    this._player.mute();
    // video duration needs to be fetched before the video is played briefly
    this.videoDuration = this._player.getDuration();
    if (this.videoDuration === 0) {
      // if video duration is 0, then embed is probably not enabled
      if (this._validateAfterLoad()) {
        this.visible = true;
      };
      this._loading$.next(false);
      // whenever the component is loaded first if it has value - the video is loaded and then it's cued
      // we need to avoid making an API call to save data on that first component load
      if (this.isControlTouched) {
        this._setValue(true);
      } else {
        this._setValue(false);
      }
    } else {
      this._player.playVideo();
    }
    // reset api changes flag whenever a player is ready & video stopped flag
    // this allows for multiple add, delete, add video interactions
    this.onApiChangeHasPassed = false;
    this.stoppedMeOnce = false;
  }

  playerStateChange($event: any) {
    // $event.data order firing explained
    // -1, 3, -1, 3, 1, -1, 5
    // the video begins as unstarted, then starts buffering, then is again as unstarted, then buffers again
    // at this point the vid starts, we stop it immediately and it goes to unstarted again, then it becomes cued
    // on the video cued moment we have the required information loaded in the player, which comes only after video has been played for a moment

    switch ($event.data) {
      case -1: // (unstarted)
        break;
      case 0: // (ended)
        break;
      case 1: // (playing)
        if (!this.stoppedMeOnce) {
          this._player.stopVideo();
          this.stoppedMeOnce = true;
        }
        break;
      case 2: // (paused)
        break;
      case 3: // (buffering)
        break;
      case 5: // (video cued)
      // this flag prevents this function from running a second time
        if (!this.onApiChangeHasPassed) {
          this.onApiChangeHasPassed = true;
          setTimeout(() => {
            if (this._validateAfterLoad()) {
              this.visible = true;
            }
            this._loading$.next(false);
            this._player.stopVideo();
            this._player.unMute();
            // whenever the component is loaded first if it has value - the video is loaded and then it's cued
            // we need to avoid making an API call to save data on that first component load
            if (this.isControlTouched) {
              this._setValue(true);
            } else {
              this._setValue(false);
            }
          }, 100);
        }
        break;
      default:
        break;
    }
  }

  private init() {
    if (this.value && !this.initialized) {
      const videoData = JSON.parse(this.value).value;
      this._videoUrl = videoData.videoUrl;
      if (this._videoUrl) {
        this.isControlEmpty = false;
        this._loading$.next(true);
      } else {
        this.isControlEmpty = true;
        this._loading$.next(false);
      }
      this.videoId = videoData.videoId;
      this.error = JSON.parse(this.value).validationMessage;
      this.initialized = true;
    }
  }
}
