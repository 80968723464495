<div class="login-page" [ngClass]="{'initial-loading': showLogin}">
  <div class="login-loader">
    <div class="loader-inner">
      <div class="loader-image">
        <img src="assets/images/carrot-logo-black-cropped.png" alt="Carrot loader indicator...">
      </div>
      <div class="loader-progress"></div>
    </div>
  </div>
  <div class="login-widget" [ngClass]="{'loading': loaderShown}">
    <div class="login-widget-content">
      <div class="login-widget-branding">
        <img [src]="competitionLogo" [alt]="competitionLogoAltText">
        <div class="loader">
          <div class="loader-inner"></div>
        </div>
      </div>
      <div class="login-form-wrap" *ngIf="showPasswordField && !ssoAuthDisplayService.ssoGenericScreen.visible">
        <h1 class="title">Reset Password</h1>
        <p class="subtitle">Enter your new password for {{userEmail}}.</p>
        <div class="action-failed" *ngIf="showErrorMessage">
          <p>{{ errorMessage }}</p>
        </div>
        <div class="input-wrap">
          <label for="user-password" class="input-label">
            <input
              [type]="passwordInputType"
              #passwordInput="ngModel"
              id="user-password"
              class="input-text input-password"
              #userPasswordId="ngModel"
              [(ngModel)]="userPassword"
              (keydown)="keyDownHandler($event)"
              (validationCountUpdated)="checkPasswordValidity($event)"
              maxlength="256"
              reqired
              passwordCheck
              [validationList]="validationList"
              [ngClass]="{
                'errored': (!isPasswordValid && userPassword?.length > 0) || (userPassword?.length < 1 && userPasswordId.touched),
                'errored-real': (!isPasswordValid && userPassword?.length > 0) || (userPassword?.length < 1 && userPasswordId.touched)
              }"
              aria-label="Enter password"
            />
            <span
              class="placeholder input-placeholder"
              [ngClass]="{
                'filled': userPassword,
                'errored': (!isPasswordValid && userPassword?.length > 0) || (userPassword?.length < 1 && userPasswordId.touched)
              }"
            >
              Password
            </span>
            <button
              class="icon icon-right material-icons-outlined show-pass-button"
              (click)="showPasswordButtonClicked()"
              [ngClass]="{'hide-pass': passwordButtonTooltip === 'Hide password'}"
              [pTooltip]="passwordButtonTooltip"
              [attr.aria-label]="passwordButtonTooltip === 'Hide password' ? 'Hide password' : 'Show password'"
            >
              visibility
            </button>
          </label>
          <div class="validation-rules-wrap">
            <div class="password-requirements">
              <span class="txt">Password Requirements:</span>
              <ul class="password-requirements-list">
                <ng-container *ngFor="let validation of validationList">
                  <li [ngClass]="validation.count >= validation.expCount ? 'matched' : 'not-matched'">
                    <span class="rule-label">{{ validation.name }}</span>
                    <span class="icon material-icons" *ngIf="validation.count >= validation.expCount">done</span>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
        <div class="button-wrap">
          <button
            class="button button-primary button-login"
            (click)="onSubmit()"
            [disabled]="passwordInput.invalid || userPassword.length < 1"
          >
            Reset Password
          </button>
        </div>
        <div class="extra-buttons-wrap">
          <span class="text">Remember password?</span>
          <a routerLink="/login" class="button-forgotten-pass">Back to Login</a>
        </div>
      </div>

      <app-auth-generic-screen></app-auth-generic-screen>
      
    </div>
  </div>
</div>
