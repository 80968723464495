import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { FormioCustomComponentsModule } from "../core/formio-custom-components/formio-custom-components.module";
import { TooltipModule } from "primeng/tooltip";
import { ProgressBarModule } from "primeng/progressbar";
import { FormRendererComponent } from './form-renderer/form-renderer.component';
import { FormioAppConfig } from "@formio/angular";
import { FormDisplayRendererComponent } from "./form-display-renderer/form-display-renderer.component";

@NgModule({
  declarations: [
    FormRendererComponent,
    FormDisplayRendererComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormioCustomComponentsModule,
    TooltipModule,
    ProgressBarModule,
  ],
  providers: [
    {
      provide: FormioAppConfig,
      useValue: {
        appUrl:'',
        apiUrl: 'api',
      }
    }
  ],
})
export class FormsModule {}
