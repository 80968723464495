export default [
  {
    key: "validate.custom",
    calculateValue(context) {
      return "valid = (!input && !component.validate.required) || (input && JSON.parse(input).valid) || 'Invalid File'";
    },
  },
  {
    type: "textfield",
    input: true,
    key: "customOptions.allowedFileType",
    weight: 10,
    label: "Allowed File Type",
    placeholder: "Allowed File Type",
    validate: {
      required: true,
    },
    tooltip: "Set uploaded file allowed type",
  },
  {
    type: "number",
    input: true,
    key: "customOptions.allowedFileSize",
    weight: 10,
    label: "Allowed Max File Size In MB",
    placeholder: "Allowed Max File Size In MB",
    validate: {
      required: true,
      min: 0.001
    },
    tooltip: "Set max allowed file size",
  },
]
