<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <textarea
    pInputTextarea
    #jsonText
    [(ngModel)]="json"
    (input)="onInput(jsonText.value)"
    [autofocus]="true"
  ></textarea>
  <p class="invalid" *ngIf="invalidJSON">Invalid JSON entered</p>
</div>


<div class="modal-footer">
  <button
    *ngIf="btnCancelText"
    type="button"
    class="button button-secondary"
    (click)="cancel()"
  >
    {{ btnCancelText }}
  </button>
  <button
    [ngClass]="{ 'ml-24': btnCancelText !== undefined }"
    type="button"
    class="button button-primary"
    (click)="save()"
    [disabled]="disableButton || invalidJSON"
  >
    {{ btnOkText }}
  </button>
</div>
