import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { saveAs } from 'file-saver';
import { DateTime } from "luxon";
import { Paginator } from "primeng/paginator";
import { Table } from "primeng/table";
import { ConfirmationDialogService } from "src/app/shared/services/confirmation-dialog.service";
import { EvaluationJudgingService } from "src/app/shared/services/evaluation-judging.service";
import { NotificationDialogService } from "src/app/shared/services/notification-dialog.service";
import { PeerOverviewService } from "src/app/shared/services/peer-overview.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { TabTitleService } from "src/app/shared/services/tab-title.service";
import { getFileNameFromResponse } from "src/app/shared/utils/http.util";
import { GridService } from "src/app/shared/services/grid.service";
import { GridConfigModalService } from "src/app/shared/services/grid-config-modal.service";
import { GridNames, IGridConfig } from "src/app/shared/model/grid-config.model";
import { createFromGridColumns } from "src/app/shared/model/user-form-data-filter-model";
import { CopyToClipboardService } from "src/app/shared/services/copy-to-clipboard.service";

@Component({
  selector: "app-peer-overview-details",
  templateUrl: "./peer-overview-details.component.html",
  styleUrls: ["./peer-overview-details.component.scss"],
})
export class PeerOverviewDetailsComponent implements OnInit {
  @ViewChild('pTable') table: Table;
  data = [];
  
  columns = [];

  reviewerId = '';
  reviewerName = '';
  deadline = '';
  sortApplied = false;
  filters: any = {};
  loading = false;

  @ViewChild('availableJudgesTable') availableJudgesTable: Table;
  @ViewChild('availableJudgesPaginator') availableJudgesPaginator: Paginator;
  @ViewChild('searchInputAvailableJudges') searchInputAvailableJudges: ElementRef;
  selectedJudgment = null;
  selectedJudge = null;
  judgementId = '';
  stageId = '';
  reassignDialog = false;
  availableJudges = [];
  availableJudgesFirst = 0;
  availableJudgesLast = 0;
  availableJudgesPageCount = 0;
  totalAvailableJudges = 0;
  availableJudgesRowsPerPage = 10;
  availableJudgesFilters: any = {};
  availableJudgesColumns = [
    { field: 'toPeerReviewer', header: 'To Peer Reviewer' },
    { field: 'assigned', header: '# Assigned' },
    { field: 'notStarted', header: '# Not Started' },
    { field: 'submitted', header: '# Submitted' },
    { field: 'openReviews', header: 'Open Reviews' },
    { field: 'lastLogin', header: 'Last Login' },
  ]
  sortAppliedAvailableJudges = false;
  filterAppliedAvailableJudges = false;

  gridConfig: IGridConfig = {
    gridName: '',
    stageId: '',
    stages: {},
    columns: [],
  };

  // used to enable/disable focus trap for reassignment modal 
  dynamicFocusTrap = true;

  constructor(
    private confirmationDialogService: ConfirmationDialogService,
    private notificationDialogService: NotificationDialogService,
    private peerOverviewService: PeerOverviewService,
    private judgmentService: EvaluationJudgingService,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private router: Router,
    private tabTitleService: TabTitleService,
    private gridService: GridService,
    private gridConfigModalService: GridConfigModalService,
    private copyToClipboard: CopyToClipboardService,
  ) {

  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      this.reviewerId = params.reviewer;
      this.stageId = params.id;
      await this.getColumns();
      await this.getData();
    });
    this.availableJudgesRowsPerPage = this.storageService.retrieve('availableJudgesRowsPerPage') ?? 10;
    Object.assign(this.availableJudgesFilters, { PageSize: this.availableJudgesRowsPerPage });
  }

  async refresh() {
    await this.getData();
  }

  async getData() {
    this.loading = true;
    if (!this.filters.userFormDataFilters) {
      Object.assign(this.filters, { userFormDataFilters: createFromGridColumns(this.columns) });
    }
    const res: any = await this.peerOverviewService.GetPeerOverviewDetailsList(this.reviewerId, this.filters).toPromise();
    this.reviewerName = res.judgeName;
    this.deadline = res.deadline;
    this.tabTitleService.setTabTitle(`${res.stageName} Overview`);
    this.data = res.data?.map(judgement => {
      judgement.actions = judgement.actions?.map(action => {
        return {
          label: action.name,
          disabled: action.value === 1 && this.isDeadlinePassed(),
          command: async () => {
            switch (action.value) {
              case 0:
                //review
                const url = this.router.serializeUrl(this.router.createUrlTree([`peer-review/${this.stageId}/review/${judgement.id}`], { queryParams: { step: 1 } }));
                window.open(url, '_blank');
                break;
              case 1:
                //reassign
                this.judgementId = judgement.id;
                this.selectedJudgment = judgement;
                this.selectedJudge = null;
                this.sortAppliedAvailableJudges = false;
                this.filterAppliedAvailableJudges = false;
                this.availableJudgesFilters = {};
                Object.assign(this.availableJudgesFilters, { PageSize: this.availableJudgesRowsPerPage });
                await this.getAvailableJudges();
                this.reassignDialog = true;
                break;
              case 2:
                // submit
                this.confirmationDialogService.confirm('Confirmation', `Are you sure you want to submit this review?`, 'Confirm', 'Cancel', '400px')
                  .then(async result => {
                    if (result) {
                      await this.judgmentService.saveJudgementSteps({
                        judgementId: judgement.id,
                        step: 8,
                      }).toPromise();
                      await this.getData();
                    }
                  });
                break;
              case 3:
                //reopen
                this.confirmationDialogService.confirm('Confirmation', `Are you sure you want to reopen this review?`, 'Confirm', 'Cancel', '400px')
                  .then(async result => {
                    if (result) {
                      await this.judgmentService.editReview(judgement.id).toPromise();
                      await this.getData();
                    }
                  });
                break;
              case 4:
                //swap
                this.confirmationDialogService.confirm('Confirmation', ` Are you sure you want to swap this optional review with another one? This review will be deleted and no longer accessible.`, 'Confirm', 'Cancel', '400px')
                  .then(async result => {
                    if (result) {
                      const res: any = await this.peerOverviewService.performSwap(judgement.id).toPromise();
                      if (!res.peerReviewNewOptionalJudgementGenerated) {
                        this.notificationDialogService.confirm('An error occurred', '', res.resultMessage, 'Ok');
                      } else {
                        await this.getData();
                      }
                    }
                  });
                break;
              default:
                break;
            }
          }
        }
      });
      judgement.actions.push({
        label: 'View scores',
        command: () => {
          const url = this.router.serializeUrl(this.router.createUrlTree([`peer-review/${this.stageId}/results/${judgement.userId}/${this.reviewerId}`]));
          window.open(url, '_blank');
        }
      });
      return judgement;
    });
    this.loading = false;
  }

  exit() {
    this.router.navigate([
      `peer-review/${this.activatedRoute.snapshot.paramMap.get('id')}/overview`,
    ]);
  }

  async onSort(event: any) {
    this.sortApplied = true;
    Object.assign(this.filters, {
      SortField: event.field,
      SortOrder: event.order,
    });
    await this.getData();
  }

  async clearFilters() {
    this.filters = {};
    this.table.clear();
    this.sortApplied = false;
    await this.getData();
  }

  onDownload(event: any) {
    this.peerOverviewService
      .ExportPeerOverviewDetailsList(this.reviewerId, {
        ...this.filters,
        ExportFormat: event.code,
      })
      .subscribe((response) => {
        saveAs(response.body, getFileNameFromResponse(response));
      });
  }

  async getAvailableJudges() {
    const res: any = await this.peerOverviewService.GetPeerAvailableJudges(this.judgementId, this.availableJudgesFilters).toPromise();
    this.availableJudges = res.data;
    const page = (this.availableJudgesFirst / this.availableJudgesRowsPerPage) + 1;
    this.totalAvailableJudges = res?.totalItems;
    this.availableJudgesLast = page === res?.pageCount ? res?.totalItems : this.availableJudgesRowsPerPage * page;
    this.availableJudgesPageCount = res?.pageCount;
  }

  async paginateAvailableJudges(event: any) {
    this.availableJudgesFirst = event.first;
    Object.assign(this.availableJudgesFilters, {
      PageIndex: this.availableJudgesFirst / this.availableJudgesRowsPerPage,
    });
    await this.getAvailableJudges();
  }

  async onSortAvailableJudges(event: any) {
    this.sortAppliedAvailableJudges = true;
    Object.assign(this.availableJudgesFilters, {
      SortField: event.field,
      SortOrder: event.order,
    });
    await this.getAvailableJudges();
  }

  async onFilterAvailableJudges(event: any) {
    this.filterAppliedAvailableJudges = event.filters?.global?.value ? true : false;
    Object.assign(this.availableJudgesFilters, {
      SearchQuery: event.filters.global?.value,
    });
    await this.updateAvailableJudgeTable();
  }

  async onPageAvailableJudges(event: any) {
    if (this.availableJudgesRowsPerPage !== event.rows) {
      this.availableJudgesRowsPerPage = event.rows;
      this.storageService.store('availableJudgesRowsPerPage', this.availableJudgesRowsPerPage);
    }
    Object.assign(this.availableJudgesFilters, {
      PageSize: this.availableJudgesRowsPerPage,
    });
    this.availableJudgesPaginator.changePage(0);
  }

  async updateAvailableJudgeTable() {
    if (this.availableJudgesPaginator.currentPage() === 0) {
      await this.getAvailableJudges();
    } else {
      this.availableJudgesPaginator.changePage(0);
    }
  }

  async clearFiltersAvailableJudges() {
    this.availableJudgesFilters = {};
    this.filterAppliedAvailableJudges = false;
    this.availableJudgesTable.clear();
    this.sortAppliedAvailableJudges = false;
    this.searchInputAvailableJudges.nativeElement.value = '';
    this.selectedJudge = null;
    this.updateAvailableJudgeTable();
  }

  async reassignJudgment() {
    // stop focus trap on reassignment modal
    // so that the confirmation modal has focus trap
    this.dynamicFocusTrap = false;
    this.confirmationDialogService
      .confirm('Confirmation', `Are you sure you want to reassign ${this.selectedJudgment.submittedBy} from ${this.selectedJudgment.judgeName} to ${this.selectedJudge.toPeerReviewer}? <br /> If you are performing a manual reassignment before Redistribution, swap will be automatically performed.`, 'Confirm', 'Cancel', '400px')
      .then(async (confirmed) => {
        if (confirmed) {
          await this.peerOverviewService.reassignJudge(this.selectedJudgment.id, this.selectedJudge.stageUserId).toPromise();
          this.reassignDialog = false;
          await this.getData();
        }
        // enable focus trap on the reassignment modal
        this.dynamicFocusTrap = true;
      });
  }

  openAvailableJudgesSearchInput() {
    document
      .getElementById("top-search-aj")
      .classList.add("search-button-open", "table");
    setTimeout(() => this.searchInputAvailableJudges.nativeElement.focus(), 0);
  }

  closeSearchInputAvailableJudges() {
    document
      .getElementById("top-search-aj")
      .classList.remove("search-button-open");
  }

  isDeadlinePassed() {
    const currentDate = DateTime.fromJSDate(new Date()).setZone('UTC').toISO();
    return currentDate > this.deadline;
  }

  async getColumns() {
    this.gridConfig = await this.gridService
      .GetGridConfig(GridNames.PeerOverview2, this.stageId)
      .toPromise();
    // create a copy of the original grid config object
    // this allows for the grid config modal to be opened with the original settings
    // and not with overridden settings
    this.columns = JSON.parse(JSON.stringify(
      this.gridConfig.columns.filter((c) => c.visibleInGrid)
    )); 
  }

  openConfigDialog() {
    this.gridConfigModalService
      .openGridConfigDialog({ gridName: GridNames.PeerOverview2, stageId: this.stageId, columns: this.gridConfig.columns })
      .then(async res => {
        if (res) {
          await this.getColumns();
          this.clearFilters()
        }
      })
  }

  clickToCopy(stringToCopy: string) {
    this.copyToClipboard.copyToClipboard(stringToCopy);
  }
}
