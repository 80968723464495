import { Injector } from '@angular/core';
import { FormioCustomComponentInfo, registerCustomFormioComponent } from '@formio/angular';
import { BudgetComponent } from './budget.component';
import baseEditForm from "formiojs/components/_classes/component/Component.form";
import budgetEditData from './editForm/budget.edit.data';
import budgetEditValidation from './editForm/budget.edit.validation';

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: 'mybudget',
  selector: 'my-budget',
  title: 'Budget',
  group: 'basic',
  icon: 'fa fa-table',
  editForm: budgetEditForm,
  extraValidators: ['custom'],
  schema: {
    customDefaultValue: "{}"
  }
};

export function budgetEditForm() {
  const form = baseEditForm([
    {
      key: "display",
      components: budgetEditData,
    },
    {
      key: "validation",
      components: budgetEditValidation,
    },
  ]);
  return form;
}

export function registerBudgetComponent(injector: Injector) {
  try{
    registerCustomFormioComponent(COMPONENT_OPTIONS, BudgetComponent, injector);
  }
  catch (err) {
    // custom component already exists
  }
}
