import { Injector } from "@angular/core";
import {
  Components,
  FormioCustomComponentInfo,
  registerCustomFormioComponent,
} from "@formio/angular";
import { VideoComponent } from "./video.component";
import baseEditForm from "formiojs/components/_classes/component/Component.form";
import VideoEditDisplay from "./editForm/video.edit.display";
import VideoEditData from "./editForm/video.edit.data";
import VideoEditValidation from "./editForm/video.edit.validation";

export function videoEditForm() {
  const form = baseEditForm([
    {
      key: "display",
      components: VideoEditDisplay,
    },
    {
      key: "data",
      components: VideoEditData,
    },
    {
      key: "validation",
      components: VideoEditValidation,
    },
  ]);
  return form;
}

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: "video",
  selector: "formio-video",
  title: "Video",
  group: "basic",
  icon: "fa fa-youtube-play",
  editForm: videoEditForm,
  fieldOptions: ["label"],
  schema: {
    customDefaultValue: "{}"
  }
};

let apiLoaded = false;
export function registerVideoComponent(injector: Injector) {
  try {
    registerCustomFormioComponent(
      COMPONENT_OPTIONS,
      VideoComponent,
      injector
    );

    if (!apiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      apiLoaded = true;
    }
  } catch (err) {
    // custom component already exists
  }
}
