import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { filter } from 'rxjs/operators';
import { getPwValidList } from 'src/app/shared/model/auth.model';
import { CompetitionInformation } from 'src/app/shared/model/common.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { SsoAuthDisplayService } from 'src/app/shared/services/sso-auth.display.service';
import { SsoAuthService } from 'src/app/shared/services/sso-auth.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TabTitleService } from 'src/app/shared/services/tab-title.service';

@Component({
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  @ViewChild('passwordInput') passwordInput: NgModel;

  competitionLogo = '';
  competitionLogoAltText: string = '';

  showLogin: boolean = false;
  userEmail: string = '';
  passwordInputType: string = 'password';
  userPassword: string = '';
  isPasswordValid: boolean = false;
  passwordButtonTooltip: string = 'Show password';
  
  errorMessage: string = '';
  showErrorMessage: boolean = false;

  submitButtonActive: boolean = false;

  showPasswordField: boolean = true;
  loaderShown: boolean = false;
  formSubmitting: boolean = false;
  token: string;

  constructor(
    private storage: StorageService,
    private commonService: CommonService,
    private tabTitleService: TabTitleService,
    private activatedRoute: ActivatedRoute,
    public ssoAuthService: SsoAuthService,
    public ssoAuthDisplayService: SsoAuthDisplayService,
  ) { }

  public validationList = getPwValidList();

  async ngOnInit(): Promise<void> {
    this.token =this.activatedRoute.snapshot.paramMap.get("token");
    this.checkPasswordRequestStatus(this.token);

    this.tabTitleService.setTabTitle('Reset Password');

    this.commonService.competitionInfo
      .pipe(filter(res => !!res))
      .subscribe(() => {
        const logoFromStorage = this.storage.retrieve(CompetitionInformation.loginWidgetLogoUrl);
        if (logoFromStorage !== null) {
          this.competitionLogo = logoFromStorage;
        };
        const logoAltTextFromStorage = this.storage.retrieve(CompetitionInformation.brandingsLogoAltText);
        if (logoAltTextFromStorage !== null) {
          this.competitionLogoAltText = logoAltTextFromStorage;
        };
        this.showLogin = true;
      });
  }

  showPasswordButtonClicked() {
    if (this.passwordInputType === 'password') {
      this.passwordInputType = 'text';
      this.passwordButtonTooltip = 'Hide password';
    } else {
      this.passwordInputType = 'password';
      this.passwordButtonTooltip = 'Show password';
    }
  }

  keyDownHandler(e: KeyboardEvent) {
    if (['Enter', 'NumpadEnter'].includes(e.key)) {
      this.onSubmit();
    };
  }

  checkPasswordValidity(event) {
    let validityRulesUpdated = event;
    this.validatePassword(validityRulesUpdated.every((element) => element.count >= element.expCount));
  }

  validatePassword(value: boolean) {
    this.isPasswordValid = value;
  }

  async onSubmit() {
    // check if we are currently submitting the form
    if (!this.formSubmitting) {
      // show loading animation
      this.loaderShown = true;
      this.formSubmitting = true;

      try
      {
        const response = await this.ssoAuthService.postConfirmPasswordRequest(this.token, this.userPassword);
        
        this.ssoAuthService.loginWithCustomToken(response.data.loginToken);

      }
      catch(err){
        this.ssoAuthDisplayService.handleApiError(err);
      }
      finally{
        this.loaderShown = false;
        this.formSubmitting = false;
      }         
    };
  }

  async checkPasswordRequestStatus(token: string) {
    try
    {
      const currentDate = DateTime.fromJSDate(new Date()).setZone('UTC').toISO();
      const response = await this.ssoAuthService.getConfirmPasswordRequest(token);
      const resetPasswordRequest = response.data;
      
      if (resetPasswordRequest.isConfirmed === true ||
          currentDate > resetPasswordRequest.expiresAt) {

            this.ssoAuthDisplayService.showGenericScreen("Reset Password", "The link has expired or is invalid.", ["Back to Login", "Forgot Password"]);
      }
      else {
        this.userEmail = resetPasswordRequest.email;
        this.showPasswordField = true;
      }
    }
    catch(err){
      this.ssoAuthDisplayService.handleApiError(err);
    }
    finally{
      this.loaderShown = false;
      this.formSubmitting = false;
    } 
  }
}
