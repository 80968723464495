export default [
  {
    key: "multiple",
    ignore: true,
  },
  {
    key: "defaultValue",
    ignore: true,
  },
];
