import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { DataService } from './data.service';
import { PublicConfigService } from './public.config.service';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  private storage: StorageService;
  public ConsentGranted: boolean;
  public RegistrationSubmitted: boolean;
  public _consentGranted$: Subject<boolean>;
  public _regIsSubmitted$: Subject<boolean>;

  constructor(
    private _storageService: StorageService,
    private dataService: DataService,
    private publicConfigService: PublicConfigService,
    private router: Router
  ) {
    this._consentGranted$ = new Subject<boolean>();
    this._regIsSubmitted$ = new Subject<boolean>();
    this.storage = _storageService;

    if (this.storage.retrieve("ConsentGranted")) {
      this.ConsentGranted = this.storage.retrieve("ConsentGranted");
    }
  }

  public checkConsent() {
    const consentUrl = `${this.publicConfigService.rpBaseUrl}/api/consent`;
    this.dataService.get(consentUrl).subscribe(res => {
      if (res.consentGranted) {
        this.GrantConsent(true);
      }
      else {
        this.GrantConsent(false);
      }
    });
  }

  // TODO - is this needed? Currently this is being run for all users, while it needs to be run only for participants
  public checkRegistrationIsSubmitted() {
    var regIsSubmittedUrl = `${this.publicConfigService.rpBaseUrl}/api/registationSubmitted`
    this.dataService.get(regIsSubmittedUrl).subscribe(res => {
      if (res.submitted) {
        this.RegistrationSubmitted = true;
        this._regIsSubmitted$.next(true);
      }
      else {
        this.RegistrationSubmitted = false;
        this._regIsSubmitted$.next(false);
      }
    });
  }

  public GrantConsent(boolean) {
    this.ConsentGranted = boolean;
    this.storage.store(`ConsentGranted`, boolean);
    this._consentGranted$.next(boolean);
  }

  public SubmitRegistration() {
    this.RegistrationSubmitted = true;
    this.router.navigate(["/"]);
  }

}
