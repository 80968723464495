import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Tokens } from "../model/auth.model";
import { StorageService } from "./storage.service";
import { PublicConfigService } from "./public.config.service";
import { RpUser } from "../model/user.model";
import { RpApiResponse } from "../model/common.model";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http: HttpClient,
    private publicConfigService: PublicConfigService,
    private storage: StorageService) {
  }

  get(url: string, params?: any, headers?: HttpHeaders, observe: any = "body"): Observable<any> {
    const options = { params, headers, observe };
    this.setHeaders(options);

    return this.http.get(url, options).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  post(url: string, data: any, params?: any): Observable<any> {
    const options = {};
    this.setHeaders(options);

    return this.http.post(url, data, options).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  postFile(fileToUpload: File, containerName): Observable<any> {
    
    const formData: FormData = new FormData();
    
    formData.append("file", fileToUpload, fileToUpload.name);
    formData.append("containerName", containerName);
    const options = {};
    this.setHeaders(options, false);

    return this.http.post(`${this.publicConfigService.rpBaseUrl}/api/file?loader=false`, formData, options).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  deleteFile(fileUrl: string): Observable<any>{
    var params = { file: fileUrl };
    
    return this.delete(`${this.publicConfigService.rpBaseUrl}/api/file?loader=false`, params);
  }

  put(url: string, data: any, params?: any): Observable<any> {
    const options = {};
    this.setHeaders(options);

    return this.http.put(url, data, options).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  delete(url: string, params?: any): Observable<any> {
    let options = {};
    this.setHeaders(options);

    if (params) {
      this.setParams(options, params);
    }
    return this.http.delete(url, options).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    return throwError(error || "server error");
  }

  private setHeaders(options: any, setContentTypeHeader = true) {
    options.headers = new HttpHeaders();
    if (setContentTypeHeader) {
      options.headers = options.headers.append(
        "Content-Type",
        "application/json"
      );
    }
  }

  private setParams(options: any, params: any) {
    options.params = params;
  }

  setRole(data: { roleId: string }) {
    return this.http.put(`${this.publicConfigService.rpBaseUrl}/api/user/setCurrentRole`, data, {

    })
  }

  getMe() {
    return this.http.get<RpApiResponse<RpUser>>(`${this.publicConfigService.rpBaseUrl}/api/user/get-me`)
  }

  recalculateSubmissions(formPathName: string): Observable<any> {
    return this.http.post(`${this.publicConfigService.rpBaseUrl}/api/formsubmission/recalculate`, { formPathName })
  }
}
