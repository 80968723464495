<div *ngIf="!disabled" class="location" [ngClass]="{'has-empty-value': isControlEmpty}">
  <div
    *ngFor="let field of fields"
    ref="component"
    class="form-group has-feedback formio-component formio-component-select formio-component-select"
  >
    <label class="col-form-label location-label">
      {{ field.name }}
    </label>
    <div
      class="choices form-group formio-choices"
      data-type="select-one"
      dir="ltr"
      tabindex="0"
      role="combobox"
      aria-autocomplete="list"
      aria-haspopup="true"
      [attr.aria-expanded]="field.active ? 'true' : 'false'"
      [attr.aria-label]="'Click enter to expand dropdown for ' + field.name"
      (click)="$event.stopPropagation(); setActiveField(field)"
      (keyup.enter)="$event.stopPropagation(); setActiveField(field)"
      [ngClass]="{'opened': field.active}"
    >
      <span class="asterisk" *ngIf="required" aria-hidden="true">*</span>
      <div
        class="form-control ui fluid selection dropdown"
        [ngClass]="{'is-invalid': !field.valid && isDirty}"
      >
        <div *ngIf="field.location.text" class="choices__list choices__list--single">
          <span class="choices__item">{{ field.location.text }}</span>
          <button type="button" class="choices__button" (click)="$event.stopPropagation(); clearFieldValue(field)" [attr.aria-label]="'Clear selected value ' + field.location.text">
            Remove item
          </button>
        </div>
      </div>
      <div
        class="choices__list choices__list--dropdown"
        [attr.aria-expanded]="field.active ? 'true' : 'false'"
        [ngClass]="{visible: field.active}"
      >
        <input
          #search
          type="text"
          class="choices__input choices__input--cloned"
          autocomplete="off"
          autocapitalize="off"
          spellcheck="false"
          role="textbox"
          aria-autocomplete="list"
          aria-label="Type to search"
          placeholder="Type to search"
          [disabled]="disabled"
          (click)="$event.stopPropagation()"
          [(ngModel)]="field.search"
          (ngModelChange)="onSearchChange()"
          [attr.level]="field.level"
        />
        <div class="choices__list" role="listbox" [ngClass]="{'searching': isSearching}">
          <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div *ngFor="let choice of field.choices" class="choices-item-wrapper" [ngClass]="{'inactive': choice.value === -1}">
            <button
              class="choices__item choices__item--choice"
              (click)="$event.stopPropagation(); setFieldLocation(field, choice)"
              [attr.aria-label]="'Choose ' + choice.text"
            >
              {{ choice.text }}
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="validationMessage && isDirty" class="formio-errors invalid-feedback" ref="messageContainer">
    <div class="form-text error">{{ validationMessage }}</div>
  </div>
</div>

<div *ngIf="disabled" class="location-disabled-wrap">
  <div *ngFor="let field of fields" class="location-disabled-dropdown-wrap">
    <div class="location-disabled-dropdown">
      <span class="content" *ngIf="field.location.text">{{ field.location.text }}</span>
      <span class="label" *ngIf="field.name && !field.location.text">{{ field.name }}</span>
      <span class="asterisk" *ngIf="required" aria-hidden="true">*</span>
    </div>
  </div>
</div>