import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storage: any;

  constructor() {
    // this try catch avoids errors if localStorage is disabled
    try {
      this.storage = localStorage;
    } catch (error) {
      console.log(error);
    }
  }

  public retrieve(key: string): any {
    const item = this.storage?.getItem(key);

    if (item && item !== "undefined") {
      try {
        return JSON.parse(item);
      } catch (e) {
        return item;
      }
    }

    return;
  }

  public store(key: string, value: any, jsonStringify: boolean = true) {
    let itemValue = jsonStringify ? JSON.stringify(value) : value;
    this.storage?.setItem(key, itemValue);
  }

  public remove(key: string) {
    this.storage?.setItem(key, undefined);
  }

  public removeCompletely(key: string) {
    this.storage?.removeItem(key);
  }

  public removeAllCompletely() {
    this.storage?.clear();
  }
}
