import { Component, OnInit } from "@angular/core";
import { TabTitleService } from "../shared/services/tab-title.service";
import { CommonService } from "../shared/services/common.service";
import { PublicConfigService } from "../shared/services/public.config.service";

@Component({
  selector: "app-registration-closed",
  templateUrl: "./registration-closed.component.html",
  styleUrls: ["./registration-closed.component.scss"],
})
export class RegistrationClosedComponent implements OnInit {

  feSite = '';
  endDate = '';

  constructor(private tabTitleService: TabTitleService, private compInfo: CommonService, private publicConfig: PublicConfigService) {}

  ngOnInit() {
    this.tabTitleService.setTabTitle("Registration is Closed");
    this.feSite = this.publicConfig.feBaseUrl;
    this.compInfo.competitionInfo.subscribe(info => this.endDate = info.data.registrationEndDate);
  }
}
