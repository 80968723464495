<button
  id="scrollTop"
  (click)="onScrollToTop()"
  class="scroll-wrapper"
  aria-label="Scroll to top of page"
>
  <span class="material-icons-round scroll-icon" aria-hidden="true">
    north
  </span>
</button>
