<label class="selected-label">Selected: </label>
<div class="choices" data-type="text" [ngClass]="{'has-empty-value': isControlEmpty, 'is-disabled': disabled}">
  <div class="choices__inner">
    <div class="choices__list choices__list--multiple">
      <div
        *ngFor="let node of selected"
        class="choices__item choices__item--selectable"
        data-deletable=""
      >
        <button
          (click)="expandNode(node)"
          class="button-text"
          aria-label="Click to expand nodes to this selection"
        >
          {{ node.label }}
        </button>
        <button
          *ngIf="!disabled"
          (click)="unselectNode(node)"
          class="choices__button"
          [attr.aria-label]="'Remove selected item ' + node.label"
        >
          Remove item
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showMaxSelectionError" class="error">
  Maximum number of allowed selections reached
</div>
<div class="tree-wrapper" *ngIf="!disabled">
  <div *ngIf="!disabled" class="button-container">
    <button
      class="button button-primary button-primary--medium"
      type="button"
      (click)="expandAll()"
      [disabled]="!canExpand"
      aria-label="Expand all taxonomy levels"
    >
      <span class="icon material-icons" aria-hidden="true">add</span>
      <span class="txt">Expand All</span>
    </button>
    <button
      class="button button-primary button-primary--medium"
      type="button"
      (click)="collapseAll()"
      [disabled]="!canCollapse || filter"
      aria-label="Collapse all taxonomy levels"
    >
      <span class="icon material-icons" aria-hidden="true">remove</span>
      <span class="txt">Collapse All</span>
    </button>
    <button
      class="button button-primary button-primary--medium"
      type="button"
      (click)="clearSelection()"
      [disabled]="this.selected.length === 0"
      aria-label="Clear all selected taxonomy items"
    >
      <span class="icon material-icons" aria-hidden="true">refresh</span>
      <span class="txt">Clear All Selections</span>
    </button>
  </div>
  <span class="sr-only">To use the taxonomy selection with the keyboard, you have to use the up, right, down and left arrows in combination with spacebar key to select a selectable item</span>
  <p-tree 
    *ngIf="!disabled" 
    [loading]="loading$ | async"
    [value]="nodes" 
    [selectionMode]="'checkbox'"
    [filter]="showFilter" 
    (onFilter)="treeFiltered($event)" 
    [selection]="selected"
    (onNodeSelect)="selectNode($event.node)" 
    (onNodeUnselect)="unselectNode($event.node)"
    (onNodeExpand)="canCollapse = true"
    (onNodeCollapse)="collapseNode($event.node)" 
    [filterPlaceholder]="'Search'"
    >
    <ng-template let-node pTemplate="default">
      <span [ngClass]="{ dot: showDot(node) }"></span>
      <span (click)="nodeClicked(node)" [ngClass]="{
          'node-not-allowed': !node.selectable && !isSelectionDisabled(node),
          'node-clickable': node.children.length,
          'node-selection-disabled': isSelectionDisabled(node)
        }" [title]="node.label">{{ node.label }}</span>
    </ng-template>
  </p-tree>
</div>