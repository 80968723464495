export interface FormDetails {
  absolutePath: string;
  relativePath: string;
  name: string;
  stageId: string;
  stageType: FormStageTypes;
  userId: string;
}

export enum FormStageTypes {
  RegistrationForm = "894eed61-6a4a-4170-8d92-09abbd9e003e",
  ApplicationForm = "a8aa2ad8-e9ae-47b9-8cc2-e61ab4a9a427",
  Form = "5bb450f2-f47f-4c0d-8894-0cc373195ebd",
}

export const formStageUrlSegment = "form-stage";
export const formStageIdRouteParam = "stageId";
export const formStageTypeRouteParam = "stageType";
export const formUserIdRouteParam = "userId";
