import { NavigationStart, Router } from '@angular/router';
import { ErrorHandler, inject, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';

export class CustomErrorHandler implements ErrorHandler {

  router = inject(Router);
  snackBar = inject(MatSnackBar);
  ngZone = inject(NgZone);
  location = inject(Location);

  // used to cache the desired / last navigated route
  // used after empty-redirect to return to desired route once more and trigger a reload of chunk
  storedDestinationRoute: string = '';

  constructor(){
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        if (evt.url != '/empty-redirect') {
          // store url only if it is different from /empty-redirect
          // /empty-redirect is a service route used as a utility to trigger route reload
          this.storedDestinationRoute = evt.url;
        }
      };
    });
  }

  // handleError will catch ALL errors in the platform
  handleError(error: any): void {
    // console.log(this.router.events);
    console.log(error);
    
    // handle angular chunk loading error and retry mechanism
    if (error.message?.includes('Loading chunk')) {
      this.chunkLoadRetry(error);
    };

    // throw the error - standard behaviour
    // Note, that any http errors are first caught by the interceptor and they are handled there
    throw error;
  }

  chunkLoadRetry(error) {
    // check if session storage item exists
    let chunkLoadErrorOccurenciesFromSession = sessionStorage.getItem('chunkLoadErrorOccurencies');
    // local error counts variable
    let errorCounts = 0;
    if (chunkLoadErrorOccurenciesFromSession) {
      // if it exists - parse to int, increase count and store new value as sting
      errorCounts = parseInt(chunkLoadErrorOccurenciesFromSession);
      errorCounts++;
      sessionStorage.setItem('chunkLoadErrorOccurencies', errorCounts.toString());
    } else {
      // if it does not exist - create it with value 1
      errorCounts = 1;
      sessionStorage.setItem('chunkLoadErrorOccurencies', '1')
    }

    // if error occured 3 times or less -> retry
    if (errorCounts < 4) {
      // run in ngZone in order to avoid errors, since the error handler runs outside of the ngZone
      this.ngZone.run(() => {
        console.log(`chunk load retry ${errorCounts}`);
        // redirect to empty route, then redirect to previous cached route, to trigger init of the route again
        // thus try to reload chunk
        this.router.navigateByUrl('/empty-redirect', {skipLocationChange: true})
                  .then(() => this.router.navigateByUrl(this.storedDestinationRoute));
      });
    } else if (errorCounts >= 4) {
      // if error occured more than 3 times
      // update the browser url without triggering navigation
      // this way the user will end on his desired route and not on /empty-redirect
      this.location.replaceState(this.storedDestinationRoute);
      // clear session storage and show popup asking for page refresh
      sessionStorage.removeItem('chunkLoadErrorOccurencies');
      // show error in snackbar permanently without close buttons
      // it will stay open even if route is changed
      // until the user refreshes the page
      this.snackBar.open('Something went wrong. Please refresh your page!', '', { duration: 0 });
    };
  }
}