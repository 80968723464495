<label [attr.for]="identifier" class="input-label">
  <input
    [type]="type"
    [attr.id]="identifier"
    class="input-text"
    [(ngModel)]="model"
    (input)="LogoAltText($event.target.value)"
    (keyup)="keyUpFunction($event)"
    (focusout)="focusOutFunction($event)"
    autocomplete="off"
    [disabled]="disabled"
    [maxlength]="maxLength"
    [ngClass]="{
      ' errored-soft': model?.length > 255,
      ' errored-real': inputError,
      ' errored-false': noError && !inputError
    }"
  />
  <span
    class="placeholder input-placeholder"
    [ngClass]="{
      filled: model !== null && model !== undefined && model !== '',
      errored: model?.length > 255 || inputError || noError
    }"
  >
    {{ name }}
  </span>

  <span
    *ngIf="icon"
    class="icon icon-right material-icons"
    (click)="emitIconClick($event)"
    pTooltip="Auto-generate"
  >
    {{ icon }}
  </span>

  <span
    *ngIf="model?.length > 255 || inputError || noError"
    class="error-field"
  >
    <span *ngIf="model?.length > 255">
      <span
        class="error-message error-message-soft"
        *ngIf="model?.length > 255"
      >
        <span class="icon error-icon">
          <span class="material-icons">
            priority_high
          </span>
        </span>
        <span class="error-text"
          >You have reached the maximum allowed characters
        </span>
      </span>
    </span>
    <span *ngIf="inputError">
      <span class="error-message error-message-urgent">
        <span class="icon error-icon">
          <span class="material-icons">
            priority_high
          </span>
        </span>
        <span class="error-text">Please review the added data </span>
      </span>
    </span>
    <span *ngIf="noError">
      <span class="error-message no-error">
        <span class="icon error-icon">
          <span class="material-icons">
            done
          </span>
        </span>
        <span class="error-text no-error">No errors here! </span>
      </span>
    </span>
  </span>
</label>
