import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";
import { StorageService } from "../services/storage.service";

@Pipe({
  name: "utcToCompTime"
})
export class utcToCompTimePipe implements PipeTransform {
  compiledCompTime: DateTime;
  constructor(private storage: StorageService) {}

  transform(utcDate: string, output?: string): string {
    if (utcDate !== null && utcDate !== undefined) {
      const competitionTimeZoneDatabaseNameFromStorage = this.storage.retrieve(
        "competitionTimeZoneDatabaseName"
      );
      let formatted: string;

      if (
        competitionTimeZoneDatabaseNameFromStorage !== "Etc/GMT" &&
        competitionTimeZoneDatabaseNameFromStorage
      ) {
        // we explicitly say. that the received time is in UTC zone (it always is in UTC, because BE sends it this way)
        // after that we convert that UTC time to the competition time zone
        this.compiledCompTime = DateTime.fromISO(utcDate, {
          zone: "Etc/GMT"
        }).setZone(competitionTimeZoneDatabaseNameFromStorage);
        // formatting
        formatted = this.compiledCompTime.toFormat("MM/dd/yyyy h:mm:ss a");
      } else {
        this.compiledCompTime = DateTime.fromISO(utcDate, {
          zone: "Etc/GMT"
        });
        formatted = DateTime.fromISO(utcDate).toFormat("MM/dd/yyyy h:mm:ss a");
      }
      switch(output){
        case('dateTransform'):
         return formatted = this.compiledCompTime.toFormat("dd MMM yyyy");
        case('timeTransform'):{
          const competitionTimeZoneAbbrFromStorage = this.storage.retrieve(
            "competitionTimeZoneAbbr"
          );
          formatted = this.compiledCompTime.toFormat("hh:mm a");
          if (competitionTimeZoneAbbrFromStorage) {
            formatted = formatted + " " + competitionTimeZoneAbbrFromStorage;
          } else {
            formatted = formatted + " Etc/GMT";
          }
          return formatted;
        }
        case('fullDateTime'):{
          let formattedDate = this.compiledCompTime.toFormat("dd MMM yyyy");
          const competitionTimeZoneAbbrFromStorage = this.storage.retrieve(
            "competitionTimeZoneAbbr"
          );
          let formattedTime = this.compiledCompTime.toFormat("hh:mm a");
          if (competitionTimeZoneAbbrFromStorage) {
            formattedTime = formattedTime + " " + competitionTimeZoneAbbrFromStorage;
          } else {
            formattedTime = formattedTime + " Etc/GMT";
          }
          return formattedDate + ' ' + formattedTime;
        }
      }
      return formatted;
    }
  }
}
