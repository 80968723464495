import { Component, OnInit, ViewChild } from "@angular/core";
import { NgModel } from "@angular/forms";
import { Router } from "@angular/router";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { filter} from 'rxjs/operators';
import { emailRegex, getPwValidList, SsoApiResponse, SsoRegisterUserRequest } from "src/app/shared/model/auth.model";
import { CompetitionInformation } from "src/app/shared/model/common.model";
import { CommonService } from 'src/app/shared/services/common.service';
import { NotificationDialogService } from "src/app/shared/services/notification-dialog.service";
import { PublicConfigService } from "src/app/shared/services/public.config.service";
import { SanitizeService } from "src/app/shared/services/sanitize.service";
import { SsoAuthDisplayService } from "src/app/shared/services/sso-auth.display.service";
import { SsoAuthService } from "src/app/shared/services/sso-auth.service";
import { StorageService } from 'src/app/shared/services/storage.service';
import { TabTitleService } from "src/app/shared/services/tab-title.service";

@Component({
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {
  
  @ViewChild('passwordInput') passwordInput: NgModel;

  competitionLogo: string = '';
  competitionLogoAltText: string = '';

  showLogin: boolean = false;
  userFirstName: string = '';
  userLastName: string = '';
  userEmail: string = '';
  passwordInputType: string = 'password';
  userPassword: string = '';
  isPasswordValid: boolean = false;
  passwordButtonTooltip: string = 'Show password';

  emailRegex = emailRegex;

  errorMessage: string = '';
  showErrorMessage: boolean = false;

  loaderShown: boolean = false;
  formSubmitting: boolean = false;

  hideReg: boolean = false;

  disabledRegButton: boolean = false;

  feBaseUrl = '';

  constructor(
    private storage: StorageService,
    private commonService: CommonService,
    public ssoAuthService: SsoAuthService,
    private tabTitleService: TabTitleService,
    public ssoAuthDisplayService: SsoAuthDisplayService,
    private router: Router,
    private sanitizeService: SanitizeService,
    private notificationDialogService: NotificationDialogService,
    public googleTagManagerService: GoogleTagManagerService,
    private publicConfigService: PublicConfigService,
  ) {

  }

  public validationList = getPwValidList();

  async ngOnInit() {
    
    this.tabTitleService.setTabTitle('Register');

    await document.fonts.ready;
    await this.ssoAuthService.checkSsoSession();

    this.feBaseUrl = this.publicConfigService.feBaseUrl;
    
    this.commonService.competitionInfo
      .pipe(filter(res => !!res))
      .subscribe((competitionInfo) => {
        this.checkRegistrationEndDate();
        const logoFromStorage = this.storage.retrieve(CompetitionInformation.loginWidgetLogoUrl);
        if (logoFromStorage !== null) {
          this.competitionLogo = logoFromStorage;
        };
        const logoAltTextFromStorage = this.storage.retrieve(CompetitionInformation.brandingsLogoAltText);
        if (logoAltTextFromStorage !== null) {
          this.competitionLogoAltText = logoAltTextFromStorage;
        };
        this.showLogin = true;
      });
  }

  checkRegistrationEndDate() {
    if(!this.commonService.isRegistrationOpen()) {
      this.router.navigateByUrl('/login')
    }
  }

  showPasswordButtonClicked() {
    if (this.passwordInputType === 'password') {
      this.passwordInputType = 'text';
      this.passwordButtonTooltip = 'Hide password';
    } else {
      this.passwordInputType = 'password';
      this.passwordButtonTooltip = 'Show password';
    }
  }

  keyDownHandler(e: KeyboardEvent) {
    if (['Enter', 'NumpadEnter'].includes(e.key)) {
      this.shouldSubmit();
    };
  }

  checkPasswordValidity(event) {
    let validityRulesUpdated = event;
    this.validatePassword(validityRulesUpdated.every((element) => element.count >= element.expCount));
  }

  validatePassword(value: boolean) {
    this.isPasswordValid = value;
  }

  shouldSubmit() {
    // check for disabled reg button
    if (this.disabledRegButton) {
      return;
    }
    // first name check
    if (this.userFirstName.length > 50) {
      return;
    }
    // last name check
    if (this.userLastName.length > 50) {
      return;
    }
    // email check
    if (!this.userEmail.match(this.emailRegex)) {
      return;
    }
    // password check
    if (!this.passwordInput.valid || this.userPassword.length < 1) {
      return;
    }

    this.onSubmit();
  }

  async onSubmit() {
    // check if we are currently submitting the form
    if (!this.formSubmitting) {
      // show loading animation
      this.loaderShown = true;
      this.formSubmitting = true;

      if (this.userFirstName !== this.sanitizeService.sanitizeUserInput(this.userFirstName)) {
        this.disabledRegButton = true;
        this.notificationDialogService
        .confirm(
          "An error occurred",
          "",
          `Make sure First Name doesn't contain invalid characters or an HTML tag and try again. Registration is not complete.`,
          "Ok",
          "600px"
        )
        .then((confirmed) => {
          // if confirmed === true - user confirmed, if false - user clicked cancel button
          this.disabledRegButton = false;
        })
        .catch(() => {
          // user dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog))
          this.disabledRegButton = false;
        });
        this.loaderShown = false;
        this.formSubmitting = false;
        return;
      }

      if (this.userLastName !== this.sanitizeService.sanitizeUserInput(this.userLastName)) {
        this.disabledRegButton = true;
        this.notificationDialogService
        .confirm(
          "An error occurred",
          "",
          `Make sure Last Name doesn't contain invalid characters or an HTML tag and try again. Registration is not complete.`,
          "Ok",
          "600px"
        ).then((confirmed) => {
          // if confirmed === true - user confirmed, if false - user clicked cancel button
          this.disabledRegButton = false;
        })
        .catch(() => {
          // user dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog))
          this.disabledRegButton = false;
        });
        this.loaderShown = false;
        this.formSubmitting = false;
        return;
      }

      const registerUserPayload : SsoRegisterUserRequest = {
        email: this.userEmail,
        firstname: this.userFirstName,
        lastname: this.userLastName,
        password: this.userPassword
      }
      let response :SsoApiResponse<SsoRegisterUserRequest>;

      try {
        response = await this.ssoAuthService.registerUser(registerUserPayload);
        if (response.success) {
          this.hideReg = true;
          this.ssoAuthDisplayService.showGenericScreen(
            "Verification email sent",
            `A verification email was successfully sent to ${registerUserPayload.email}. Just click on the link in that email to verify your account. If you don’t see a verification email from us in your inbox, you may need to check your spam or junk folder.`,
            ["Back to Login"]
          )
          this.initPostRegistrationPixels();
        }
      }
      catch(err) {
        this.ssoAuthDisplayService.handleApiError(err);
      }
      finally {
        this.loaderShown = false;
        this.formSubmitting = false;
      }
    }
  }

  initPostRegistrationPixels() {
    //TODO: post registration pixels
    this.googleTagManagerService.pushTag({
      event: 'user_register_event',
      url: this.publicConfigService.rpBaseUrl
    });
  }
}
