<p-dropdown
  #dropDownComponent
  [options]="items"
  (onChange)="handleDropdownSelection($event, dropDownComponent)"
  [optionLabel]="optionLabel"
  [placeholder]="placeholderText"
  [dropdownIcon]="ddIcon"
  [(ngModel)]="selectedItem"
  (onHide)="dropdownClose()"
  (onFocus)="dropdownOpen()"
  (onShow)="dropdownOpen()"
  [disabled]="disabled"
  [emptyFilterMessage]="emptyFilterMessage"
  [emptyMessage]="emptyMessage"
  [ngClass]="{ 'p-dropdown--only-icon': icon !== undefined }"
  scrollHeight="260px"
>
  <ng-template let-item pTemplate="item">
    <div *ngIf="item.img !== undefined">
      <img [src]="item.img" />
      <div>{{ item.name }}</div>
    </div>
    <div *ngIf="item.img == undefined">
      <div>{{ item.name }}</div>
    </div>
  </ng-template>
</p-dropdown>
