<div (window:resize)="onResize($event)" class="reg-header" #componentHeader>
  <div class="header-wrap" *ngIf="formHeaderData">
    <div class="header-title" [pTooltip]="formDetails.name" tooltipPosition="right">{{ formDetails.name }}</div>
    <!-- TODO: When implementing the header, loading icon should be incorporated here -->
    <div class="auto-save-icon" *ngIf="formHeaderData.state !== 'INITIAL' && formHeaderData.state">
      <span
        class="button-icon icon-default save-button"
        [pTooltip]="toggleSaveIcon ? 'Autosave in progress' : 'Last saved on ' + (formHeaderData?.lastSaved | utcToCompTime: 'fullDateTime')"
        tooltipPosition="bottom"
        [tooltipDisabled]="formHeaderData?.lastSaved == null"
      >
        <span class="sr-only">{{toggleSaveIcon ? 'Autosave in progress' : 'Last saved on ' + (formHeaderData?.lastSaved | utcToCompTime: 'fullDateTime')}}</span>
        <!-- TODO: lastSaved should be formatted after format discussion -->
        <span class="material-icons-round" [ngClass]="{ rotate: toggleSaveIcon }" aria-hidden="true">
          {{ toggleSaveIcon ? "autorenew" : "cloud_done" }}
        </span>
      </span>
    </div>
    <div class="progress-bar-wrap">
      <span class="progress-bar-text" *ngIf="formHeaderData?.progress < 100">
        Progress:
        <span [ngStyle]="{
            color: getClass(formHeaderData.progress, formHeaderData.isDeadlinePassed, submitted)
          }">
          {{ formHeaderData.progress }}%
        </span>
      </span>
      <span class="progress-bar-text" *ngIf="formHeaderData?.progress == 100">
        Complete:
        <span [ngStyle]="{
            color: getClass(formHeaderData.progress, formHeaderData.isDeadlinePassed, submitted)
          }">
          {{ formHeaderData.progress }}%
        </span>
      </span>
      <p-progressBar [ngClass]="getClass(formHeaderData.progress, formHeaderData.isDeadlinePassed, submitted)"
        class="customize-progress-bar" [value]="formHeaderData.progress"></p-progressBar>
    </div>
    <div class="next-prev-wrap" [ngClass]="{ 'disable-double-button': formHeaderData.progress == 100 }">
      <button
        class="button-icon icon-w-fill icon-w-fill--smaller"
        [disabled]="formHeaderData.progress == 100 || formHeaderData.isDeadlinePassed"
        pTooltip="Previous incomplete question"
        tooltipPosition="bottom"
        [tooltipDisabled]="formHeaderData.progress == 100 || formHeaderData.isDeadlinePassed"
        (click)="nextPrev('prev')"
        aria-label="Scroll to previous incomplete question"
      >
        <span class="material-icons" aria-hidden="true"> keyboard_arrow_up </span>
      </button>
      <button
        class="button-icon icon-w-fill icon-w-fill--smaller"
        [disabled]="formHeaderData.progress == 100 || formHeaderData.isDeadlinePassed"
        pTooltip="Next incomplete question"
        tooltipPosition="bottom"
        [tooltipDisabled]="formHeaderData.progress == 100 || formHeaderData.isDeadlinePassed"
        (click)="nextPrev('next')"
        aria-label="Scroll to next incomplete question"
      >
        <span class="material-icons" aria-hidden="true"> keyboard_arrow_down </span>
      </button>
    </div>
    <!-- TODO : Check with BE - this works if on reopen the state goes back to inprogress -->
    <div class="submitted-details-wrap" [ngClass]="{
        submitted: submitted || (!submitted && !formHeaderData.isDeadlinePassed),
        'not-submitted': formHeaderData.isDeadlinePassed && !submitted
      }">
      <span class="deadline-details" *ngIf="submitted">
        <span
          class="material-icons"
          [pTooltip]="'Submitted ' + (formHeaderData.submitedAt | utcToCompTime: 'fullDateTime')"
          tooltipPosition="bottom"
          [tooltipDisabled]="windowWidth > 1200"
          aria-hidden="true"
        >
          check_circle_outline
        </span>
        <span class="submitted-details">
          <span class="submitted-text">Submitted</span>
          <span class="submitted-date">
            <span>
              {{ formHeaderData.submitedAt | utcToCompTime: "dateTransform" }}
            </span>
            <span>
              {{ formHeaderData.submitedAt | utcToCompTime: "timeTransform" }}
            </span>
          </span>
        </span>
      </span>
      <span
        class="deadline-details deadline-approaching"
        *ngIf="!submitted && !formHeaderData.isDeadlinePassed && showDeadLine"
        [pTooltip]="formHeaderData.toolTipText"
        tooltipPosition="bottom"
        [stageDeadline]="formHeaderData.deadline"
      >
        <span class="material-icons" aria-hidden="true"> schedule </span>
        <span class="submitted-details">
          <span class="submitted-date">
            <span>
              {{ formHeaderData.deadline | utcToCompTime: "dateTransform" }}
            </span>
            <span>
              {{ formHeaderData.deadline | utcToCompTime: "timeTransform" }}
            </span>
          </span>
        </span>
      </span>
      <span class="deadline-details" *ngIf="!submitted && formHeaderData.isDeadlinePassed">
        <span
          class="material-icons"
          [pTooltip]="'Not Submitted by ' + (formHeaderData.deadline | utcToCompTime: 'fullDateTime')"
          tooltipPosition="bottom"
          [tooltipDisabled]="windowWidth > 1200"
          aria-hidden="true"
        >
          error_outline
        </span>
        <span class="submitted-details">
          <span class="submitted-text">Not Submitted <span class="by">by</span></span>
          <span class="submitted-date">
            <span>
              {{ formHeaderData.deadline | utcToCompTime: "dateTransform" }}
            </span>
            <span>
              {{ formHeaderData.deadline | utcToCompTime: "timeTransform" }}
            </span>
          </span>
        </span>
      </span>
    </div>
    <div class="additional-icons-wrap">
      <div class="print-icon">
        <a
          class="button-icon icon-default button-link" 
          target="_blank"
          href="form/{{formDetails.stageId}}/display/{{formDetails.userId}}?print=true"
          pTooltip="Print"
          aria-label="Print form"
        >
          <span class="material-icons-round" aria-hidden="true">print</span>
        </a>
      </div>
      <div class="view-icon">
        <a 
          class="button-icon icon-default button-link" 
          target="_blank" 
          href="form/{{formDetails.stageId}}/display/{{formDetails.userId}}" 
          pTooltip="Preview"
          aria-label="Preview form"
        >
          <span class="material-icons-round" aria-hidden="true">preview</span>
        </a>
      </div>
    </div>
    <div class="action-button-wrap" *ngIf="!formHeaderData.isDeadlinePassed">
      <button
        *ngIf="formHeaderData.state == 'INPROGRESS' || formHeaderData.state == 'INITIAL'"
        class="button button-primary button-primary--medium"
        (click)="openSubmitModal()"
        aria-label="Submit form"
      >
        Submit
      </button>
      <button
        *ngIf="formHeaderData.state == 'SUBMITTED' && enableEdit"
        (click)="openEditModal()"
        class="button button-primary button-primary--medium"
        aria-label="Edit form"
      >
        Edit
      </button>
    </div>
  </div>
</div>
<div
  class="formio-custom-wrapper"
  (click)="changeScrollItem($event)"
  [attr.inert]="makeInert || null"
>
  <ng-container *ngIf="!loading">
    <formio 
      [form]="formDefinition"
      [submission]="{'data': formData.data}"
      (ready)="formioReady($event)"
      (change)="onFormChange($event)"
      [readOnly]="isReadonly"
      [renderOptions]="renderOptions"
    ></formio>
    <div class="submit-button-bottom-wrap" *ngIf="formHeaderData">
      <div class="action-button-wrap" *ngIf="!formHeaderData.isDeadlinePassed">
        <button
          *ngIf="formHeaderData.state == 'INPROGRESS' || formHeaderData.state == 'INITIAL'"
          class="button button-primary button-primary--medium"
          (click)="openSubmitModal()"
          aria-label="Submit form"
        >
          Submit
        </button>
      </div>
    </div>
  </ng-container>
</div>
