export default [
    {
      type: "textfield",
      input: true,
      required: "true",
      key: "customOptions.totalRequirements",
      weight: 10,
      label: "Total Requirements Text",
      placeholder: "Total Requirements Text",
      tooltip: "The text displayed in the last row, against the Total sum.",
    }
  ];
  