import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ScoreBarComponent } from './score-bar.component';

import { ProgressBarModule } from 'primeng/progressbar';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ProgressBarModule
  ],
  declarations: [ScoreBarComponent],
  exports: [ScoreBarComponent],
  
})
export class ScoreBarModule { }
