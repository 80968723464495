import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-scroll-to-top",
  templateUrl: "./scroll-to-top.component.html",
  styleUrls: ["./scroll-to-top.component.scss"]
})
export class ScrollToTopComponent implements OnInit {
  @Output() scrollToTop = new EventEmitter<void>();
  constructor() {}

  ngOnInit() {
  }

  onScrollToTop(): void {
    document.getElementById('scrollTop').blur();
    this.scrollToTop.emit();
  }

}
