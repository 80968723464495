<div class="fullscreen-modal fullscreen-modal--edit">
  <div class="modal-header">
    <div class="container-md">
      <div class="grid">
        <div class="col-md-6">
          <div class="user-details">
            <h2 *ngIf="applicantIdentifier" class="user-name">Applicant: {{ applicantIdentifier }}</h2>
          </div>
        </div>
        <div class="col-md-6">
          <div class="review-details">
            <div *ngIf="userForms" class="dropdown-wrap">
              <app-dropdown
                [items]="userForms"
                type="link"
                placeholderText="Submissions"
                optionLabel="name"
              ></app-dropdown>
            </div>
            <div *ngIf="userActions" class="actions-wrap">
              <span *ngFor="let action of userActions" class="actions-inner-wrap">
                <button
                  class="button"
                  [ngClass]="{'button-secondary button-secondary--red--full': action.style === 'discard',
                              'button-primary': action.style === 'normal'}"
                  (click)="performAction(action.value, action.name)"
                >
                  {{action.name}}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-content">
    <div class="container-md">
      <div class="container-inner">
        <div class="questions-wrap">
          <div class="question-section" *ngIf="generalQuestionsArr">
            <h2 class="question-section-title">General Questions</h2>
            <div *ngIf="generalQuestionsArr" class="questions-inner-wrap">
              <div *ngFor="let question of generalQuestionsArr; let i = index">
                <div class="question-single" [id]="'general-question-' + i">
                  <h2 class="question-title">{{question.sortOrder + 1}}. {{question.title}}</h2>
                  <div class="question-description" [innerHTML]="question.description | formatLinks"></div>
                  <div class="answer-wrap">
                    <button
                      class="button button-secondary button-secondary--green"
                      [ngClass]="{'marked': question.answer === 'yes'}"
                      (click)="markQuestion(i, 'yes')"
                    >
                      Yes
                    </button>
                    <button
                      class="button button-secondary button-secondary--red"
                      [ngClass]="{'marked': question.answer === 'no'}"
                      (click)="markQuestion(i, 'no')"
                    >
                      No
                    </button>
                  </div>
                  <div class="comment-wrap">
                    <label for="comment-{{i}}" class="general-setup-label" [ngClass]="{'errored-button-next': question.answer === 'no' && question.comments.length < 1}">
                      <span class="input-direct-wrap">
                        <textarea
                          type="text"
                          id="client-name"
                          class="general-setup-input"
                          [(ngModel)]="question.comments"
                          (ngModelChange)="storeState()"
                          maxlength="256"
                          [ngClass]="{'errored errored-real': question.answer === 'no' && question.comments.length < 1}"
                        >
                        </textarea>
                      </span>
                      <span class="placeholder general-setup-placeholder" [ngClass]="{'filled': question.comments}">If No, please provide a comment.</span>
                      <span class="error-field" *ngIf="question.answer === 'no' && question.comments.length < 1">
                        <span class="error-message" *ngIf="question.answer === 'no' && question.comments.length < 1"><span class="icon error-icon">!</span><span class="error-text">You need to enter a comment</span></span>
                      </span>
                    </label>
                    <button class="button button-secondary button-next-question" (click)="slideToNextQuestion($event, i)" *ngIf="i < (generalQuestionsArr.length - 1)">Next</button>
                    <button class="button button-secondary button-next-question" (click)="slideToNextQuestion($event, 0, 'ar')" *ngIf="i === (generalQuestionsArr.length - 1) && additionalReviewArr">Next</button>
                    <button class="button button-secondary button-next-question" (click)="performAction(9, 'Next')" *ngIf="(!additionalReviewArr && i === (generalQuestionsArr.length - 1))">Next</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="question-section additional-spacing" *ngIf="additionalReviewArr">
            <h2 class="question-section-title">Additional Feedback</h2>
            <div *ngIf="additionalReviewArr" class="questions-inner-wrap">
              <div *ngFor="let question of additionalReviewArr; let i = index">
                <div class="question-single" [id]="'additional-review-' + i">
                  <h2 class="question-title">
                    <span *ngIf="i === 0">A.</span> <span *ngIf="i === 1">B.</span> {{question.title}}
                  </h2>
                  <div class="question-description" [innerHTML]="question.description | formatLinks"></div>
                  <div class="answer-wrap">
                    <app-checkbox-button
                      [(state)]="question.subAnswer"
                      [label]="'Yes'"
                      (stateChange)="updateAnswer(question)"
                    ></app-checkbox-button>
                  </div>
                  <div class="comment-wrap">
                    <label for="comment-{{i}}" class="general-setup-label" [ngClass]="{'errored-button-next': question.answer === 'yes' && question.comments.length < 1}">
                      <span class="input-direct-wrap">
                        <textarea
                          type="text"
                          id="client-name"
                          class="general-setup-input"
                          [(ngModel)]="question.comments"
                          (ngModelChange)="storeState()"
                          maxlength="256"
                          [ngClass]="{'errored errored-real': question.answer === 'yes' && question.comments.length < 1}"
                        >
                        </textarea>
                      </span>
                      <span class="placeholder general-setup-placeholder" [ngClass]="{'filled': question.comments}">If Yes, please provide a comment.</span>
                      <span class="error-field" *ngIf="question.answer === 'yes' && question.comments.length < 1">
                        <span class="error-message" *ngIf="question.answer === 'yes' && question.comments.length < 1"><span class="icon error-icon">!</span><span class="error-text">You need to enter a comment</span></span>
                      </span>
                    </label>
                    <button
                      *ngIf="additionalReviewArr.length > 1 && i < (additionalReviewArr.length - 1)"
                      class="button button-secondary button-next-question"
                      (click)="slideToNextQuestion($event, i, 'ar')"
                    >
                      Next
                    </button>
                    <button
                      *ngIf="(additionalReviewArr.length > 1 && i === (additionalReviewArr.length - 1)) || additionalReviewArr.length === 1"
                      class="button button-secondary button-next-question"
                      (click)="performAction(9, 'Next')"
                      [disabled]="saveInProgress"
                      pTooltip="Save in progress"
                      [tooltipDisabled]="!saveInProgress"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-footer">
          <div *ngIf="userActions" class="actions-wrap">
            <span *ngFor="let action of userActions" class="actions-inner-wrap">
              <button
                class="button"
                [ngClass]="{'button-secondary button-secondary--red--full': action.style === 'discard',
                              'button-primary': action.style === 'normal'}"
                (click)="performAction(action.value, action.name)"
                [disabled]="saveInProgress"
                pTooltip="Save in progress"
                [tooltipDisabled]="!saveInProgress"
              >
                {{action.name}}
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>