import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SsoAuthDisplayService } from "src/app/shared/services/sso-auth.display.service";

@Component({
  selector: 'app-auth-generic-screen',
  templateUrl: './generic-screen.component.html',
  styleUrls: ['./generic-screen.component.scss']
})
export class GenericScreenComponent {

  constructor(
    public ssoAuthDisplayService: SsoAuthDisplayService,
    private router: Router
  ) { }

  

  navigateByLinkName(linkName:string) {
    //this.hideReg = false;
    this.ssoAuthDisplayService.hideGenericScreen();

    let relativeUrl = "";
    switch(linkName) {
      case "Login":
      case "Back to Login":
        relativeUrl = "login";
        break;
      case "Register":
        relativeUrl = "register";
        break;
      case "Forgot Password":
        relativeUrl = "forgot-password";
        break;
    }

    this.router.navigateByUrl(relativeUrl);
  }
}
