import { Injectable } from "@angular/core";
import { PublicConfigService } from "../../shared/services/public.config.service";
import { DataService } from "src/app/shared/services/data.service";
import { FormSubmissionPOC, FormSubmissionPayload } from "../model/form-submission";

@Injectable({ providedIn: "root" })
//TODO: FormIO - better name for the service, check what is needed from the other services in /forms/services
export class FormSubmissionService {
  public defaultFormStageId: string = '18b7bf4e-60e3-49e2-32ce-08dba8aac8ac';
  public userId: string;

  constructor(
    private publicConfigService: PublicConfigService,
    private dataService: DataService
  ) {
  }

  init(
    userId: string
  ) {
    this.userId = userId;
  }
    

  //TODO: FormIO - better return type is needed
  async loadFormDefinition(stageId: string) : Promise<any|null> {
    var formDefinitionResponse = await this.dataService.get(`${this.publicConfigService.rpBaseUrl}/api/form-management/${stageId}`).toPromise();
    return formDefinitionResponse.data.definition;
  }

  async saveFormDefinition(formDefinition: any, stageId: string, runDataExtraction = false): Promise<any> {
    return this.dataService.post(`${this.publicConfigService.rpBaseUrl}/api/form-management/${stageId}`,{
      definition: JSON.stringify(formDefinition),
      runDataExtraction
    }).toPromise();
  }

  async saveFormData(submission: FormSubmissionPayload) {
    return this.dataService.post(`${this.publicConfigService.rpBaseUrl}/api/${submission.stageId}/formsubmission/${submission.userId}`, {
      data: submission.data,
      userId: submission.userId,
      stageId: submission.stageId,
    }).toPromise();
  }

  async submit(formStageId: string, userId: string, triggerNotification = false) {
    return this.dataService.put(`${this.publicConfigService.rpBaseUrl}/api/${formStageId}/formsubmission/${userId}/submit`, { triggerNotification }).toPromise();
  }

  async loadFormData(stageId: string, userId: string) {
    return this.dataService.get(`${this.publicConfigService.rpBaseUrl}/api/${stageId}/formsubmission/${userId}`).toPromise();
  }

  async getForms() {
    return this.dataService.get(`${this.publicConfigService.rpBaseUrl}/api/form-management/forms`).toPromise();
  }
}
