import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { toQueryParams } from "../utils/http.util";
import { PublicConfigService } from "./public.config.service";

@Injectable({ 
    providedIn: 'root' 
})
export class PeerJudgingService {

    constructor(private httpClient: HttpClient, private publicConfigService: PublicConfigService) {

    }

    GetPeerJudgingList(stageId: string) {
        return this.httpClient.get(`${this.publicConfigService.rpBaseUrl}/api/judging/${stageId}/judge-judgements-list`);
    }

    getJudgementSteps(judgementId: string) {
        return this.httpClient.get(`${this.publicConfigService.rpBaseUrl}/api/judging/${judgementId}/judgement-steps`);
    }

    saveJudgementSteps(body: any) {
        return this.httpClient.put(`${this.publicConfigService.rpBaseUrl}/api/judging/upsert-judgement-step`, body);
    }

    editReview(judgementId: string) {
        return this.httpClient.put(`${this.publicConfigService.rpBaseUrl}/api/judging/${judgementId}/unsubmit`, null);
    }
}
