<div class="win">
  <div class="main">
    <div [ngSwitch]="errorStatus">
      <div *ngSwitchCase="403">
        <h1>403: Access Forbidden</h1>
        <p>
          Looks like you don’t have permission to this screen. If you believe
          this is wrong, please contact the competition administrator at
          <a [href]="mailto(supportEmail, 'Access Forbidden')">
            {{ supportEmail }}
          </a>
        </p>
      </div>
      <div *ngSwitchDefault>
        <h1>404: Page Not Found</h1>
        <p>Oops, we can’t seem to find the page you’re looking for.</p>
      </div>
    </div>
    <button routerLink="/">Go to Dashboard</button>
  </div>
</div>
