import { Component, OnInit, Output, EventEmitter, ViewChild, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Dropdown } from "primeng/dropdown";

@Component({
  selector: "app-export-data",
  templateUrl: "./export-data.component.html",
  styleUrls: ["./export-data.component.scss"],
})
export class ExportDataComponent implements OnChanges {
  @Input() showZipDownload = false;

  @ViewChild('downloadComponent') downloadComponent: Dropdown

  @Output() download = new EventEmitter<boolean>();

  items = [
    { name: "Excel", img: "./../../assets/fonts/excel.svg", code: "xlsx" },
    { name: "CSV", img: "./../../assets/fonts/csv.svg", code: "csv" },
  ];

  constructor() {}


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showZipDownload']?.currentValue) {
      this.items.push({
        name: "ZIP", img: "./../../assets/fonts/zip.svg", code: "zip"
      });
    }
  }

  onChange(event: any): void {
    // do nothing if event.value is null
    if (event.value !== null) {
      this.download.emit(event.value);
      // clear the dropdown, so that multiple clicks on the same option work
      // this triggers another onChange event with event.value === null
      // that's why we need the if condition above
      this.downloadComponent.clear();  
    };
  }
}
