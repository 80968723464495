import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormioCustomComponent } from "@formio/angular";
import { BehaviorSubject, Subject, of } from "rxjs";
import { tap, debounceTime, switchMap } from "rxjs/operators";
import { DataService } from "src/app/shared/services/data.service";
import { Field } from "./model/field.model";
import {
  createNotApplicableLocation,
  createNoResultsFoundLocation,
  Location,
  NotApplicableLocation,
} from "./model/location.model";
import { createEmptyLocation } from "./model/location.model";
import { PublicConfigService } from "src/app/shared/services/public.config.service";

@Component({
  selector: "app-location",
  templateUrl: "./location.component.html",
  styleUrls: ["./location.component.scss"],
})
export class LocationComponent
  implements FormioCustomComponent<string>, OnInit {
  private initialized = false;

  private _value: string;
  @Input()
  public set value(v: string) {
    this._value = v;
    this.init();
  }

  public get value(): string {
    return this._value;
  }

  private _fieldNames: string;
  @Input()
  public set fieldNames(v: string) {
    this._fieldNames = v;
    this.init();
  }

  public get fieldNames(): string {
    return this._fieldNames;
  }

  public get isValid(): boolean {
    let result = false;
    if (!this.required && (this.isControlEmpty || this.validateLocations())) {
      result = true;
    } else if (this.required && !this.isControlEmpty && this.validateLocations()) {
      result = true;
    }
    return result;
  }

  // used to check if the control is empty or not
  public isControlEmpty: boolean;

  @Input()
  locationDatasetName: string;

  @Input()
  disabled: boolean;

  @Input()
  label: string;

  @Input()
  hideKeys: boolean;

  @Input()
  required: boolean;

  @Output()
  valueChange = new EventEmitter<string>();

  @ViewChildren("search") searchRef: QueryList<ElementRef>;

  fields: Field[];
  currentField: Field = null;
  validationMessage = "";
  isDirty = false;
  private _loading$ = new BehaviorSubject<boolean>(false);
  private _search$ = new Subject<void>();
  get loading$() {
    return this._loading$.asObservable();
  }

  private lastSearchResults: any;
  private lastSearchUrl: string;

  public isSearching: boolean = false;

  constructor(
    private publicConfigService: PublicConfigService,
    private dataService: DataService,
    private elRef: ElementRef
  ) { }

  ngOnInit() {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this.search()),
        tap(() => this._loading$.next(false))
      )
      .subscribe((options) => {
        if (options) {
          // if options is not false this is a real search
          this.lastSearchResults = options;
          this.isSearching = false;
          if (this.currentField) {
            if (options && options.data && options.data.length) {
              this.currentField.choices = options.data;
            } else if (this.validateHigherLevelFields()) {
              if (this.currentField.search) {
                // if we are searching - no results found
                this.currentField.choices = [createNoResultsFoundLocation()];
              } else {
                // if we are not searching - not applicable location
                this.currentField.choices = [createNotApplicableLocation()];
              };
            }
          }
        } else {
          // if options is false this is a cached search
          this.isSearching = false;
          if (this.currentField) {
            if (this.lastSearchResults && this.lastSearchResults.data && this.lastSearchResults.data.length) {
              this.currentField.choices = this.lastSearchResults.data;
            } else if (this.validateHigherLevelFields()) {
              if (this.currentField.search) {
                // if we are searching - no results found
                this.currentField.choices = [createNoResultsFoundLocation()];
              } else {
                // if we are not searching - not applicable location
                this.currentField.choices = [createNotApplicableLocation()];
              };
            }
          }
        }
      });
  }

  ngOnDestroy() {
    this._search$.complete();
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      if (this.fields) {
        this.currentField = null;
        this.hideFields();
      }
    }
  }

  onSearchChange() {
    this._search$.next();
  }

  search() {
    this.isSearching = true;
    let url = `${this.publicConfigService.rpBaseUrl}/api/dataset/dropdown/${this.locationDatasetName}?limit=1000`
    if (this.currentField) {
      if (this.currentField.search) {
        url = `${url}&searchTerm=${this.currentField.search}`;
      }
      const fieldParent = this.fields.find(
        (f) => f.level === this.currentField.level - 1
      );
      if (fieldParent) {
        url = `${url}&parentValue=${fieldParent.location.value}`;
      }
    }

    if (url === this.lastSearchUrl) {
      return of(false);
    }

    this.lastSearchUrl = url;
    return this.dataService.get(url);
  }

  clearFieldValue(field: Field) {
    field.location = createEmptyLocation();
    this.resetLowerLevelFields(field);
    this.isControlEmpty = true;
    this.setValue();
  }

  setFieldLocation(field: Field, location: Location) {
    if (
      field.location.value !== location.value ||
      field.location.text !== location.text
    ) {
      field.location = location;
      this.resetLowerLevelFields(field);
      this.isControlEmpty = false;
      this.setValue();
    }
    this.currentField = null;
    this.hideFields();
  }

  setActiveField(field: Field) {
    field.active = !field.active;
    if (field.active) {
      this.currentField = field;
      this.hideFields();
      const searchInput = this.searchRef
        .toArray()
        .find((r) => r.nativeElement.getAttribute("level") == field.level);
      setTimeout(() => searchInput.nativeElement.focus());
      this._search$.next();
    } else {
      this.currentField = null;
      this.hideFields();
    }
  }

  private setValue() {
    const locationData = {
      value: this.fields.map((f) => {
        return { ...f, choices: [] };
      }),
      hideKeys: this.hideKeys,
      valid: false,
    };

    locationData.valid = this.isValid;

    this.value = JSON.stringify(locationData);
    this.valueChange.emit(this.value);
  }

  private hideFields() {
    this.fields.forEach((f) => {
      if (!this.currentField || this.currentField.name !== f.name) {
        f.active = false;
        f.search = "";
        f.choices = [];
      }
    });
  }

  private resetLowerLevelFields(field: Field) {
    this.fields
      .filter((f) => f.level > field.level)
      .forEach((field) => {
        field.location = createEmptyLocation();
      });
  }

  private validateLocations() {
    this.fields.forEach((f) => (f.valid = true));
    const invalidFields = this.fields.filter(
      (f) => f.location.value === 0 && f.location.text !== NotApplicableLocation
    );
    invalidFields.forEach((f) => (f.valid = false));
    this.isDirty = true;
    return invalidFields.length === 0;
  }

  private validateHigherLevelFields() {
    return !this.fields.some(
      (f) =>
        f.level < this.currentField.level &&
        f.location.value === 0 &&
        f.location.text !== NotApplicableLocation
    );
  }

  private init() {
    if (this.value && !this.initialized) {
      this.fields = JSON.parse(this.value).value;
      this.validateLocations();
      this.hideFields();
      this.isControlEmpty = false;
      this.initialized = true;
    } else if (this.fieldNames && !this.initialized) {
      let index = 0;
      const fieldNames = this.fieldNames.split(",");
      const fields = fieldNames.map((name) => {
        const locationSubmit = { key: 0, value: "" };
        return {
          name: name,
          active: false,
          location: createEmptyLocation(
            locationSubmit.key || 0,
            locationSubmit.value
          ),
          choices: [],
          level: ++index,
          search: "",
          valid: true,
        };
      });
      this.fields = fields;
      this.initialized = true;
    }
  }
}
