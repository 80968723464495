import { Injector, NgModule } from "@angular/core";
import { TaxonomyComponent } from "./taxonomy/taxonomy.component";
import { FormioModule } from "@formio/angular";
import { FormsModule } from "@angular/forms";
import { registerTaxonomyComponent } from "./taxonomy/taxonomy.formio";
import { BudgetComponent } from "./budget/budget.component";
import { registerBudgetComponent } from "./budget/budget.formio";
import { registerVideoComponent } from "./video/video.formio";
import { CommonModule } from "@angular/common";
import { VideoComponent } from "./video/video.component";
import { YouTubePlayerModule } from "@angular/youtube-player";
import { FileUploadModule } from "primeng/fileupload";
import { registerFileUploadComponent } from "./fileUpload/fileUpload.formio";
import { FileUploadComponent } from "./fileUpload/fileUpload.component";
import { LocationComponent } from "./location/location.component";
import { registerLocationComponent } from "./location/location.formio";
import { LocationWrapperComponent } from "./location/location-wrapper/location-wrapper.component";
import { TreeModule } from 'primeng/tree';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToastModule } from 'primeng/toast';
import { InputTextareaModule } from "primeng/inputtextarea";
import { ToolbarModule } from "primeng/toolbar";
import { MessageService } from 'primeng/api';
import { SharedModule } from "src/app/shared/shared.module";

@NgModule({
    declarations: [
        TaxonomyComponent,
        BudgetComponent,
        VideoComponent,
        FileUploadComponent,
        LocationComponent,
        LocationWrapperComponent
    ],
    imports: [
        TreeModule,
        FormioModule,
        TableModule,
        InputTextModule,
        InputTextareaModule,
        FormsModule,
        ButtonModule,
        ToolbarModule,
        InputNumberModule,
        ToastModule,
        CommonModule,
        YouTubePlayerModule,
        FileUploadModule,
        SharedModule
    ],
    providers: [MessageService]
})
export class FormioCustomComponentsModule {
  constructor(injector: Injector) {
    registerTaxonomyComponent(injector);
    registerBudgetComponent(injector);
    registerVideoComponent(injector);
    registerFileUploadComponent(injector);
    registerLocationComponent(injector);
  }
}
