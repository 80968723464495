import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class TabTitleService {

    private competitionName = '';

    constructor(private titleService: Title, private storage: StorageService) {
        this.competitionName = this.storage.retrieve('competitionName');
    }

    setTabTitle(tabTitle: string) {
        this.titleService.setTitle(`${tabTitle} - ${this.competitionName}`);
    }

}