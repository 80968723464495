<div class="display-form-info" *ngIf="!loading">
  <div class="print-icon">
    <button class="button-icon icon-default" (click)="printPage()" pTooltip="Print">
      <span class="material-icons-round">print</span>
    </button>
  </div>
  <div class="competition-info">
    <div class="logo-wrap">
      <img *ngIf="competitionLogo" [src]="competitionLogo">
    </div>
    <h3 class="competition-name" *ngIf="competitionName">{{ competitionName }}</h3>
  </div>
  <div class="form-info">
    <h1 class="form-name">{{ formDetails.name }}</h1>
    <h5 class="form-owner">{{ formData.userIdentifier }}</h5>
  </div>
</div>
<div class="formio-custom-wrapper">
  <ng-container *ngIf="!loading">
    <formio 
      [form]="formDefinition"
      [submission]="{'data': formData.data}"
      (ready)="formioReady($event)"
      [readOnly]="true"
      [renderOptions]="renderOptions"
      (render)="doneRendering()"
    ></formio>
  </ng-container>
</div>
