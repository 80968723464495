import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormioCustomComponent, FormioEvent } from "@formio/angular";
import { LocationWrapped } from "../model/location.model";

@Component({
  selector: "app-location-wrapper",
  templateUrl: "./location-wrapper.component.html",
  styleUrls: ["./location-wrapper.component.scss"],
})
export class LocationWrapperComponent implements FormioCustomComponent<string> {
  private initialized = false;

  private _value: string;
  @Input()
  public set value(v: string) {
    this._value = v;
    this.init();
  }

  public get value(): string {
    return this._value;
  }

  @Input()
  locationDatasetName: string;

  @Input()
  disabled: boolean;

  @Input()
  label: string;

  @Input()
  hideKeys: boolean;

  @Input()
  fieldNames: string;

  @Input()
  required: boolean;

  @Input()
  maxSelection: number;

  @Output()
  valueChange = new EventEmitter<string>();

  @Output()
  formioEvent = new EventEmitter<FormioEvent>();

  locations: LocationWrapped[] = [{ index: 0, value: null }];

  addLocation() {
    this.locations = [
      ...this.locations,
      { index: this.locations.length, value: null },
    ];
    this.setValue();
  }

  removeLocation(location: { index: number }) {
    this.locations = this.locations.filter((l) => l.index !== location.index);
    this.setValue();
  }

  locationValueChange(value: string, location: { value: any }) {
    location.value = JSON.parse(value);
    this.setValue();
  }

  setValue() {
    this.value = JSON.stringify({
      value: this.locations,
      hideKeys: this.locations.some((l) => l.value?.hideKeys),
      valid: !this.locations.some((l) => !l.value?.valid),
    });
    this.valueChange.emit(this.value);
    this.formioEvent.emit({ eventName: "change", data: { isChanged: true } });
  }

  private init() {
    if (this.value && !this.initialized) {
      this.locations = JSON.parse(this.value).value;
      this.initialized = true;
    }
  }
}
