import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import Quill from "quill";
import Link from "quill/formats/link";

@Component({
  standalone: true,
  selector: "app-rich-text-editor",
  imports: [CommonModule],
  templateUrl: "./rich-text-editor.component.html",
  styleUrls: ["./rich-text-editor.component.scss"],
})
export class RichTextEditorComponent implements OnChanges, AfterViewInit {
  @Input() value = "";
  @Output() valueChange = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<{ text: string; html: string }>();
  @Input() focus = false;
  @Input() placeholder = "Enter text";
  @Input() height = "200px";
  @Input() limit = 0;
  @Input() formats = ["header", "text", "list", "link", "clear"];
  @Input() styleClass = '';

  // a list of attributes by key name that will be preserved on paste
  // if empty (default) - preserve all
  // NOTE - sanitization will fail on pasting styled text if no valid attributes are provided
  // due to inline styles being present in the pasted content
  // example usage - ['bold', 'italic', 'underline', 'link']
  @Input() validAttributesOnPaste: string[] = [];

  @ViewChild("toolbar", { read: ElementRef })
  toolbarElement: ElementRef<HTMLElement>;
  @ViewChild("editor", { read: ElementRef })
  editorElement: ElementRef<HTMLElement>;

  editor: Quill = null;
  charCount = 0;

  constructor() {
    // register custom quill link blot
    Quill.register(CustomLink, true);
  }

  ngAfterViewInit(): void {
    this.initEditor();
  }

  initEditor() {
    // init editor
    this.editor = new Quill(this.editorElement.nativeElement, {
      modules: {
        toolbar: this.toolbarElement.nativeElement,
        keyboard: {
          bindings: {
            tab: {
              key: 9,
              handler: function() {
                // Handle tab
                // quil handles tab key in order to add indentation
                // this reverts default tab behaviour to focus next interactable element in dom
                return true;
              }
            },
          }
        }
      },
      placeholder: this.placeholder,
      theme: "snow",
    });

    // if valid keys (attributes) are not provided do not alter the default paste behaviour
    // this preserves usual behaviour when pasting inside our various editors - e.g. on setup screens
    if (this.validAttributesOnPaste.length > 0) {
      // this handles paste event inside editor
      this.editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        // iterate through each delta ops - this is the pasted content in quill's delta format
        // delta is an object with a single property ops
        // ops is an array of objects (operations)
        // operations have attributes, inserts and more, but we are interested only in attributes at the moment
        // insert is the actual string - plain text
        // attributes are all attributes that are attached to the specific insert - bold, italic, link, underline, etc..
        delta.ops.forEach((op, index) => {
          // if the ops has an attribute property of type object
          if (op.attributes && typeof op.attributes === 'object') {
            // check if the keys inside the attribute are among our list of valid attributes
            // if they are not - delete the attribute
            Object.keys(op.attributes).forEach((key) => this.validAttributesOnPaste.includes(key) || delete op.attributes[key]);
          };
        });
        // return the updated delta object
        return delta;
      });
    };

    // attach text change event only on user interaction
    this.editor.on("text-change", (delta, oldDelta, source) => {
      if (source === "user") {
        const text = this.editor.getText().trim();
        if (this.limit && text.length > this.limit) {
          this.editor.deleteText(this.limit, text.length);
        }
        this.calculateCharCount();
        this.value = text ? this.editor.getSemanticHTML() : "";
        this.valueChange.emit(this.value);
        this.onChange.emit({
          text: this.editor.getText().trim(),
          html: this.editor.getSemanticHTML(),
        });
      }
    });

    // add focus
    if (this.focus) {
      this.editor.focus();
    }

    // set initial value
    if (this.value) {
      this.setEditorValue();
    }
  }

  setEditorValue() {
    // we set it to "silent" so it does not trigger as user interaction
    this.editor.setContents(
      this.editor.clipboard.convert({ html: this.value }),
      "silent"
    );
    this.calculateCharCount();
  }

  calculateCharCount() {
    // calculate char count in timeout since otherwise we get angular expression check error..
    setTimeout(
      () => (this.charCount = this.editor?.getText().trim().length),
      0
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    // we need to check that the change is comming from outside the component, not from user interaction
    if (
      this.editor &&
      !changes["value"].firstChange &&
      changes["value"].currentValue !== this.editor.getSemanticHTML()
    ) {
      this.setEditorValue();
    }
  }
}

// We need this CustomLink to extend the default one in Quill in order to set tabindex=0 for all <a> tags
class CustomLink extends Link {
  static create(url: string): HTMLElement {
    const node = super.create(url);
    node.setAttribute("href", this.sanitize(url));
    node.setAttribute("target", "_blank");
    node.setAttribute("tabindex", "0");
    return node;
  }
}