import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { CompetitionInformation } from '../model/common.model';
import { PrintPageTypes, printPageContent } from '../model/print.model';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  private competitionLogo: string = '';
  private competitionName: string = '';
  private currentYear: number = new Date().getFullYear();

  private pageType: string = '';
  private stageName: string = '';
  private fullName: string = '';
  private firstPageCompiledText: string = '';

  private compiledHeaderTitle: string = '';

  private printModalOpened: boolean = false;

  constructor(
    private storage: StorageService
  ) {
    const competitionLogo = this.storage.retrieve(CompetitionInformation.brandingsLogo);
    if (competitionLogo !== null) {
      this.competitionLogo = `${competitionLogo}`;
    };
    const competitionName = this.storage.retrieve(CompetitionInformation.competitionName);
    if (competitionName !== null) {
      this.competitionName = `${competitionName}`;
    };
  }

  printPage(firstPageContent?: printPageContent) {

    if (firstPageContent) {
      this.pageType = firstPageContent.pageType;
      this.stageName = firstPageContent.stageName;
      this.fullName = firstPageContent.fullName;

      switch (this.pageType) {
        case PrintPageTypes.PeerReviewResultsForStaff:
          this.firstPageCompiledText = `<b>${this.stageName}</b> Results:<br><b>${this.fullName}</b>`;
          this.compiledHeaderTitle = `${firstPageContent.stageName} Results: ${firstPageContent.fullName}`;
          break;

        case PrintPageTypes.PeerReviewResultsForParticipants:
          this.firstPageCompiledText = `<b>${this.stageName}</b> Results:<br><b>${this.fullName}</b>`;
          this.compiledHeaderTitle = `${firstPageContent.stageName} Results: ${firstPageContent.fullName}`;
          break;

        case PrintPageTypes.EvaliationReviewResultsForStaff:
            this.firstPageCompiledText = `<b>${this.stageName}</b> Results:<br><b>${this.fullName}</b>`;
            this.compiledHeaderTitle = `${firstPageContent.stageName} Results: ${firstPageContent.fullName}`;
            break;
  
        case PrintPageTypes.EvaluationReviewResultsForParticipants:
          this.firstPageCompiledText = `<b>${this.stageName}</b> Results:<br><b>${this.fullName}</b>`;
          this.compiledHeaderTitle = `${firstPageContent.stageName} Results: ${firstPageContent.fullName}`;
          break;

        case PrintPageTypes.Form:
          this.firstPageCompiledText = `<b>${this.stageName}</b><br><b>${this.fullName}</b>`;
          this.compiledHeaderTitle = `${firstPageContent.stageName} - ${firstPageContent.fullName}`;
          break;
        
        default:
          break;
      }
    }

    const firstPageStructure = `
      <div class="print-page-separator"></div>
      <div class="first-page">
        <div class="first-page-content">
          <div class="first-page-content-inner">
            <div class="comp-branding">
              <span class="logo-wrap">
                <img src="${this.competitionLogo}">
              </span>
              <span class="comp-name">${this.competitionName}</span>
            </div>
            <p class="content-title">${this.firstPageCompiledText}</p>
          </div>
        </div>
        <div class="first-page-footer">
          <div class="carrot-logo">
            <img id="footer-logo" src="assets/images/carrot-logo.png" width="129" alt="footer-logo" class="footer-logo">
          </div>
          <div class="text-wrap">
            <p>&copy; ${this.currentYear}. All rights reserved.</p>
          </div>
        </div>
      </div>
    `

    // toggle local flag
    this.printModalOpened = true;

    // add first (branding) page if needed
    let firstPageDiv;
    if (firstPageContent) {
      firstPageDiv = document.createElement('div');
      firstPageDiv.innerHTML = firstPageStructure;
      document.body.prepend(firstPageDiv);
    }

    // add compiled header title (if any) to it's placeholder
    if (this.compiledHeaderTitle) {
      document.querySelector('.print-compiled-header-title').innerHTML = this.compiledHeaderTitle;
    }

    // timeout to help with image loading
    setTimeout(() => {
      // Open the print modal
      window.print();

      // Remove the first page content when the print modal is closed
      const printModal = window.matchMedia('print');
      if (!printModal.matches) {
        if (this.printModalOpened) {
          // remove the first page element
          if (firstPageContent) {
            firstPageDiv.remove();
          }
          // remove compiled header title (if any) from it's placeholder
          if (this.compiledHeaderTitle) {
            document.querySelector('.print-compiled-header-title').innerHTML = this.compiledHeaderTitle;
            this.compiledHeaderTitle = '';
          };
          // toggle local flag
          this.printModalOpened = false;
        };
      };
    }, 100);
  }

  // used for headless pdf generation only
  generateFirstPageForPDFGeneration(firstPageContent: printPageContent) {
    this.pageType = firstPageContent.pageType;
    this.stageName = firstPageContent.stageName;
    this.fullName = firstPageContent.fullName;

    switch (this.pageType) {
      case PrintPageTypes.PeerReviewResultsForStaff:
        this.firstPageCompiledText = `<b>${this.stageName}</b> Results:<br><b>${this.fullName}</b>`;
        this.compiledHeaderTitle = `${firstPageContent.stageName} Results: ${firstPageContent.fullName}`;
        break;

      case PrintPageTypes.PeerReviewResultsForParticipants:
        this.firstPageCompiledText = `<b>${this.stageName}</b> Results:<br><b>${this.fullName}</b>`;
        this.compiledHeaderTitle = `${firstPageContent.stageName} Results: ${firstPageContent.fullName}`;
        break;

      case PrintPageTypes.EvaliationReviewResultsForStaff:
          this.firstPageCompiledText = `<b>${this.stageName}</b> Results:<br><b>${this.fullName}</b>`;
          this.compiledHeaderTitle = `${firstPageContent.stageName} Results: ${firstPageContent.fullName}`;
          break;

      case PrintPageTypes.EvaluationReviewResultsForParticipants:
        this.firstPageCompiledText = `<b>${this.stageName}</b> Results:<br><b>${this.fullName}</b>`;
        this.compiledHeaderTitle = `${firstPageContent.stageName} Results: ${firstPageContent.fullName}`;
        break;

      case PrintPageTypes.Form:
        this.firstPageCompiledText = `<b>${this.stageName}</b><br><b>${this.fullName}</b>`;
        this.compiledHeaderTitle = `${firstPageContent.stageName} - ${firstPageContent.fullName}`;
        break;
      
      default:
        break;
    }

    const firstPageStructure = `
      <div class="print-page-separator"></div>
      <div class="first-page">
        <div class="first-page-content">
          <div class="first-page-content-inner">
            <div class="comp-branding">
              <span class="logo-wrap">
                <img src="${this.competitionLogo}">
              </span>
              <span class="comp-name">${this.competitionName}</span>
            </div>
            <p class="content-title">${this.firstPageCompiledText}</p>
          </div>
        </div>
        <div class="first-page-footer">
          <div class="carrot-logo">
            <img id="footer-logo" src="assets/images/carrot-logo.png" width="129" alt="footer-logo" class="footer-logo">
          </div>
          <div class="text-wrap">
            <p>&copy; ${this.currentYear}. All rights reserved.</p>
          </div>
        </div>
      </div>
    `

    let firstPageDiv;
    firstPageDiv = document.createElement('div');
    firstPageDiv.innerHTML = firstPageStructure;
    document.body.prepend(firstPageDiv);
    if (this.compiledHeaderTitle) {
      document.querySelector('.print-compiled-header-title').innerHTML = this.compiledHeaderTitle;
    }
  }
}
