import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PublicConfigService } from "../../shared/services/public.config.service";

@Injectable({ providedIn: "root" })
export class FormHeaderDataService {
  public publicConfig: any;
  constructor(
    private httpClient: HttpClient,
    publicConfigService: PublicConfigService
  ) {
    this.publicConfig = publicConfigService;
  }

  GetHeader(stageId: string, userId: string) {
    const options = {};
    return this.httpClient.get(
      `${this.publicConfig.rpBaseUrl}/api/${stageId}/componentheader/${userId}`,
      options
    );
  }

  submitForm(stageId: string, userId: string, data) {
    return this.httpClient.post(
      `${this.publicConfig.rpBaseUrl}/api/${stageId}/formsubmission/${userId}`,
      data,
      {}
    );
  }

  unsubmitRegistrationForm(userId) {
    return this.httpClient.post(
      `${this.publicConfig.rpBaseUrl}/api/formsubmission/${userId}/regedit`,
      true,
      {}
    );
  }
}
