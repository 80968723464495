import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CheckboxModule } from "primeng/checkbox";
import { CheckboxComponent } from "./checkbox.component";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [CommonModule, CheckboxModule, FormsModule],
  declarations: [CheckboxComponent],
  exports: [CheckboxComponent],
  providers: []
})
export class ChkModule {}
