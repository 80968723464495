import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { InputComponent } from "./input.component";
import { TooltipModule } from "primeng/tooltip";

@NgModule({
  imports: [CommonModule, FormsModule, TooltipModule],
  declarations: [InputComponent],
  exports: [InputComponent],
  providers: []
})

export class InputModule {}