import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { DropdownComponent } from "./dropdown.component";

@NgModule({
  imports: [CommonModule, DropdownModule, FormsModule],
  declarations: [DropdownComponent],
  exports: [DropdownComponent],
  providers: []
})
export class DDModule {}
