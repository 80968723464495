import { Injector } from "@angular/core";
import {
  Components,
  FormioCustomComponentInfo,
  registerCustomFormioComponent,
} from "@formio/angular";
import baseEditForm from "formiojs/components/_classes/component/Component.form";
import LocationEditDisplay from "./editForm/location.edit.display";
import { getLocationEditData } from "./editForm/location.edit.data";
import LocationEditValidation from "./editForm/location.edit.validation";
import { LocationWrapperComponent } from "./location-wrapper/location-wrapper.component";
import { PublicConfigService } from "src/app/shared/services/public.config.service";

let rpBaseUrl = "";
export function locationEditForm() {
  const form = baseEditForm([
    {
      key: "display",
      components: LocationEditDisplay,
    },
    {
      key: "data",
      components: getLocationEditData(rpBaseUrl),
    },
    {
      key: "validation",
      components: LocationEditValidation,
    },
  ]);
  return form;
}

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: "location",
  selector: "formio-location",
  title: "Location",
  group: "basic",
  icon: "fa",
  editForm: locationEditForm,
  fieldOptions: ["label"],
  extraValidators: ["custom"],
  schema: {
    customDefaultValue: "{}",
  },
};

export function registerLocationComponent(injector: Injector) {
  const configService = injector.get(PublicConfigService);
  rpBaseUrl = configService.rpBaseUrl;
  
  try {
    registerCustomFormioComponent(
      COMPONENT_OPTIONS,
      LocationWrapperComponent,
      injector
    );
  } catch (err) {
    // custom component already exists
  }
}
