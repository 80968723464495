import { Pipe, PipeTransform } from "@angular/core";
import { PublicConfigService } from "../services/public.config.service";

@Pipe({
  name: "formatLinks"
})
export class formatLinksPipe implements PipeTransform {

  constructor( public publicConfig: PublicConfigService) {}

  transform(html: string): string {
    return html?.replace(/https:\/\/febaseurl/g, this.publicConfig.feBaseUrl);
  }
}
