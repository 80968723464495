import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CompetitionInformation } from 'src/app/shared/model/common.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { SsoAuthDisplayService } from 'src/app/shared/services/sso-auth.display.service';
import { SsoAuthService } from 'src/app/shared/services/sso-auth.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TabTitleService } from 'src/app/shared/services/tab-title.service';

@Component({
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  competitionLogo = '';

  showLogin: boolean = false;  

  loaderShown: boolean = false;

  constructor(
    private storage: StorageService,
    private commonService: CommonService,
    private tabTitleService: TabTitleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public ssoAuthService: SsoAuthService,
    public ssoAuthDisplayService: SsoAuthDisplayService,
  ) { }

  async ngOnInit() {
    const token = this.activatedRoute.snapshot.paramMap.get("token");
    
    const ssosessionParameter = this.activatedRoute.snapshot.queryParams["ssosession"];

    if(ssosessionParameter !== "false") {
      await this.ssoAuthService.logout(`/verify-email/${token}?ssosession=false`);
    }   

    this.verifyEmail(token);
    this.tabTitleService.setTabTitle('Verify Email');

    this.commonService.competitionInfo
      .pipe(filter(res => !!res))
      .subscribe(() => {
        const logoFromStorage = this.storage.retrieve(CompetitionInformation.loginWidgetLogoUrl);
        if (logoFromStorage !== null) {
          this.competitionLogo = logoFromStorage;
        };
        this.showLogin = true;
      });
  }

  async verifyEmail(token: string) {

    try
    {
      const response = await this.ssoAuthService.verifyEmail(token);
      if (response.success) {

        this.ssoAuthDisplayService.showGenericScreen(
          "Email verified",
          "You have successfully verified your email. Please proceed to Login.",
          ["Back to Login"]
        )
      }
    }
    catch(err){
      this.ssoAuthDisplayService.handleApiError(err);
    }
  }

  backToLogin() {
    this.router.navigateByUrl('/login');
  }
}
