import { jsDateToISODate } from "../utils/general.utils";

export interface IFormsSubmittedAtFilter {
  condition: string;
  rules: IFormsSubmittedAtFilterRule[];
}

export interface IFormsSubmittedAtFilterRule {
  field: string;
  operator: string;
  dateTimeValue: string;
}

export interface IUserFormDataFilter {
  condition: string;
  rules: IUserFormDataFilterRule[];
}

export interface IUserFormDataFilterRule {
  field: string;
  operator: string;
  value: string;
}

export function createSubmittedAtFiltersFromGridColumns(columns) {
  let filters = [];
  for (const col of columns.filter((c) => c.field.startsWith('submittedAt_'))) {
  let filter: IFormsSubmittedAtFilter = {
    condition: "",
    rules: [{ field: col.field, operator: "", dateTimeValue: "" }],
  };
  filters.push(filter);
  }
  return filters;
}

export function createSubmittedAtFiltersFromGridFilters(columns, gridFilters) {
  let filters = [];
  for (const col of columns.filter((c) => c.field.startsWith('submittedAt_'))) {
    let filter: IFormsSubmittedAtFilter = {
      condition: "",
      rules: [],
    };
    let i = 0,
      gridFilter = gridFilters[col.field]?.[i];
    while (gridFilter) {
      filter.condition = gridFilter.operator;
      let filterRule: IFormsSubmittedAtFilterRule = {
        field: col.field,
        operator: gridFilter.matchMode,
        dateTimeValue: jsDateToISODate(gridFilter.value),
      };
      filter.rules.push(filterRule);
      gridFilter = gridFilters[col.field][++i];
    }
    filters.push(filter);
  }
  return filters;
}

export function createFromGridColumns(columns) {
  let filters = [];
  for (const col of columns.filter((c) => c.isUserFormData)) {
    let filter: IUserFormDataFilter = {
      condition: "",
      rules: [{ field: col.field, operator: "", value: "" }],
    };
    filters.push(filter);
  }
  return filters;
}

export function createFromGridFilters(columns, gridFilters) {
  let filters = [];
  for (const col of columns.filter((c) => c.isUserFormData)) {
    let filter: IUserFormDataFilter = {
      condition: "",
      rules: [],
    };
    let i = 0,
      gridFilter = gridFilters[col.field]?.[i];
    while (gridFilter) {
      filter.condition = gridFilter.operator;
      let filterRule: IUserFormDataFilterRule = {
        field: col.field,
        operator: gridFilter.matchMode,
        value: gridFilter.value,
      };
      filter.rules.push(filterRule);
      gridFilter = gridFilters[col.field][++i];
    }
    filters.push(filter);
  }
  return filters;
}
