import { Injector } from "@angular/core";
import {
  Components,
  FormioCustomComponentInfo,
  registerCustomFormioComponent,
} from "@formio/angular";
import { TaxonomyComponent } from "./taxonomy.component";
import baseEditForm from "formiojs/components/_classes/component/Component.form";
import TaxonomyEditDisplay from "./editForm/taxonomy.edit.display";
import { getTaxonomyEditData } from "./editForm/taxonomy.edit.data";
import taxonomyEditValidation from "./editForm/taxonomy.edit.validation";
import { PublicConfigService } from "src/app/shared/services/public.config.service";


let rpBaseUrl = "";
function taxonomyEditForm() {
  
  const form = baseEditForm([
    {
      key: "display",
      components: TaxonomyEditDisplay,
    },
    {
      key: "data",
      components: getTaxonomyEditData(rpBaseUrl),
    },
    {
      key: "validation",
      components: taxonomyEditValidation,
    },
  ]);
  return form;
}

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: "taxonomy",
  selector: "formio-taxonomy",
  title: "Taxonomy",
  group: "basic",
  icon: "fa fa-tree",
  editForm: taxonomyEditForm,
  schema: {
    customDefaultValue: "{}",
  },
};

export function registerTaxonomyComponent(injector: Injector) {
  const configService = injector.get(PublicConfigService);
  rpBaseUrl = configService.rpBaseUrl;
  
  try {
    registerCustomFormioComponent(
      COMPONENT_OPTIONS,
      TaxonomyComponent,
      injector
    );
  } catch (err) {
    // custom component already exists
  }
}
