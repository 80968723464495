import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DropdownGenericComponent } from './dropdown-generic.component';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule
  ],
  declarations: [DropdownGenericComponent],
  exports: [DropdownGenericComponent],
  
})
export class DropdownGenericModule { }
