import { Component, OnInit } from "@angular/core";
import { TabTitleService } from "../shared/services/tab-title.service";
import { CommonService } from "../shared/services/common.service";

@Component({
  selector: "app-guest",
  templateUrl: "./guest.component.html",
  styleUrls: ["./guest.component.scss"],
})
export class GuestComponent implements OnInit {

  email = '';
  compName = '';

  constructor(private tabTitleService: TabTitleService, private compInfo: CommonService) {}

  ngOnInit() {
    this.tabTitleService.setTabTitle("Permission Disabled");
    this.compInfo.competitionInfo.subscribe(info => {
      this.email = info.data.supportEmailAddress;
      this.compName = info.data.competitionName;
    });
  }
}
