import { Injectable } from "@angular/core";
import { DataService } from "../../shared/services/data.service";
import { PublicConfigService } from "../../shared/services/public.config.service";
import { FormDetails } from "../model/form-details";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FormDetailsService {
  constructor(
    private dataService: DataService,
    private publicConfigService: PublicConfigService
  ) {}

  getFormDetailsByStageType(
    stageType: string,
    userId?: string
  ): Observable<FormDetails> {
    let url = `${this.publicConfigService.rpBaseUrl}/api/stagetype/${stageType}/formdetails`;
    if (userId) url = `${url}/${userId}`;
    return this.dataService.get(url);
  }

  getFormDetailsByStageId(
    stageId: string,
    userId?: string
  ): Observable<FormDetails> {
    let url = `${this.publicConfigService.rpBaseUrl}/api/stage/${stageId}/formdetails`;
    if (userId) url = `${url}/${userId}`;
    return this.dataService.get(url);
  }
}
