import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { GridConfigModalComponent } from '../components/modal/grid-config-modal/grid-config-modal.component';

export interface GridConfigModalOptions {
  gridName: string,
  stageId?: string,
  columns?: any[]
}

@Injectable({
  providedIn: 'root'
})
export class GridConfigModalService {

  constructor(private dialogService: DialogService) { }

  public openGridConfigDialog(
    options: GridConfigModalOptions,
    title: string = 'Configuration',
    btnOkText: string = 'Save changes',
    btnCancelText: string = 'Cancel',
    dialogSize: string = 'auto'): Promise<boolean> {
    const modalRef = this.dialogService.open(GridConfigModalComponent, {
      dismissableMask: false,
      styleClass: 'dialog hidden-close-dialog',
      width: dialogSize,
      data: {
        title,
        options,
        btnOkText,
        btnCancelText,
      }
    });
    return new Promise((resolve, reject) => {
      modalRef.onClose.subscribe(res => resolve(res));
    })
  }
}
