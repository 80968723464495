<div class="budget-wrap" [ngClass]="{'has-empty-value': isEmpty, 'disabled': disabled}">
  <div class="table-wrap">
    <p-table [value]="budgets" [(selection)]="selectedBudgets" (onEditCancel)="onEditCancel($event)" (onEditInit)="onEditInit($event)">
      <ng-template pTemplate="header">
        <tr>
          <td class="p-element" style="width: 4%"></td>
          <td class="p-element" style="width: 70%">Budget Item</td>
          <td class="p-element" style="width: 20%">Budget Amount</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-budget>
        <tr [ngClass]="{ 'soft-errored': !budget.budgetItemValid || !budget.budgetValueValid }">
          <td class="table-checkbox">
            <app-checkbox-button
              [(state)]="budget.selected"
              (stateChange)="onRowSelect($event, budget)"
              [disabled]="disabled"
              ariaLabel="Select to mark row for deletion via delete button in the end of the table"
            ></app-checkbox-button>
          </td>
          <td [pEditableColumn]="budget" [pEditableColumnField]="'budgetItem'" [pEditableColumnDisabled]="disabled ? true : false">
            <p-cellEditor #budgetItems>
              <ng-template pTemplate="input">
                <input
                  pInputText
                  autoResize="autoResize"
                  type="text"
                  (change)="handleChange()"
                  (keydown)="handleEnter($event, budget)"
                  (keyup)="removeLastWords(budget)"
                  [disabled]="disabled"
                  [readonly]="disabled"
                  [(ngModel)]="budget.budgetItem"
                  placeholder="Budget item short description"
                  (keydown.ArrowRight)="stopPropagation($event)"
                  (keydown.Arrowleft)="stopPropagation($event)"
                  aria-label="Enter budget row description"
                />
              </ng-template>
              <ng-template pTemplate="output">
                <div [ngClass]="[budget.budgetValue === '' ? 'output' : 'output-filled']">
                  <button class="button-icon-round icon-w-fill button-edit-budget-item" pInitEditableCol aria-label="Press Space to edit">
                    <span class="material-icons" aria-hidden="true">edit</span>
                  </button>
                  <span class="budget-item-content">{{ getBudgetItemValue(budget.budgetItem) }}</span>
                </div>
              </ng-template>
            </p-cellEditor>
          </td>
          <td [pEditableColumn]="budget" [pEditableColumnField]="'budgetValue'" [pEditableColumnDisabled]="disabled ? true : false" id="{{ 'budgetValue-' + budget.id }}">
            <p-cellEditor #budgetValues>
              <ng-template pTemplate="input">
                <input
                  pInputText
                  type="number"
                  (change)="handleChange()"
                  (keydown)="handleEnterVal($event, budget)"
                  (keyup)="removeLastDigits(budget)"
                  [(ngModel)]="budget.budgetValue"
                  placeholder="$ 0"
                  [disabled]="disabled"
                  min="0"
                  oninput="validity.valid||(value='');"
                  (keydown.ArrowRight)="stopPropagation($event)"
                  (keydown.Arrowleft)="stopPropagation($event)"
                  aria-label="Enter budget row amount"
                />
              </ng-template>
              <ng-template pTemplate="output">
                <div [ngClass]="[budget.budgetValue === '' ? 'output' : 'output-filled']">
                  <button class="button-icon-round icon-w-fill button-edit-budget-item" pInitEditableCol aria-label="Press Space to edit">
                    <span class="material-icons" aria-hidden="true">edit</span>
                  </button>
                  <span class="budget-value-content">{{ getBudgetValue(budget.budgetValue) }}</span>
                </div>
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer" let-car>
        <tr [ngClass]="{ errored: !this.totalIsValid }">
          <td></td>
          <td>
            {{ totalRequirements ? totalRequirements : "Total:" }}
          </td>
          <td>
            $ {{ getTotal() }}
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="footer-buttons-wrap" *ngIf="!disabled">
      <div class="btn-wrap">
        <p-button
          styleClass="button button-primary button-primary--medium"
          (click)="addNew()"
          [disabled]="this.budgets.length === 50"
          ariaLabel="Add a new budget row in the table above"
        >
          <span class="material-icons" aria-hidden="true">add</span>
          Add
        </p-button>
      </div>
      <div class="btn-wrap" [pTooltip]="showDeleteTooltip(this.selectedBudgets.length)">
        <p-button
          styleClass="button button-delete button-delete--medium"
          (click)="deleteSelectedBudgets()"
          [disabled]="this.budgets.length === this.selectedBudgets.length || this.selectedBudgets.length === 0"
          [ariaLabel]="this.selectedBudgets.length === 0 ?
                      'Select rows with the checkbox from the table above to enable delete' :
                      this.selectedBudgets.length === 1 ?
                      'Delete ' + this.selectedBudgets.length + ' selected budget row' :
                      this.budgets.length === this.selectedBudgets.length ?
                      'You should leave at least one budget row' :
                      'Delete ' + this.selectedBudgets.length + ' selected budget rows'"
        >
          <span class="material-icons" aria-hidden="true">delete</span>
          Delete
        </p-button>
      </div>
    </div>
  </div>
</div>