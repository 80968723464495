<div class="editor-container" [ngClass]="styleClass ? styleClass : ''">
  <div #toolbar class="toolbar">
    <span class="ql-formats" *ngIf="formats.includes('header')">
      <select class="ql-header" title="Header">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option selected value="3">Normal</option>
      </select>
    </span>
    <span class="ql-formats" *ngIf="formats.includes('text')">
      <button type="button" class="ql-bold" title="Bold"></button>
      <button type="button" class="ql-italic" title="Italic"></button>
      <button type="button" class="ql-underline" title="Underline"></button>
    </span>
    <span class="ql-formats" title="Clear" *ngIf="formats.includes('clear')">
      <button class="ql-clean"></button>
    </span>
    <span class="ql-formats" *ngIf="formats.includes('list')">
      <button class="ql-list" value="ordered" title="Ordered List"></button>
      <button class="ql-list" value="bullet" title="Bullet List"></button>
    </span>
    <span class="ql-formats" title="Link" *ngIf="formats.includes('link')">
      <button class="ql-link"></button>
    </span>
  </div>
  <div #editor [style]="{ height }" class="editor"></div>
  <div *ngIf="limit" class="count">Characters: {{ charCount }}/{{ limit }}</div>
</div>
