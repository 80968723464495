<div class="login-form-wrap generic-screen" *ngIf="ssoAuthDisplayService.ssoGenericScreen.visible">
  <h1 class="title">{{ ssoAuthDisplayService.ssoGenericScreen.title}}</h1>
  <p class="subtitle">{{ssoAuthDisplayService.ssoGenericScreen.message}}</p>
  
  <div class="bottom-button-wrap" *ngFor="let link of ssoAuthDisplayService.ssoGenericScreen.links">
    <a
      class="button-forgotten-pass"
      (click)="navigateByLinkName(link)"
      (keyup.enter)="navigateByLinkName(link)"
      tabindex="0"
      [attr.aria-label]="'Navigate to ' + link"
    >
      {{link}}
    </a>
  </div>
</div>