import { Inject, Injectable, Injector } from "@angular/core";
import { Router } from '@angular/router';
import { StorageService } from "./storage.service";
import { InjectionToken } from "@angular/core";

export const PUBLIC_CONFIGURATION_TOKEN = new InjectionToken<PublicConfiguration>("public.rp2.config");

@Injectable({
  providedIn: 'root'
})
export class PublicConfigService {
  private config: PublicConfiguration;
  constructor(@Inject(PUBLIC_CONFIGURATION_TOKEN) private publicConfig: PublicConfiguration) {
    this.config = publicConfig;
  }

  get rpBaseUrl() {
    return this.config.rpBaseUrl;
  }

  get feBaseUrl() {
    const url = new URL(window.location.href);
    let hostname = url.hostname;
    if(hostname.startsWith("rp")) {
      hostname = hostname.slice(2);
    }
    let feUrl = `${url.protocol}//${hostname}`;
    if(url.port){
      feUrl += `:${url.port}`;
    }

    return feUrl;
  }

  getCarrotSSOConfig(): CarrotSSOConfiguration {
    return this.config.carrotSSOConfiguration;
  }

  getGTMId(): string {
    return this.config.googleTagManagerId;
  }

  getCookieInfoStorageUrl(): string {
    return this.config.cookieInfoStorageUrl;
  }

  getEnvironmentName(): string {
    return this.config.environmentName;
  }
}

export interface PublicConfiguration {
  carrotSSOConfiguration: CarrotSSOConfiguration;
  rpBaseUrl: string;
  googleTagManagerId: string;
  cookieInfoStorageUrl: string;
  environmentName: string;
}

export interface CarrotSSOConfiguration {
  apiKey: string,
  authDomain: string,
  projectId: string,
  storageBucket: string,
  messagingSenderId: string,
  appId: string,
  measurementId: string,
  useEmulators: boolean,
  ssoBaseUrl: string,
  ssoClientId: string,
}