import { Injector } from '@angular/core';
import { FormioCustomComponentInfo, registerCustomFormioComponent } from '@formio/angular';
import { FileUploadComponent } from './fileUpload.component';
import baseEditForm from "formiojs/components/_classes/component/Component.form";
import fileUploadEditData from './editForm/fileUpload.edit.data';

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: 'fileUpload',
  selector: 'file-upload',
  title: 'FileUpload',
  group: 'basic',
  icon: 'fa fa-circle',
  editForm: fileUploadEditForm,
  schema: {
    customDefaultValue: "{}"
  }
  //extraValidators: ['custom']
};

export function fileUploadEditForm() {
  const form = baseEditForm([
    {
      key: "display",
      components: fileUploadEditData,
    }
  ]);

  var tabs = form?.components?.find(x=>x.type = 'tabs');
  if(tabs)
  {
    var dataTab = tabs.components?.find(x=>x.key == 'data');
    if (dataTab) {
      if (dataTab.components?.findIndex(x=>x.key == 'multiple')>=0) {
        dataTab.components.splice(dataTab.components.findIndex(x=>x.key == 'multiple'),1);
      }
    }
  }

  return form;
}

export function registerFileUploadComponent(injector: Injector) {
  try {
    // debugger;
    registerCustomFormioComponent(COMPONENT_OPTIONS, FileUploadComponent, injector);
  }
  catch (err) {
    // custom component already exists
  }
}