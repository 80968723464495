import { HttpParams, HttpResponse } from '@angular/common/http';

export function toQueryParams(params: any): HttpParams {
  let httpParams = new HttpParams();
  Object.keys(params)
    .filter((key) => params[key] != null && params[key] != undefined && params[key] !== '')
    .forEach((key) => httpParams = httpParams.set(key, params[key]));
  return httpParams;
}

export function toBodyParams(params: any) {
  Object.keys(params)
    .forEach((key) => {
      if (params[key] === null || params[key] === undefined || params[key] === '') {
        delete params[key]
      }
    })
  return params
}

export function getFileNameFromResponse(response: HttpResponse<Blob>): string {
  let fileName = response.headers.get('content-disposition').split('filename="')[1].split('";')[0].trim();
  return fileName;
}
