import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Console } from 'console';

//https://stackoverflow.com/questions/38271273/experimental-decorators-warning-in-typescript-compilation

@Directive({
  selector: '[passwordCheck]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordCheckDirective, multi: true }]
})

export class PasswordCheckDirective implements Validator {
  @Input('validationList') validationList: any;
  @Output('validationCountUpdated') validationCountUpdated = new EventEmitter<any>();


  validate(control: AbstractControl): ValidationErrors | null {

    this.validationList.map((x) => (x.count = 0));
    if (control.value !== null) {
      //do regex checks
      for (let i = 0; i < control.value.length; i++) {
        this.validationList.map((pwRule) => {
          if (pwRule.id !== "MinChars") {
            if (control.value[i].match(pwRule.regX)) {
              pwRule.count++
            }
          }
        });
      }
      const minChars = this.validationList.find(chk => chk.id == 'MinChars')
      if (minChars)
        minChars.count = control.value.length

      this.validationCountUpdated.emit(this.validationList);
    }

    return control.value ? characterValidator(this.validationList)(control)
      : null;

  }
}

export function characterValidator(validationList: any) {
  return (control: AbstractControl): ValidationErrors | null => {
    let errorObj: any = {};
    validationList.map((pwRule) => {
      if (!validateFilterExpectedCount(pwRule)) {
        errorObj['passwordError'] = true;
      }
    });
    return errorObj
  };
}

function validateFilterExpectedCount(validation: any) {
  return validation.count >= validation.expCount;
}

