export const Tokens = {
  ACCESS: "accessToken",
  ID: "idToken"
};

export const emailRegex = /^[\w-+\.]+@([\w-]+\.)+[\w-]+$/;

export function getPwValidList() {
  let validList: {
    id: string,
    name: string,
    count: number,
    expCount: number,
    regX: RegExp
  }[] = [
    { id: "MinChars", name: "At least 8 characters", count: 0, expCount: 8, regX: null },
    { id: "LowerCase", name: "A lowercase letter", count: 0, expCount: 1, regX: /^[a-z]*$/ },
    { id: "UpperCase", name: "An uppercase letter", count: 0, expCount: 1, regX: /^[A-Z]*$/},
    { id: "Numbers", name: "A number", count: 0, expCount: 1, regX: /^[0-9]*$/},
    { id: "SpecialCharacters", name: "A symbol", count: 0, expCount: 1, regX: /^\W$/},
  ]

  return validList;
}

export interface SsoApiResponse<T>{
  success: boolean,
  errors: [],
  data: T
}

export interface SsoRegisterUserRequest{
  email: string,
  password: string,
  firstname: string,
  lastname: string
}

export interface SsoRegisterUserResponse {
  message: string,
  uid: string
}

export interface RpRoleForList {
  id: string,
  name: string,
  ssoName: string,
}

export const RpRolesList = {
  masterAdmin: {
    id: '51BF4776-295F-4BDC-926D-951B2174E799',
    name: 'Master Admin',
    ssoName: 'MasterAdmin',
  },
  competitionAdmin: {
    id: '9BA3A3CE-E9C7-494D-A0D9-16208DA94BE1',
    name: 'Competition Admin',
    ssoName: 'CompetitionAdmin',
  },
  client: {
    id: '65089D5C-8497-49DF-9571-C516BA53708F',
    name: 'Client',
    ssoName: 'Client',
  },
  participant: {
    id: '6BA20FA9-300A-4EFA-91B9-C53F7BB39EB6',
    name: 'Participant',
    ssoName: 'Participant',
  },
  judge: {
    id: 'D17B7F7D-F5DB-42D1-89AC-2E74AA6DC81A',
    name: 'Judge',
    ssoName: 'Judge',
  },
  adminReviewer: {
    id: '860261A1-E0D0-415A-AFCD-8F7CE005AA1F',
    name: 'Admin Reviewer',
    ssoName: 'AdminReviewer',
  },
  guest: {
    id: '419A25B8-01C4-47D3-A097-EE76406CFAC2',
    name: 'Guest',
    ssoName: 'Guest',
  },
}
