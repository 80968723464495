import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ExportDataComponent } from './export-data.component';

@NgModule({
  imports: [CommonModule, DropdownModule, FormsModule],
  declarations: [ExportDataComponent],
  exports: [ExportDataComponent],
})
export class ExportDataModule { }
