import { DateTime } from "luxon";

export const cleanUTF8String = (input: string) => {
  let output = '';
  for (let i = 0; i < input.length; i++) {
    if (input.charCodeAt(i) <= 127) {
      output += input.charAt(i);
    }
  }
  return output;
};

export const jsDateToISODate = (date: Date) => {
  if (!date) return null;
  // fetching timezone from localStorage and removing double quotes, because luxon does not accept double quoted time zone ;/
  const timeZone = localStorage.getItem("competitionTimeZoneDatabaseName").replace(/"/g, '');
  const luxonDateConvertedToComp = DateTime.fromJSDate(date).setZone(timeZone, {keepLocalTime: true});
  // setting the time zone to UTC - this converts the chosen time to UTC and is ready to be saved
  const finalDate = luxonDateConvertedToComp.setZone('utc');
  // returning date by changing date time to string and stripping the last character - it is Z which denotes UTC
  return finalDate.toString().substring(0, finalDate.toString().length - 1);
}

export const replaceFebaseurl = (feBaseUrl: string) => {
  document.querySelectorAll('a').forEach(aTag => {
    if (aTag.href.includes('https://febaseurl')) {
      aTag.href = aTag.href.replace('https://febaseurl', feBaseUrl);
    }
  });
}