import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { BasicModalComponent } from '../components/modal/basic-modal/basic-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  constructor(private dialogService: DialogService) { }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: string = 'auto',
    additionalMessage?: string): Promise<boolean> {
    const modalRef = this.dialogService.open(BasicModalComponent, {
      dismissableMask: true,
      styleClass: 'dialog hidden-close-dialog',
      width: dialogSize,
      data: {
        title,
        message,
        btnOkText,
        btnCancelText,
        additionalMessage
      }
    });
    return new Promise((resolve, reject) => {
      modalRef.onClose.subscribe(res => resolve(res));
    })
  }
}
