import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DropdownModule } from "primeng/dropdown";
import { SubmissionsDropdownComponent } from "./submissions-dropdown.component";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [CommonModule, DropdownModule, FormsModule],
  declarations: [SubmissionsDropdownComponent],
  exports: [SubmissionsDropdownComponent],
  providers: []
})
export class SubmissionsDropdownModule {}
