import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { toBodyParams, toQueryParams } from "src/app/shared/utils/http.util";
import { PublicConfigService } from "../../shared/services/public.config.service";

@Injectable({ providedIn: "root" })
export class AdminReviewService {
  public publicConfig: any;
  constructor(
    private httpClient: HttpClient,
    publicConfigService: PublicConfigService
  ) {
    this.publicConfig = publicConfigService;
  }

  GetAdminReviewResults(adminReviewId, searchParams) {
    return this.httpClient.post(`${this.publicConfig.rpBaseUrl}/api/admin-review/${adminReviewId}`, toBodyParams(searchParams));
  }

  GetAdminReviewResultsExport(adminReviewId, searchParams) {
    return this.httpClient.post(`${this.publicConfig.rpBaseUrl}/api/admin-review/${adminReviewId}/export`, toBodyParams(searchParams), { responseType: 'blob', observe: 'response' });
  }

  GetAdminReviewResultsActionsPerReview(adminReviewId) {
    const options = {

    };
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/admin-review/${adminReviewId}/actions`, options);
  }

  PerformActionOnAdminReview(adminReviewId, object) {
    // if action is 8 (mark final), hide the loader
    let url = `${this.publicConfig.rpBaseUrl}/api/admin-review/${adminReviewId}/perform-action`;
    if (object.action === 8) {
      url += '?loader=false';
    }
    return this.httpClient.put(
      url,
      object,
      {

      }
    );
  }

  // SetAdminReviewStatus(object, adminReviewId) {
  //   return this.httpClient.put(
  //     `${this.publicConfig.rpBaseUrl}/api/admin-review/${adminReviewId}`,
  //     object,
  //     {
  //       headers: {
  //         Authorization: "Bearer " + this.storage.retrieve(Tokens.ACCESS)
  //       }
  //     }
  //   );
  // }

  // review header
  GetAdminReviewReviewHeader(adminReviewReviewId, viewMode) {
    const options = {
      params: {
        viewMode: viewMode
      }
    };
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/admin-review/${adminReviewReviewId}/component-header`, options);
  }

  // view review
  GetAdminReviewReviewDetails(adminReviewReviewId) {
    const options = {};
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/admin-review/details/${adminReviewReviewId}`, options);
  }
}
