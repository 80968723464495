import { NgModule } from '@angular/core';
import { HasRoleDirective } from '../directives/has-role.directive';
import { StageDeadlineDirective } from './stage-deadline.directive';

@NgModule({
  declarations: [
    HasRoleDirective,
    StageDeadlineDirective
  ],
  exports: [
    HasRoleDirective,
    StageDeadlineDirective
  ]
})
export class DirectivesModule {}
