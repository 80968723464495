<div class="login-page" [ngClass]="{'initial-loading': showLogin}">
  <div class="login-loader">
    <div class="loader-inner">
      <div class="loader-image">
        <img src="assets/images/carrot-logo-black-cropped.png" alt="">
      </div>
      <div class="loader-progress"></div>
    </div>
  </div>
  <div class="login-widget" [ngClass]="{'loading': loaderShown}">
    <div class="login-widget-content">
      <div class="login-widget-branding">
        <img [src]="competitionLogo" alt="">
        <div class="loader">
          <div class="loader-inner"></div>
        </div>
      </div>

      <app-auth-generic-screen></app-auth-generic-screen>
    </div>
  </div>
</div>
