import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface componentHeaderRef {
  available?: boolean;
  elementRef?: ElementRef;
}

@Injectable({
  providedIn: 'root'
})
export class StickyHeaderService {

  private componentHeaderObs = new BehaviorSubject<componentHeaderRef>({available: false, elementRef: undefined});

  constructor(
  ) {
  }

  public getComponentHeaderAsObs() {
    return this.componentHeaderObs.asObservable();
  }

  public updateComponentHeaderRef(elementRef: ElementRef) {
    this.componentHeaderObs.next({available: true, elementRef});
  }

  public removeComponentHeaderRef() {
    this.componentHeaderObs.next({available: false, elementRef: undefined});
  }
}
