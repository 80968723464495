import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  OnInit,
} from "@angular/core";
import { FormioCustomComponent, FormioEvent } from "@formio/angular";
import { BudgetData } from "../models/budget-data";
import { MessageService } from "primeng/api";
import { CellEditor, TableEditCancelEvent, TableEditInitEvent } from "primeng/table";

@Component({
  selector: "budget",
  templateUrl: "./budget.component.html",
  styleUrls: ["./budget.component.scss"],
})
export class BudgetComponent implements OnInit, FormioCustomComponent<string> {
  budgets: BudgetData[] = [];

  totalIsValid = true;

  private initialized = false;

  private _value: string;
  @Input()
  public set value(v: string) {
    this._value = v;
    this.init();
  }

  public get value(): string {
    return this._value;
  }

  public get isEmpty(): boolean {
    // No value available or the value contains no budget/s
    return !this.value || this.budgets.length === 0 ||
      // The value contains one budget, which is the default/empty one
      (this.budgets.length === 1 && !this.budgets[0].budgetItem && !this.budgets[0].budgetValue);
  }

  public get isValid(): boolean {
    let result = false;
    if (!this.required && (this.isEmpty || this.checkValueIsValid())) {
      result = true;
    }
    else if (this.required && !this.isEmpty && this.checkValueIsValid()) {
      result = true;
    }
    return result;
  }

  @Output()
  valueChange = new EventEmitter<string>();

  @Output()
  formioEvent = new EventEmitter<FormioEvent>();

  @Input()
  disabled: boolean;

  @Input()
  required: boolean;

  @Input()
  totalRequirements: string;

  @Input()
  totalValue: string;

  @Input()
  totalValidationRule: string;

  @Input()
  custom: string;

  @ViewChildren("budgetItems") budgetItems: QueryList<CellEditor>;
  @ViewChildren("budgetValues") budgetValues: QueryList<CellEditor>;

  customMessage: string =
    "Please revise your budget table. Some of the requirements are not met.";

  selectedBudgets: BudgetData[];
  total: number = 0;

  error: string = "";

  // used to revert to initial value on edit cancel
  // via escape button
  budgetCellCopy: any;

  constructor(private messageService: MessageService) {
    this.selectedBudgets = [];
  }

  ngOnInit(): void {
    if (!this.budgets.length) {
      this.budgets.push({
        id: 0,
        budgetItem: "",
        budgetValue: "",
        budgetItemValid: true,
        budgetValueValid: true,
      });
    };
  }

  private setValue(val: string = null) {
    const valid = this.checkValueIsValid();
    if (valid) {
      this.clearError();
    }

    const budgetData = {
      value: this.budgets,
      valid: valid,
    };
    this.value = JSON.stringify(budgetData);
    this.valueChange.emit(this.value);
    this.formioEvent.emit({ eventName: "change", data: { isChanged: true } });
  }

  deleteSelectedBudgets() {
    if (this.budgets.length == this.selectedBudgets.length) {
      return;
    }
    this.budgets = this.budgets.filter(
      (val) => !this.selectedBudgets.includes(val)
    );
    this.selectedBudgets = [];
    this.getTotal();
    this.handleChange();
  }

  addNew() {
    if (this.budgets.length == 50) {
      return;
    }
    if (this.budgets.length) {
      this.budgets.push({
        id: this.budgets[this.budgets.length - 1].id + 1,
        budgetItem: "",
        budgetValue: "",
        budgetItemValid: true,
        budgetValueValid: true,
      });
    } else {
      this.budgets.push({
        id: 0,
        budgetItem: "",
        budgetValue: "",
        budgetItemValid: true,
        budgetValueValid: true,
      });
    }
  }

  checkValueIsValid() {
    this.validateRows();
    if (!this.validateTotal() || !this.checkRowsAreValid()) {
      return false;
    }

    return true;
  }

  checkRowsAreDefault() {
    for (let i = 0; i < this.budgets.length; i++) {
      if (
        this.budgets[i].budgetItem !== "" ||
        this.budgets[i].budgetValue !== ""
      ) {
        return false;
      }
    }
    return true;
  }

  validateTotal() {
    if (this.totalValidationRule === "=") {
      let totalValueNum = Number(this.totalValue);
      if (this.total !== totalValueNum) {
        this.totalIsValid = false;
        return false;
      }
    }

    if (this.totalValidationRule === ">=") {
      let totalValueNum = Number(this.totalValue);
      if (this.total < totalValueNum) {
        this.totalIsValid = false;
        return false;
      }
    }

    if (this.totalValidationRule === "<=") {
      let totalValueNum = Number(this.totalValue);
      if (this.total > totalValueNum) {
        this.totalIsValid = false;
        return false;
      }
    }

    if (this.totalValidationRule === "<=, <=") {
      let numbers = this.totalValue.split(",");
      let min = Number(numbers[0]);
      let max = Number(numbers[1]);

      if (min > this.total || this.total > max) {
        this.totalIsValid = false;
        return false;
      }
    }

    this.totalIsValid = true;
    return true;
  }

  checkRowsAreValid() {
    for (let i = 0; i < this.budgets.length; i++) {
      if (
        !this.budgets[i].budgetItemValid ||
        !this.budgets[i].budgetValueValid
      ) {
        return false;
      }
    }
    return true;
  }

  validateRows() {
    let rowsAreValid = true;
    for (let i = 0; i < this.budgets.length; i++) {
      this.validateBudgetItem(i);
      this.validateBudgetCell(i);
    }
    return rowsAreValid;
  }

  onEditInit(event: TableEditInitEvent) {
    // store previous value (pre-edit) upon edit start (so we can restore value upon clicking escape)
    if (event.field === 'budgetItem') {
      this.budgetCellCopy = event.data.budgetItem;
    } else if (event.field === 'budgetValue') {
      this.budgetCellCopy = event.data.budgetValue;
    };
  }

  onEditCancel(event: TableEditCancelEvent) {
    // restore previous value (pre-edit) upon clicking escape (cancelling edit)
    if (event.field === 'budgetItem') {
      this.budgets[event.data.id].budgetItem = this.budgetCellCopy;
    } else if (event.field === 'budgetValue') {
      this.budgets[event.data.id].budgetValue = this.budgetCellCopy;
    };
  }

  removeLastWords(budget) {
    let res = budget.budgetItem.split(/\s+/);
    if (res.length > 25) {
      res = res.splice(0, 25);
    }
    budget.budgetItem = res.join(" ");
  }

  handleEnter(event, budget) {
    if (event.key === "Enter") {
      this.handleChange();
      // if you are editing budget text
      // enter key will move you to the next budget value (it's on the same row)
      var res = this.budgetValues.toArray();

      setTimeout(() => {
        var nextId = budget.id;
        if (nextId > -1) res[nextId].editableColumn.openCell();
      }, 300);
    }
  }

  handleEnterVal(event, budget) {
    if (event.key === "Enter") {
      this.handleChange();
      // if you are editing budget value
      // enter key will move you to the next budget text (if such exists) - it's on the next row
      var res = this.budgetItems.toArray();

      setTimeout(() => {
        var nextId = this.findNextBudgetId(budget.id);
        if (nextId > -1) res[nextId].editableColumn.openCell();
      }, 300);
    }
  }

  private findNextBudgetId(budgetId) {
    for (let i = 0; i < this.budgets.length; i++) {
      if (this.budgets[i].id === budgetId && i != this.budgets.length - 1) {
        return i + 1;
      }
    }
    return -1;
  }

  removeLastDigits(budget) {
    let sBudget = budget.budgetValue?.toString() || '';
    sBudget = sBudget.substr(0, 14);
    budget.budgetValue = sBudget;
  }

  private validateBudgetCell(i: number) {
    if (
      this.budgets[i].budgetValue === "" ||
      this.budgets[i].budgetValue === "0"
    ) {
      this.budgets[i].budgetValueValid = false;
    } else {
      this.budgets[i].budgetValueValid = true;
    }
  }

  private validateBudgetItem(i: number) {
    if (this.budgets[i].budgetItem === "") {
      this.budgets[i].budgetItemValid = false;
    } else {
      this.budgets[i].budgetItemValid = true;
    }
  }

  getTotal() {
    var sum = 0;
    for (let i = 0; i < this.budgets.length; i++) {
      if (this.budgets[i].budgetValue) {
        sum += parseInt(this.budgets[i].budgetValue);
      }
    }

    this.total = sum;
    return this.total.toLocaleString("en-Us", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  handleChange() {
    this.setValue();
  }

  private clearError() {
    setTimeout(() => {
      var errors = document.getElementsByClassName("error");
      if (errors.length > 1) {
        for (let i = 0; i < errors.length; i++) {
          if (errors[i].innerHTML === this.customMessage) {
            errors[i].setAttribute("style", "display:none;");
            break;
          }
        }
      }
    }, 250);
  }

  private init() {
    if (this.value && !this.initialized) {
      this.budgets = JSON.parse(this.value).value;
      this.budgets.forEach(element => {
        delete element['selected'];
      });
      this.getTotal();
      this.checkValueIsValid();
      this.initialized = true;
    }
  }

  getBudgetItemValue(item) {
    if (!item) return "Budget item short description";
    return item;
  }

  getBudgetValue(item) {
    if (!item) return "$ 0";
    return `$ ${Number(item).toLocaleString("en-Us", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}`;
  }

  onRowSelect(typeOfSelection, item) {
    if (typeOfSelection == true) {
      this.selectedBudgets.push(item);
    } else if (typeOfSelection == false) {
      let removeIndex = this.selectedBudgets.map(el => el.id).indexOf(item.id);
      if (removeIndex >= 0) {
        this.selectedBudgets.splice(removeIndex, 1);
      };
    }
  }

  showDeleteTooltip(selectedBudgetsLength) {
    let tooltip: string = ``;
    if (selectedBudgetsLength === 0) {
      tooltip = 'You must select at least one row to delete'
    } else {
      if (selectedBudgetsLength === this.budgets.length) {
        tooltip = 'You must leave at least one row present'
      };
    }
    return tooltip;
  }

  stopPropagation(e: any) {
    // this is called to prevent primeng built-in behaviour of switching active (focused) input
    // when in edit mode the left/right arrows are pressed
    // this effectively restores standard behaviour of moving the cursor inside the input field, changing the selected character
    e.stopPropagation();
  }
}
