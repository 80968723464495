import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Dropdown } from "primeng/dropdown";

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html"
})
export class DropdownComponent implements OnInit {
  // To be used as type of dropdown items = links or other
  @Input() type: string;
  @Input() items;
  @Input() placeholderText: string;
  @Input() optionLabel: string;
  @Input() disabled: boolean;
  @Input() icon?: string;
  @Input() emptyFilterMessage = "Nothing to display";
  @Input() emptyMessage = "Nothing to display";

  @Output() handleSelectedItem = new EventEmitter<any>();
  @Output() dropdownOpenEmitter = new EventEmitter<boolean>();

  public selectedItem: string;
  public ddIcon: string = "pi pi-angle-down";
  dropDownComponent: Dropdown;

  constructor(private router: Router) {}
  ngOnInit() {
  }

  handleDropdownSelection(event: any, dropDownComponent) {
    if (this.type == "link") {
      let link = event.value.href;

      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/${link}`])
      );
      window.open(url, "_blank");
      dropDownComponent.selectedOption = null;
    } else {
      this.handleSelectedItem.emit(this.selectedItem);
      if(!!this.icon){
        dropDownComponent.selectedOption = null;
      }
    }
  }

  dropdownOpen(){
    this.dropdownOpenEmitter.emit(true);
  }

  dropdownClose(){
    this.dropdownOpenEmitter.emit(false);
  }
}
