import { formatLinksPipe } from "./format-links.pipe";
import { utcToCompTimePipe } from "./utc-to-comp-time.pipe";
import { NgModule } from "@angular/core";

@NgModule({
  imports: [],
  declarations: [ 
    utcToCompTimePipe,
    formatLinksPipe,
  ],
  exports: [
    utcToCompTimePipe,
    formatLinksPipe,
  ]
})
export class PipesModule{}
