export const CompetitionInformation = {
  competitionName: 'competitionName',
  brandingsLogo: "competitionLogo",
  brandingsLogoAltText: 'competitionLogoAltText',
  brandingsFavicon: 'competitionFavicon',
  brandingsLoginPageBackgroundImage: 'competitionLoginPageBackgroundImage',
  loginWidgetLogoUrl: 'competitionWidgetLogoUrl',
  timeZoneName: 'competitionTimeZoneName',
  timeZoneAbbr: 'competitionTimeZoneAbbr',
  timeZoneDatabaseName: 'competitionTimeZoneDatabaseName',
  supportEmailAddress: 'competitionSupportEmailAddress' ,
}

export interface RpApiResponse<T>{
  success: boolean,
  errors: [],
  data: T
}