<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="status" *ngIf="status">Status: {{ status }}</p>
  <div class="modal-message" [innerHTML]="message"></div>
  <p *ngIf="additionalMessage">{{ additionalMessage }}</p>
  <app-input
    *ngIf="captchaCheck"
    name="Type 'carrot'"
    [model]="inputModel"
    (focusOutEmitter)="focusOutHandler($event)"
    (keyUpEmitter)="keyUpHandler($event)"
    [inputError]="inputError"
    [noError]="noError"
    identifier="carrotInput"
  ></app-input>
  <div class="p-field-checkbox" *ngIf="withCheckbox">
    <p-checkbox [(ngModel)]="checkboxValue" binary="true" inputId="basic-modal-checkbox"></p-checkbox>
    <label class="p-checkbox-label" for="basic-modal-checkbox">{{ checkboxText }}</label>
  </div>
</div>


<div class="modal-footer">
  <button
    *ngIf="btnCancelText"
    type="button"
    class="button button-secondary"
    (click)="decline()"
  >
    {{ btnCancelText }}
  </button>
  <button
    [ngClass]="{ 'ml-24': btnCancelText !== undefined }"
    type="button"
    class="button button-primary"
    (click)="accept()"
    [disabled]="disableButton"
    [autofocus]="true"
  >
    {{ btnOkText }}
  </button>
</div>
