export default [
  {
    weight: 10,
    type: "textfield",
    input: true,
    key: "customOptions.fieldNames",
    label: "Drop-down titles",
    tooltip:
      "Set a title for each drop-down. Your first entry will impact the highest-level. Use comma separation.",
    validate: {
      required: true,
    },
    defaultValue: "Country,State/Province,Locality/District/County"
  },
];
