export default [
  {
    key: "validate.custom",
    calculateValue(context) {
      return "valid = (!input && !component.validate.required) || (input && JSON.parse(input).valid) || 'Invalid Video'";
    },
  },
  {
    weight: 20,
    type: "textfield",
    input: true,
    key: "customOptions.maxVideoLength",
    label: "Max allowed video length (in seconds)",
    tooltip: "Max allowed video length (in seconds)",
  },
];
