import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TabTitleService } from "../shared/services/tab-title.service";
import { CommonService } from "../shared/services/common.service";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"],
})
export class ErrorComponent implements OnInit {
  errorStatus: number;
  supportEmail: string;

  constructor(
    private router: Router,
    private tabTitleService: TabTitleService,
    private compInfo: CommonService
  ) {}

  // TODO - update this to avoid calling function in template
  // this string can be constructed in the template directly
  mailto(emailAddress: string, emailSubject: any) {
    return "mailto:" + emailAddress + "?subject=" + emailSubject;
  }

  ngOnInit() {
    this.tabTitleService.setTabTitle("Oops!");
    if (this.router.url.split("/").pop() == "403") {
      this.errorStatus = 403;
      this.compInfo.competitionInfo
        .pipe(filter((v) => v))
        .subscribe((value) => {
          this.supportEmail = value.data?.supportEmailAddress;
        });
    }
  }
}
