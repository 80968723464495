import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "multi-select",
  templateUrl: "./multi-select.component.html"
})
export class MultiSelectComponent implements OnInit {
  @Input() id: string;
  @Input() optionLabel: string;
  @Input() placeholder: string;
  @Input() labelName: string; 
  @Input() options: string;
  @Input() maxSelectedLabels: string;
  @Input() data: any[];
  @Input() displayLabel: boolean;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
  }

  emitChange(event): void {
    this.change.emit(this.data);
  }
}
