import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import { forkJoin } from "rxjs";
import { take } from "rxjs/operators";
import { StagesService } from "src/app/shared/services/stages.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { TabTitleService } from "src/app/shared/services/tab-title.service";
import { AdminReviewService } from "../../admin-review.service";
import { ConfirmationDialogService } from "src/app/shared/services/confirmation-dialog.service";

@Component({
  selector: "app-admin-review-view-review",
  templateUrl: "./admin-review-view-review.component.html",
  styleUrls: ["./admin-review-view-review.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AdminReviewViewReviewComponent implements OnInit {

  @ViewChild('refMarkFinalModal') refMarkFinalModal: ElementRef;

  // header
  applicantIdentifier: string = '';
  userId: string;
  userForms: any[] = [];
  userActionsList: any[] = [];
  userActionsHeader: any[] = [];
  userActionsFooter: any[] = [];

  stageId: string;
  review: any;
  preliminaryStatus: string;
  status: string;

  markFinalShown: boolean = false;
  turndownReasons: any[] = [];
  selectedTurndownReasons: any[] = [];

  // content
  reviewId: string;
  userAppNumber: string;
  generalQuestionsArr: any[];
  additionalReviewArr: any[];

  // page
  stageUrl: string | any[];

  actionInProgress: boolean = false;

  constructor(
    private adminReviewService: AdminReviewService,
    private stagesService: StagesService,
    private storageService: StorageService,
    public activatedRouter: ActivatedRoute,
    private router: Router,
    private tabTitleService: TabTitleService,
    private confirmationDialogService: ConfirmationDialogService,
  ) {}

  ngOnInit() {
    // TODO get stage name form api

    this.activatedRouter.params.subscribe(params => {
      this.tabTitleService.setTabTitle('View - Admin Review');
      this.stageId = params.id;
      this.stageUrl = `/admin-review/${this.stageId}`;
      this.reviewId = params.reviewId;
  
      forkJoin([this.getReviewHeader(this.reviewId), this.getReviewDetails(this.reviewId), this.getTurndownReasons()]).subscribe(response => {
        this.determineStatus();
        this.determineActions();
      });
    })
  }

  async getReviewHeader(reviewId) {
    try {
      const response: any = await this.adminReviewService.GetAdminReviewReviewHeader(reviewId, 'read').toPromise();
      this.applicantIdentifier = response.data.applicantIdentifier;
      this.userId = response.data.userId;
      if(response.data.forms) {
        response.data.forms.forEach(element => {
          const form = {
            name: element.formName,
            href: `form/${element.stageId}/display/${this.userId}`
          };
          this.userForms.push(form);
        });
      }

      if (response.data.actions) {
        response.data.actions.forEach(element => {
          if (element.name === 'MarkFinal') element.name = 'Mark Final';
          const action = {
            name: element.name,
            value: element.value
          }
          this.userActionsList.push(action);
        });
      }

      this.preliminaryStatus = response.data.readModeStatusBeforeSubmit;
    } catch (error) {
      console.log(error);
    }
  }

  async getReviewDetails(reviewId) {
    try {
      const response: any = await this.adminReviewService.GetAdminReviewReviewDetails(reviewId).toPromise();
      this.review = response;
      this.userAppNumber = response.userNumber;
      this.status = response.status;
      if (response.data) {
        this.orderQuestions(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getTurndownReasons() {
    try {
      const response: any = await this.stagesService.GetStageQuestionTypes().toPromise();
      const turndownReasonTypeId = response.data.filter(element => element.name === 'Turndown Reason')[0].id;
      this.stagesService.GetStageQuestion(this.stageId, turndownReasonTypeId).subscribe((response: any) => {
        response.data.forEach(element => {
          const turndownReason = {
            id: element.id,
            name: element.title
          };
          this.turndownReasons.push(turndownReason);
        });
      });
    } catch (error) {
      console.log(error);
    }
  }

  determineStatus() {
    if (this.status === 'InProgressCA' || this.status === 'InProgressAR') {
      this.status = this.preliminaryStatus;
    }
  }

  determineActions() {
    // excluding mark final from header actions
    this.userActionsList.forEach(element => {
      if (element.name !== 'Mark Final') {
        this.userActionsHeader.push(element);
      };

      if (element.name === 'Edit') {
        if (this.status === 'Flagged' || this.status === 'Discarded' || this.status === 'InProgressCA' || this.status === 'InProgressAR') {
          // if action is edit and status is in progress/flagged/discarded - add the item;
          this.userActionsFooter.push(element);
        }
      } else {
        // if action is not edit - add it
        this.userActionsFooter.push(element);
      }
    })
  }

  performAction(actionValue, actionName, actionConfirmed?: boolean) {
    let action: any = {
      'action': actionValue
    };
    if (actionName === 'Mark Final') {
      if (actionConfirmed) {
        if (this.status === 'Valid') {
          if (this.review.isFinalComment) {
            action.finalComment = this.review.isFinalComment;
          }
        } else if (this.status === 'Invalid') {
          if (this.selectedTurndownReasons) {
            action.turndownReasons = [];
            this.selectedTurndownReasons.forEach(element => {
              action.turndownReasons.push(element.id);
            })
          };
          if (this.review.turndownComment) {
            action.turndownComment = this.review.turndownComment;
          };
          if (this.review.isFinalComment) {
            action.finalComment = this.review.isFinalComment;
          }
        }
        this.closeMarkFinalModal();
      } else {
        this.openMarkFinalModal();
      }
    }
    if (actionName !== 'Mark Final' || actionConfirmed) {
      this.actionInProgress = true;
      this.adminReviewService.PerformActionOnAdminReview(this.reviewId, action).pipe(take(1))
      .subscribe(
        (response: any) => {
          this.actionInProgress = false;
          const message = response.data?.showOvertakeMessage;
          const action = response.data?.overtakeAction;
          if (message && action) {
            this.openOvertakeModal(action, 'Edit');
            return
          }

          if (response.success === true) {
            switch (actionName) {
              case 'Edit':
                // edit
                this.router.navigate([`${this.stageUrl}/review/${this.reviewId}/edit`]);
                break;
              case 'Submit':
                // submit
                const storedState = this.storageService.retrieve('adminReviewStoredState');
                if (storedState && storedState.reviewId === this.reviewId) {
                  this.storageService.removeCompletely('adminReviewStoredState');
                }
                this.router.navigate([`${this.stageUrl}`]);
                break;
              case 'Mark Final':
                // mark final
                this.markFinalShown = false;
                this.router.navigate([`${this.stageUrl}`]);
                break;
              case 'Exit':
                // exit
                this.router.navigate([`${this.stageUrl}`]);
                break;
            }
          }
        },
        (err) => {
          this.actionInProgress = false;
          console.log(err);
        }
      );
    }
  }

  openOvertakeModal(actionValue, actionName) {
    this.confirmationDialogService.confirm('Confirmation', 'Someone is working on this review. Are you sure you want to overtake this review?', 'Overtake', 'Cancel', '400px')
      .then((confirmed) => {
        // if confirmed === true - user confirmed, if false - user clicked cancel button
        if (confirmed) {
          // this.restoreDefaults();
          this.performAction(actionValue, actionName, true);
        } else {
        };
      })
      .catch(() => {
        // user dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog))
      });
  }

  openMarkFinalModal() {
    if (this.review.turndownReasons) {
      this.review.turndownReasons.forEach(element => {
        this.selectedTurndownReasons.push({
          id: this.turndownReasons.filter(element2 => element2.name === element)[0].id,
          name: element
        });
      });
    } else {
      this.selectedTurndownReasons = [];
    };
    this.selectedTurndownReasons = [...this.selectedTurndownReasons];
    this.markFinalShown = false;
    this.refMarkFinalModal.nativeElement.classList.add('shown');
    document.querySelector('html').classList.add('modal-opened');
  }

  closeMarkFinalModal() {
    this.markFinalShown = false;
    this.selectedTurndownReasons = [];
    this.refMarkFinalModal.nativeElement.classList.remove('shown');
    document.querySelector('html').classList.remove('modal-opened');
  }

  orderQuestions(allQuestions: any[]) {
    // mapping general questions
    const generalQuestions: any[] = allQuestions.filter(element => {
      if (element.questionType === 'General Question') {
        return element;
      };
    });
    if (generalQuestions.length) {
      function compare(a: { sortOrder: number; }, b: { sortOrder: number; }) {
        if ( a.sortOrder < b.sortOrder ){
          return -1;
        }
        if ( a.sortOrder > b.sortOrder ){
          return 1;
        }
        return 0;
      }
      generalQuestions.sort(compare);
      generalQuestions.forEach(element => {
        element.expanded = false;

        let descriptionLength: number;
        if (element.description === null) {
          descriptionLength = 0;
        } else {
          descriptionLength = element.description.length;
        }
        if (descriptionLength > 70) {
          element.showDescriptionOverlay = true;
        } else {
          element.showDescriptionOverlay = false;
        };

        if (element.comments) {
          const commentLength = element.comments.length;
          if (commentLength > 65) {
            element.showCommentOverlay = true;
          } else {
            element.showCommentOverlay = false;
          };
        };
      });
      this.generalQuestionsArr = generalQuestions;
    };

    // mapping additional review
    const additionalReviewQuestions: any[] = allQuestions.filter(element => {
      if (element.questionType === 'Additional Review' || element.questionType == 'Exemplar') {
        return element;
      };
    });
    if (additionalReviewQuestions.length) {
      function compare(a: { sortOrder: number; }, b: { sortOrder: number; }) {
        if ( a.sortOrder < b.sortOrder ){
          return -1;
        }
        if ( a.sortOrder > b.sortOrder ){
          return 1;
        }
        return 0;
      }
      additionalReviewQuestions.sort(compare);
      additionalReviewQuestions.forEach(element => {
        element.expanded = false;

        let descriptionLength: number;
        if (element.description === null) {
          descriptionLength = 0;
        } else {
          descriptionLength = element.description.length;
        }
        if (descriptionLength > 70) {
          element.showDescriptionOverlay = true;
        } else {
          element.showDescriptionOverlay = false;
        };

        if (element.comments) {
          const commentLength = element.comments.length;
          if (commentLength > 65) {
            element.showCommentOverlay = true;
          } else {
            element.showCommentOverlay = false;
          };
        };
      });
      this.additionalReviewArr = additionalReviewQuestions;
    };
  }

  toggleQuestionDetails(questionIndex: number, questionType: string) {
    if (questionType === 'General Questions') {
      this.generalQuestionsArr[questionIndex].expanded = !this.generalQuestionsArr[questionIndex].expanded;
      const clickedQuestion = document.querySelectorAll('.question-single.question-general')[questionIndex];
      const descHeight = clickedQuestion.querySelector('.question-description').clientHeight;
      const commentHeight = clickedQuestion.querySelector('.question-comment').clientHeight;
      let finalHeight: number;
      if (descHeight > 26 || commentHeight > 26) {
        if (descHeight > commentHeight) {
          finalHeight = descHeight + 30;
        } else {
          finalHeight = commentHeight + 30;
        }

        if (this.generalQuestionsArr[questionIndex].expanded === true) {
          clickedQuestion.setAttribute('style', `height: ${finalHeight}px;`);
        } else {
          clickedQuestion.setAttribute('style', '');
        }
      }
    } else if (questionType === 'Additional Review') {
      this.additionalReviewArr[questionIndex].expanded = !this.additionalReviewArr[questionIndex].expanded;
      const clickedQuestion = document.querySelectorAll('.question-single.question-additional')[questionIndex];
      const descHeight = clickedQuestion.querySelector('.question-description').clientHeight;
      const commentHeight = clickedQuestion.querySelector('.question-comment').clientHeight;
      let finalHeight: number;
      if (descHeight > 26 || commentHeight > 26) {
        if (descHeight > commentHeight) {
          finalHeight = descHeight + 30;
        } else {
          finalHeight = commentHeight + 30;
        }

        if (this.additionalReviewArr[questionIndex].expanded === true) {
          clickedQuestion.setAttribute('style', `height: ${finalHeight}px;`);
        } else {
          clickedQuestion.setAttribute('style', '');
        }
      }
    }
  }
}
