import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PublicConfigService } from "./public.config.service";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NavBarService {

  private _refreshNav$ = new Subject();

  public get refreshNav$() {
    return this._refreshNav$.asObservable();
  }

  constructor(
    private http: HttpClient,
    private publicConfigService: PublicConfigService,
  ) {
  }

  public refreshNav() {
    this._refreshNav$.next(true);
  }

  public primaryList() {
    return this.http.get(`${this.publicConfigService.rpBaseUrl}/api/navigations/primary`, {

    });
  }

  public secondaryList() {
    return this.http.get(`${this.publicConfigService.rpBaseUrl}/api/navigations/secondary`, {

    });
  }
}
