import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormSubmissionService } from '../services/form-submission.service';
import { FormSubmission, FormSubmissionPOC } from '../model/form-submission';
import { TabTitleService } from 'src/app/shared/services/tab-title.service';
import { ActivatedRoute } from '@angular/router';
import { FormDetails, FormStageTypes } from '../model/form-details';
import { FormHeaderDataService } from '../services/form-header-data.service';
import { DateTime } from 'luxon';
import { utcToCompTimePipe } from 'src/app/shared/pipes/utc-to-comp-time.pipe';
import { forkJoin } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FormioComponent } from '@formio/angular';
import { replaceFebaseurl } from 'src/app/shared/utils/general.utils';
import { PublicConfigService } from 'src/app/shared/services/public.config.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CompetitionInformation } from 'src/app/shared/model/common.model';
import { PrintService } from 'src/app/shared/services/print.service';
import { PrintPageTypes, printPageContent } from 'src/app/shared/model/print.model';
import { sanitizeConfigOptions } from '../model/form-sanitize-config';

@Component({
  selector: 'app-form-display-renderer',
  templateUrl: './form-display-renderer.component.html',
  styleUrls: ['./form-display-renderer.component.scss']
})
export class FormDisplayRendererComponent implements OnInit, OnDestroy {

  @ViewChild(FormioComponent, { static: false }) formio: FormioComponent;

  public formDetails: FormDetails;

  public competitionName: string = '';
  public competitionLogo: string = '';

  public showDeadLine: boolean = false;
  public enableEdit: boolean = false;
  public formHeaderData: any;
  public deadlineISO: string;
  public toggleSaveIcon: boolean = true;

  public windowWidth: number;
  private nowISO: string = DateTime.fromJSDate(new Date()).setZone("UTC").toISO();

  public submitted: boolean = false;
  public isReadonly: boolean = false;

  public stageId: string;
  public formDefinition: any;
  public formData: FormSubmissionPOC;
  
  public loading: boolean = true;

  public formSubmission: FormSubmission;

  private shouldPrint: boolean = false;

  private firstPagePrintContent: printPageContent;

  // used to determine if this is called from PDF generation
  private pdfGeneration: boolean = false;

  // NOTE - if something does not show on the form, make sure to check if it's listed here - it should be if it's using a custom tag, or a custom attribute
  renderOptions = { 
    sanitizeConfig: sanitizeConfigOptions
  };

  // TODO - add nullify me method

  constructor(
    private formSubmissionService: FormSubmissionService,
    private tabTitleService: TabTitleService,
    private route: ActivatedRoute,
    private formHeaderDataService: FormHeaderDataService,
    private utcToCompPipe: utcToCompTimePipe,
    private publicConfigService: PublicConfigService,
    private commonService: CommonService,
    private storage: StorageService,
    private printService: PrintService
  ) {
  }

  ngOnDestroy(): void {
    // just in case remove classes used for PDF generation
    document.querySelector('body')?.classList.remove('form-loaded');
    document.querySelector('body')?.classList.remove('pdf-generation');
  }

  doneRendering(): void {
    // document.querySelector('body')?.classList.add('form-loaded');
  }

  async ngOnInit() {
    this.windowWidth = window.innerWidth;
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.print) {
        this.shouldPrint = true;
      }
      if (queryParams.pdfPrintHeadless) {
        this.pdfGeneration = true;
        // add specific class for pdf generation styles
        document.querySelector('body')?.classList.add('pdf-generation');
      }
    })
    this.route.data.subscribe(async (response: any) => {
      this.formDetails = response.formDetails;
      this.showDeadLine = this.formDetails.stageType !== FormStageTypes.RegistrationForm;
      this.enableEdit = this.formDetails.stageType === FormStageTypes.RegistrationForm;
      this.stageId = this.formDetails.stageId;

      this.formSubmissionService.init(
        this.formDetails.userId
      );

      this.commonService.competitionInfo
            .pipe(filter(res => !!res))
            .subscribe(() => {
              const competitionNameFromStorage = this.storage.retrieve(CompetitionInformation.competitionName);
              if (competitionNameFromStorage !== null) {
                this.competitionName = competitionNameFromStorage;
              };
              const competitionLogoFromStorage = this.storage.retrieve(CompetitionInformation.brandingsLogo);
              if (competitionLogoFromStorage !== null) {
                this.competitionLogo = competitionLogoFromStorage;
              };
            });

      await forkJoin([this.getHeaderData(), this.loadFormDefinition(), this.loadFormData()]).toPromise();
      this.tabTitleService.setTabTitle(`${this.formDetails.name} - ${this.formData.userIdentifier}`);
      this.loading = false;
    });
  }

  async getHeaderData() {
    try {
      const response: any = await this.formHeaderDataService
        .GetHeader(this.formDetails.stageId, this.formDetails.userId)
        .toPromise();
      this.formHeaderData = response;
      this.submitted = this.formHeaderData.isSubmitted;

      if (this.formHeaderData.lastSaved === null) {
        this.formHeaderData.state = "INITIAL";
      } else {
        if (this.submitted === false) {
          this.formHeaderData.state = "INPROGRESS";
        } else {
          this.formHeaderData.state = "SUBMITTED";
        }
      }

      this.toggleSaveIcon = false;

      this.deadlineISO = this.formHeaderData.deadline;
      if (this.deadlineISO < this.nowISO) {
        this.formHeaderData.isDeadlinePassed = true;
      } else {
        this.formHeaderData.isDeadlinePassed = false;
      }
      if (!this.formHeaderData.isDeadlinePassed && !this.submitted) {
        if (this.windowWidth > 1200) {
          this.formHeaderData.toolTipText = "Submission Deadline";
        } else {
          this.formHeaderData.toolTipText =
            "Submission Deadline: " +
            this.utcToCompPipe.transform(this.formHeaderData.deadline, "dateTransform") +
            " " +
            this.utcToCompPipe.transform(this.formHeaderData.deadline, "timeTransform");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async loadFormDefinition() {
    const formDefinitionResponse = await this.formSubmissionService.loadFormDefinition(this.stageId);
    if (formDefinitionResponse) {
      this.formDefinition = JSON.parse(formDefinitionResponse);
    }
  }

  async loadFormData() {
    this.formData = await this.formSubmissionService.loadFormData(this.stageId, this.formDetails.userId);
    if (this.formData.data) {
      for (let [key, value] of Object.entries(this.formData.data)) {
        if (value !== null) {
          this.formData.data[key] = JSON.parse(value);
        }
      };
    }
  }

  formioReady(event) {
    // formio ready event
    replaceFebaseurl(this.publicConfigService.feBaseUrl);
    if (this.formData.data) {
      // if there is no form data on initial load, then questions should not be marked as valid
      this.markQuestionsValid(this.formData);
    };

    // this is used to make links inside content components to open in new tab
    const contentPanelsWithExternalLinks = document.querySelectorAll('.formio-component-content.external-links-inside');
    contentPanelsWithExternalLinks.forEach(panel => {
      panel.querySelectorAll('a').forEach(aTag => {
        aTag.setAttribute('target', '_blank');
      });
    });

    this.firstPagePrintContent = {
      pageType: PrintPageTypes.Form,
      stageName: this.formDetails.name,
      fullName: this.formData.userIdentifier
    };

    if (this.shouldPrint) {
      setTimeout(() => {
        this.printService.printPage(this.firstPagePrintContent);
      }, 100);
    }

    if (this.pdfGeneration) {
      setTimeout(() => {
        this.printService.generateFirstPageForPDFGeneration(this.firstPagePrintContent);
        document.querySelector('body')?.classList.add('form-loaded');
      }, 100);
    }
  }

  onResize(event) {
    this.windowWidth = event.target.innerWidth;
  }

  getClass(score: number, deadline: boolean, submitted: boolean) {
    if (deadline == true && submitted == false) return "darkgrey";
    if (score == 0) return "grey";
    if (score > 1 && score < 100) return "blue";
    if (score == 100) return "green";
  }

  markQuestionsValid(formSubmission: FormSubmissionPOC) {
    formSubmission.questionPanels.forEach(panel => {
      const questionElement = document.querySelector(`.formio-component-${panel.key}`);
      // check for valid questions and mark them as valid
      if (questionElement) {
        // valid panels where all questions within are optional
        if (panel.isValid && panel.allOptional) {
          // ^^ if the panel is valid and has only optional input inside
          // check if the panel has empty value inside
          const hasEmptyValue = this.hasEmptyValue(panel.key, formSubmission);
          if (hasEmptyValue && questionElement.classList.contains('question-marked-valid')) {
            // if the panel has empty value and has valid mark, remove it
            questionElement.classList.remove('question-marked-valid');
          } else if (hasEmptyValue && questionElement.classList.contains('question-marked-invalid')) {
            // if the panel has empty value and has invalid mark, remove it
            questionElement.classList.remove('question-marked-invalid');
          } else if (!hasEmptyValue && !questionElement.classList.contains('question-marked-valid')) {
            // if the panel does not have empty value and does not have valid mark, add it and remove invalid mark
            questionElement.classList.remove('question-marked-invalid')
            questionElement.classList.add('question-marked-valid');
          }
        } else if (panel.isValid) {
          // ^^ valid panels where not all question within are optional
          // add valid mark
          questionElement.classList.add('question-marked-valid');
          // remove invalid mark
          questionElement.classList.remove('question-marked-invalid')
        } else if (!panel.isValid) {
          // ^^ invalid panel
          // remove valid mark
          questionElement.classList.remove('question-marked-valid');
        }
      }
    });
  }

  hasEmptyValue(panelKey: string, formSubmission: FormSubmissionPOC): boolean {
    const hasEmptyValueEl = document.querySelector(`.formio-component-${panelKey} .has-empty-value`);
    if (hasEmptyValueEl) {
      return true;
    }

    const panelComponents = this.formDefinition.components
      .find(x => x.type === 'panel' && x.key === panelKey).components
      .map(x => ({ key: x.key, type: x.type }));

    for (const panelComponent of panelComponents) {
      if (!formSubmission.data.hasOwnProperty(panelComponent.key)) {
        continue;
      }

      const valueAsObj = formSubmission.data[panelComponent.key];

      switch (panelComponent.type) {
        case 'selectboxes':
          return Object.values(valueAsObj).every(x => x === false);
        default:
          if (Array.isArray(valueAsObj) && (valueAsObj.length === 0 || (valueAsObj.length === 1 && !valueAsObj[0]))) {
            return true;
          } else if (!valueAsObj) {
            return true;
          }
      }
    }
    return false;
  }

  printPage() {
    this.printService.printPage(this.firstPagePrintContent);
  }
}
