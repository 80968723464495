import { Component, Input, OnInit } from "@angular/core";
import { TabTitleService } from "../shared/services/tab-title.service";

@Component({
  selector: "app-empty-redirect",
  templateUrl: "./empty-redirect.component.html",
  styleUrls: ["./empty-redirect.component.scss"]
})
export class EmptyRedirectComponent implements OnInit {
  
  constructor(private tabTitleService: TabTitleService) {}

  ngOnInit() {
    // component used to set tab title to the default one
    // used for empty redirects with skipLocationChange
    this.tabTitleService.setTabTitle('');
  }
}
