import { Injectable } from "@angular/core";

@Injectable({ 
    providedIn: 'root' 
})
export class SsoAuthDisplayService {

    ssoGenericScreen = {
        visible: false,
        title: "",
        message: "",
        links: []
        }

    constructor(
        
        ) {

    }
    
    showGenericScreen(title:string, message: string, links: string[]){
        this.ssoGenericScreen.title = title;
        this.ssoGenericScreen.message = message;
        this.ssoGenericScreen.links = links;

        this.ssoGenericScreen.visible = true;
    }

    hideGenericScreen() {
        this.ssoGenericScreen.title = "";
        this.ssoGenericScreen.message = "";
        this.ssoGenericScreen.links = [];

        this.ssoGenericScreen.visible = false;        
    }

    handleApiError(err) {
        let handleError: boolean;

        let title = "";
        let message = "";
        let showError = true;
        let activeLinks = [];


        err.error.errors.forEach((error, index) => {
            switch (error.errorCode) {
                case 400100:
                    title = "Email already registered";
                    message += "Looks like you have already registered for a challenge powered by Carrot. Please log in.";
                    //showErrorInGenericScreen = true;
                    activeLinks = [
                    "Login", "Forgot Password"
                    ];
                    handleError = true;
                    break;

                case 400130:
                    title = "Email already verified";
                    message += error.message;
                    activeLinks = [
                        "Back to Login"
                    ];
                    handleError = true;
                    break;
                // verification token expired
                case 400170:
                // verification token not found
                case 404101:
                    title = "Email not verified";
                    message += "This link has expired or is invalid.";
                    activeLinks = [
                        "Back to Login"
                    ];
                    handleError = true;
                    break;
                
                // PasswordResetRequestExpired
                case 400180:
                // PasswordResetRequestNotFound
                case 404102:
                    title = "Reset Password";
                    message += "The link has expired or is invalid.";
                    activeLinks = [
                        "Back to Login", "Forgot Password"
                    ];
                    handleError = true;
                    break;
                default:
                    handleError = false;
                    break;
            }
        });

        if(handleError){
          this.showGenericScreen(title, message, activeLinks);
        }
    }
}