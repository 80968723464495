import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { toBodyParams, toQueryParams } from "../utils/http.util";
import { PublicConfigService } from "./public.config.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class PeerOverviewService {

  constructor(private httpClient: HttpClient, private publicConfigService: PublicConfigService, private storage: StorageService) {

  }

  GetPeerOverviewList(stageId: string, filters: any) {
    return this.httpClient.post(`${this.publicConfigService.rpBaseUrl}/api/judging/${stageId}`, toBodyParams(filters));
  }

  ExportPeerOverviewList(stageId: string, filters: any) {
    return this.httpClient.post(`${this.publicConfigService.rpBaseUrl}/api/judging/export/${stageId}`, toBodyParams({ ...filters }), {
      responseType: 'blob',
      observe: 'response'
    });
  }

  ExportData(stageId: string, InternalExport = true, ExportFormat: 'xlsx' | 'csv' = 'xlsx') {
    return this.httpClient.get(`${this.publicConfigService.rpBaseUrl}/api/judging/export-all-data/${stageId}`, {
      params: toQueryParams({ stageId, InternalExport, ExportFormat  }),
      responseType: 'blob',
      observe: 'response'
    });
  }

  ExportDisqualified(stageId: string, ExportFormat: 'xlsx' | 'csv' = 'csv') {
    return this.httpClient.get(`${this.publicConfigService.rpBaseUrl}/api/judging/${stageId}/export-disqualified-participants`, {
      params: toQueryParams({ stageId, ExportFormat }),
      responseType: 'blob',
      observe: 'response'
    });
  }

  updatePeerOverviewListUser(stageUserId: string, body: any) {
    return this.httpClient.put(`${this.publicConfigService.rpBaseUrl}/api/judging/${stageUserId}?loader=false`, body, {

    });
  }

  GetPeerOverviewDetailsList(judgeId: string, filters: any) {
    return this.httpClient.post(`${this.publicConfigService.rpBaseUrl}/api/judging/judge-details/${judgeId}`, toBodyParams(filters));
  }

  ExportPeerOverviewDetailsList(judgeId: string, filters: any) {
    return this.httpClient.post(`${this.publicConfigService.rpBaseUrl}/api/judging/judge-details/${judgeId}/export`, toBodyParams({ ...filters }), {
      responseType: 'blob',
      observe: 'response'
    });
  }

  GetPeerAvailableJudges(judgementId: string, filters: any) {
    return this.httpClient.get(`${this.publicConfigService.rpBaseUrl}/api/judging/available-judges/${judgementId}`, {

      params: toQueryParams({ ...filters })
    });
  }

  reassignJudge(judgementId: string, toJudgeId: string) {
    return this.httpClient.put(`${this.publicConfigService.rpBaseUrl}/api/judging/reassign`, { judgementId, toJudgeId }, {

    });
  }

  performSwap(judgementId: string) {
    return this.httpClient.post(`${this.publicConfigService.rpBaseUrl}/api/judging/swap`, { judgementId });
  }
}
