import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router } from "@angular/router";
import { Observable, ReplaySubject } from "rxjs";
import { take, filter } from "rxjs/operators";
import { SsoApiResponse, SsoRegisterUserRequest, Tokens } from "../model/auth.model";
import { ImpersonationInfo, RpUser, SsoUser } from "../model/user.model";
import { DataService } from "./data.service";
import { FirebaseAuthWrapperService } from "./firebase-auth-wrapper.service";
import { PublicConfigService, CarrotSSOConfiguration } from "./public.config.service";
import { StorageService } from "./storage.service";
import { environment } from "src/environments/environment";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { NavBarService } from "./nav-bar.service";

@Injectable({ 
    providedIn: 'root' 
})
export class SsoAuthService {
    private LastLoginRedirectTimestampKey = "LastLoginRedirectTimestamp";
    private ForceJWTRenewalKey = "ForceJWTRenewal";
    private ImpersonationInfoKey = "ImpersonationInfoKey";
    private ssoConfiguration: CarrotSSOConfiguration;
    private queryStringParams = {};
    private returnUrl: string;
    private isLoggin = false;
    private isInitialLoad = true;
    private startingImpersonation = false;
    private stoppingImpersonation = false;


    private _isRpUserRefreshing = new ReplaySubject();

    private _currentSsoUser:  ReplaySubject<SsoUser | null>= new ReplaySubject(1);
    public currentSsoUser$: Observable<SsoUser | null>;

    private _currentRpUser:  ReplaySubject<RpUser | null>= new ReplaySubject(1);
    public currentRpUser$: Observable<RpUser | null>;

    private _impersonationInfo:  ReplaySubject<ImpersonationInfo | null>= new ReplaySubject(1);
    public impersonationInfo$: Observable<ImpersonationInfo | null>;

    public isEmailNotVerified: boolean = false;

    constructor(
        private afAuth: AngularFireAuth,
        private firebaseAuthWrapperService: FirebaseAuthWrapperService,
        private dataService: DataService,
        private publicConfigService: PublicConfigService,
        private router: Router,
        private storage: StorageService,
        private googleTagManagerService: GoogleTagManagerService,
        private navBarService: NavBarService
        ) {
        this.afAuth.setPersistence("local");
        this.ssoConfiguration = publicConfigService.getCarrotSSOConfig();
        this.currentSsoUser$ = this._currentSsoUser.asObservable();
        this.currentRpUser$ = this._currentRpUser.asObservable();
        this.impersonationInfo$ = this._impersonationInfo.asObservable();
        this.reloadImpersonationInfo();
        
        this.afAuth.onAuthStateChanged(async user =>{
            console.log("sso-auth.service -> constructor -> this.afAuth.onAuthStateChanged");
            if(user){
                console.log("sso-auth.service -> constructor -> this.afAuth.onAuthStateChanged -> user exists");
                // check user email is verified
                let idToken = await user.getIdTokenResult();
                const shoudlRefreshJwt = this.storage.retrieve(this.ForceJWTRenewalKey);
                if(this.isInitialLoad ||
                    (shoudlRefreshJwt != null && shoudlRefreshJwt)){
                    this.storage.removeCompletely(this.ForceJWTRenewalKey);
                    idToken = await user.getIdTokenResult(true);
                }
                
                const emailVerifiedClaim = idToken.claims["email_verified"];
                const rolesByClientIdClaim = idToken.claims["rolesByClientId"];
                const firstname = idToken.claims["firstname"];
                const lastname = idToken.claims["lastname"];
                this.isEmailNotVerified = !emailVerifiedClaim;

                const ssoUser: SsoUser = {
                    uid: user.uid,
                    firstName: firstname,
                    lastName: lastname,
                    fullName: `${firstname} ${lastname}`
                }
                this._currentSsoUser.next(ssoUser);

                if(this.isEmailNotVerified) {
                    // redirect to verify email page
                    this._isRpUserRefreshing.next(false);
                    return;
                }

                // check user has roles assignment
                if(!rolesByClientIdClaim || !rolesByClientIdClaim[this.ssoConfiguration.ssoClientId]) {
                    const hasSsoSession = this.hasSsoSession();
                    if(hasSsoSession) {
                        // automatic login flow -> show login screen
                        return;
                    }
                    else {
                        // the user has entered his credentials -> login redirect
                        this.googleTagManagerService.pushTag({
                            event: 'user_register_event',
                            url: this.publicConfigService.rpBaseUrl
                        });
                        this.storage.store(this.ForceJWTRenewalKey, true);
                        await this.loginRedirect();
                        return;
                    }
                }

                const hasSsoSession = this.hasSsoSession();
                const shouldLoginRedirect = this.shouldLoginRedirect();
                if(shouldLoginRedirect && !hasSsoSession) {
                    // the user has entered his credentials -> login redirect
                    await this.loginRedirect();
                    return;
                }
                else if(this.isInitialLoad){
                    this.isInitialLoad = false;
                    this.isLoggin = true;
                    // not waiting on purpose, as forceRPUserRefresh is still not started
                    this.redirectAfterLogin();
                }


                await this.forceRpUserRefresh();
                if(this.startingImpersonation) {
                    console.log("sso-auth.service -> constructor -> this.afAuth.onAuthStateChanged -> user exists -> startingImpersonation -> redirect to /dashboard");
                    this.startingImpersonation = false;
                    this.router.navigateByUrl("/dashboard");
                }
            }
            else{
                console.log("sso-auth.service -> constructor -> this.afAuth.onAuthStateChanged - no user");
                this._currentSsoUser.next(null);
                this._currentRpUser.next(null);
                if(this.stoppingImpersonation) {
                    console.log("sso-auth.service -> constructor -> this.afAuth.onAuthStateChanged -> no user -> stoppingImpersonation -> redirect to /login");
                    this.stoppingImpersonation = false;
                    this.router.navigateByUrl("/login");
                }
            }   
        })
    }

    public setReturnUrl(relativePath:string) {
        relativePath = relativePath ? relativePath : "/dashboard";
        this.returnUrl = relativePath;
    }

    public setQueryStringParams(rawQuery: string){
        const searchParams = new URLSearchParams(rawQuery);
        const queryParams = {};
        for (const [key, value] of searchParams.entries()) {
        queryParams[key] = value;
        }

        this.queryStringParams = queryParams;
    }

    public getCurrentSsoUser() : Promise<SsoUser> {
        return this.currentSsoUser$
        .pipe(take(1))
        .toPromise();
    }

    async getCurrentRpUser() : Promise<RpUser>{
        return this.currentRpUser$
        .pipe(take(1))
        .toPromise();
    }

    async checkSsoSession() {
        const currentRpUser = await this.getCurrentRpUser();
        if(currentRpUser === null) {

            const ssosession = this.queryStringParams['ssosession'];
            
            if(ssosession === undefined) {
                this.meRedirect();
            }
            else if(ssosession === "false") {
                //TODO: show login screen
            }
            else if(ssosession === "true") {
                const loginToken = this.queryStringParams['loginToken'];
                await this.loginWithCustomToken(loginToken);
                this.setLastLoginRedirectTimestamp();
                await this.redirectAfterLogin();
            }

        }
        else {
            const jwt = this.queryStringParams['jwt'];

            if(jwt) {
                // the user is returning from the loginRedirect sso endpoint, force refresh the JWT
                await this.firebaseAuthWrapperService.refreshJwt();
            }

            // the user is logged in, redirect to dashboard
            await this.redirectAfterLogin();
        }
    }

    async loginWithCredentials(email: string, password: string) {
        this.isLoggin = true;
        await this.afAuth.signOut();
        const user = await this.afAuth.signInWithEmailAndPassword(email, password);

        await this.redirectAfterLogin();
    }

    async registerUser(registerUser: SsoRegisterUserRequest) : Promise<SsoApiResponse<SsoRegisterUserRequest>> {
        const resp = this.dataService.post(
            this.ssoConfiguration.ssoBaseUrl + "/api/v1/profile/selfregister?clientId=" + this.ssoConfiguration.ssoClientId,
             registerUser
            ).toPromise();
        
        return resp;
    }
    
    async resendVerifyEmail() {
        const resp = this.dataService.post(
            this.ssoConfiguration.ssoBaseUrl + "/api/v1/profile/sendVerifyEmail", { clientId: this.ssoConfiguration.ssoClientId })
            .toPromise();

        return resp;
    }
    
    async verifyEmail(token:string) {

        const resp = this.dataService.get(
            this.ssoConfiguration.ssoBaseUrl + "/api/v1/profile/verifyEmail?token=" + token)
            .toPromise();

        return resp;
    }
    
    async sendForgotPasswordEmail(email:string) {

        const resp = this.dataService.post(
            this.ssoConfiguration.ssoBaseUrl + "/api/v1/profile/passwordReset", { email, clientId: this.ssoConfiguration.ssoClientId })
            .toPromise();

        return resp;
    }
    
    async getConfirmPasswordRequest(token:string) {

        const resp = this.dataService.get(
            this.ssoConfiguration.ssoBaseUrl + "/api/v1/profile/confirmPasswordReset/" + token )
            .toPromise();

        return resp;
    }
    
    async postConfirmPasswordRequest(token:string, newPassword: string) {

        const resp = this.dataService.post(
            this.ssoConfiguration.ssoBaseUrl + "/api/v1/profile/confirmPasswordReset/", { requestId : token, newPassword })
            .toPromise();

        return resp;
    }

    async logout(returnUrl? : string, logoutFromSso: boolean = true){
        // setting or updating userStatus cookie
        this.setOrUpdateUserStatusCookie(1);

        if(returnUrl) {
            returnUrl = this.publicConfigService.rpBaseUrl + returnUrl;
        }
        else {
            returnUrl = this.publicConfigService.feBaseUrl;
        }
        this.storage.removeCompletely(this.ImpersonationInfoKey);

        this.removeLastLoginRedirectTimestamp();
        console.log("sso-auth.service -> logout -> this.afAuth.onAuthStateChanged");
        await this.afAuth.signOut();
        if(logoutFromSso){
            window.location.href = this.ssoConfiguration.ssoBaseUrl + "/api/v1/sso/logoutRedirect?redirectUrl=" + returnUrl;
        }
    }

    async forceRpUserRefresh(): Promise<RpUser> {
        this._isRpUserRefreshing.next(true);
        if(this.isLoggin){
            await this.selfRegisterRP();
            this.isLoggin = false;
        }

        const resp = await this.dataService.getMe().toPromise();
        const rpUser = resp.data;
        this._currentRpUser.next(rpUser);
        this._isRpUserRefreshing.next(false);

        return rpUser;
    }

    public async loginWithCustomToken(customToken: string) {
        this.isLoggin = true;
        await this.afAuth.signInWithCustomToken(customToken);        
    }

    public async startImpersonate(userId: string, firstName: string, lastName: string) {
        const response = await this.dataService.get(`${this.publicConfigService.rpBaseUrl}/api/user/impersonate/${userId}`).toPromise();
        const currentRpUser = await this.getCurrentSsoUser();
        
        const impersonationInfo = {
            impersonatedByFullName: `${currentRpUser.firstName} ${currentRpUser.lastName}`,
            impersonatedFullName: `${firstName} ${lastName}`
        };
        this.storage.store(this.ImpersonationInfoKey, JSON.stringify(impersonationInfo));
        this.reloadImpersonationInfo();
        this.startingImpersonation = true;
        await this.loginWithCustomToken(response.data.loginToken);

        // refreshing nav items after impersonation
        this.navBarService.refreshNav();
    }

    public async stopImpersonate(){
        this.stoppingImpersonation = true;
        this.storage.removeCompletely(this.ImpersonationInfoKey);
        this.reloadImpersonationInfo();
        this.logout("", false);
    }

    private reloadImpersonationInfo(){
        let impersonationInfo = null;
        const rawImpersonationInfo = this.storage.retrieve(this.ImpersonationInfoKey);
        if(rawImpersonationInfo) {
            impersonationInfo = JSON.parse(rawImpersonationInfo);
        }
        this._impersonationInfo.next(impersonationInfo);
    }

    private async selfRegisterRP(){
        await this.dataService.get(`${this.publicConfigService.rpBaseUrl}/api/user/selfregister`).toPromise();
    }

    private async redirectAfterLogin(){
        let redirectUrl = this.getReturnUrl();

        // setting or updating userStatus cookie
        this.setOrUpdateUserStatusCookie(2);

        await this._isRpUserRefreshing.asObservable()
        .pipe(
            filter(isRpUserRefreshing => isRpUserRefreshing === false),
            take(1)
        ).toPromise();
    
        if(!this.isEmailNotVerified) {

            if (redirectUrl === '/login') {
                // hacky solution for the edge case when the returnUrl is /login
                this.router.navigateByUrl('/empty-redirect', {skipLocationChange: true})
                    .then(() => this.router.navigateByUrl(redirectUrl));
            } else {
                this.router.navigateByUrl(redirectUrl);
            }
        }
    }

    private async loginRedirect(){
        const jwt = await this.firebaseAuthWrapperService.getJwt();
        
        // setting or updating userStatus cookie
        this.setOrUpdateUserStatusCookie(2);

        let redirectUrl = this.ssoConfiguration.ssoBaseUrl + "/api/v1/sso/loginRedirect?clientId=" + this.ssoConfiguration.ssoClientId + "&jwt=" + jwt;
        redirectUrl = this.appendReturnUrlQueryString(redirectUrl);

        this.setLastLoginRedirectTimestamp();
        await document.fonts.ready;
        window.location.href = redirectUrl;
    }

    private async meRedirect(){
        let redirectUrl = this.ssoConfiguration.ssoBaseUrl + "/api/v1/sso/meRedirect?clientId=" + this.ssoConfiguration.ssoClientId;
        redirectUrl = this.appendReturnUrlQueryString(redirectUrl);

        await document.fonts.ready;
        window.location.href = redirectUrl;
    }

    private appendReturnUrlQueryString(url:string) {
        const returnUrl = this.getReturnUrl();
        if(returnUrl) {
            url += "&returnUrl=" + returnUrl
        }
        if(environment.localDevSsoRedirect) {
            url += "&localDev=true"
        }

        return url;
    }

    private getReturnUrl(){
        let returnUrl = this.queryStringParams["returnUrl"] ?? this.returnUrl;

        return returnUrl;
    }

    private hasSsoSession(): boolean{
        const ssoSession = this.queryStringParams["ssosession"];
        const jwt = this.queryStringParams['jwt'];

        return ssoSession === "true" || jwt;
    }

    private shouldLoginRedirect(){
        let shouldLoginRedirect: boolean;
        const lastLoginRedirectTimestamp = this.storage.retrieve(this.LastLoginRedirectTimestampKey);

        if(!lastLoginRedirectTimestamp) {
            shouldLoginRedirect = true;
        } else {
            const twentyFourHours = environment.ssoLoginRedirectIntervalInHours * 60 * 60 * 1000;
            const currentTime = new Date().getTime();
            shouldLoginRedirect = (lastLoginRedirectTimestamp + twentyFourHours) < currentTime;
        }

        return shouldLoginRedirect;
    }

    private setLastLoginRedirectTimestamp() {
        this.storage.store(this.LastLoginRedirectTimestampKey, new Date().getTime());
    }

    private removeLastLoginRedirectTimestamp() {
        this.storage.remove(this.LastLoginRedirectTimestampKey);
    }

    private setOrUpdateUserStatusCookie(value: number) {
        // setting or updating userStatus cookie
        const cookieName = `${this.publicConfigService.getEnvironmentName().toUpperCase()}-userStatus`;
        const cookieValue = value;
        const hostnameArr = document.location.hostname.split('.');
        // remove first element from array (subdomain)
        hostnameArr.shift();
        // join array in a new string
        const domain = `.${hostnameArr.join('.')}`;
        document.cookie = `${cookieName}=${cookieValue};path=/;domain=${domain};samesite=none;Secure`;
    }
}
