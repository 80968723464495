export function getTaxonomyEditData(rpBaseUrl: string) {
  const taxonomyEditData = [
    {
      key: "multiple",
      ignore: true,
    },
    {
      key: "defaultValue",
      ignore: true,
    },
  
    {
      type: "select",
      input: true,
      dataSrc: "url",
      data: {
        url: `${rpBaseUrl}/api/datasets/taxonomy/`,
      },
      template: "<span>{{ item.item1 }}</span>",
      valueProperty: "item1",
      clearOnHide: false,
      label: "Resource custom",
      key: "customOptions.taxonomyDatasetName",
      lazyLoad: false,
      weight: 10,
      tooltip: "The resource to be used with this field.",
    },
    {
      weight: 20,
      type: "checkbox",
      input: true,
      key: "customOptions.hideKeys",
      label: "Hide Keys in Submissions",
      tooltip: "Hide keys in submissions.",
    },
  ];

  return taxonomyEditData;
}