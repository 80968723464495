export interface IGridConfig {
  gridName: string;
  stageId: string;
  stages: object;
  columns: IGridConfigColumn[];
}

export interface IGridConfigColumn {
  index: number;
  field: string;
  header: string;
  visibleInGrid: boolean;
  useInExport: boolean;
  isUserFormData: boolean;
  isDetail: boolean;
}

export const GridNames = {
  BulkReview: "bulk-review",
  AdminReview: "admin-review",
  EvaluationOverview: "evaluation-overview",
  RegAndConfirmed: "registered-and-confirmed",
  RegAndConfirmedForms: "registered-and-confirmed-form-stage",
  PeerReviewOverview: "peer-review-overview",
  EvaluationResultForStaff: "evaluation-results-for-staff",
  PeerReviewResultForStaff: "peer-review-results-for-staff",
  EvaluationAssignments: "evaluation-assignments-by-participant",
  PeerReviewAssignments: "peer-review-assignments-by-participant",
  EvaluationOverview2: "evaluation-overview-view-2",
  PeerOverview2: "peer-review-overview-view-2",
  AllFormsData: "all-forms-data",
};

