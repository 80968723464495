import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IBrandingLinks, ICompetitionSetupAccountRegistrationDetails, ICompetitionSetupBranding, ICompetitionSetupDetails, ICompetitionSetupStages } from "../model/IGeneralSetup";
import { PublicConfigService } from "./public.config.service";
import { toQueryParams } from "../utils/http.util";


@Injectable(
    {providedIn: 'root'}
)
export class GeneralSetupService {

    public publicConfig: any; 
    public subjectStatusState = new Subject();
    public platformChallengeSiteLink = new Subject();
    public platformFooterLink = new Subject();

    constructor(
        private httpClient: HttpClient,
        publicConfigService: PublicConfigService
        ) {
        this.publicConfig  = publicConfigService;
    }

    GetCompetitionSetupStatus() {
         return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/tabs`, {
           
        });
    }

    updateStatusState(data: Array<any>) {
        this.subjectStatusState.next(data);
    }

    statusStateListener(): Observable<any> {
        return this.subjectStatusState.asObservable();
    }

    GetTimeZone() {
        return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/timezones`, {
           
       });
   }

   GetSpecificTimeZone(id: string) {
        return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/timezone/${id}`, {
           
       });
   }

   GetApplicantIdentifiers() {
    return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/applicantIdentifiers`, {
    });
   }
   
    GetCompetitionSetupDetails() {
         return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/details`, {
           
        });
    }

    PutCompetitionSetupDetails(data: ICompetitionSetupDetails) {
        return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/details`, data, {
           
       });
    }

    GetCompetitionAccountRegistrationDetails() {
        return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/details/registration`, {
           
        });
    }

    PutCompetitionAccountRegistrationDetails(details: ICompetitionSetupAccountRegistrationDetails) {
        return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/details/registration`, { ... details }, {
           
        });
    }

    GetCompetitionSetupStages() {
        return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages`, {
           
        });
    }

    GetStageType(id: string) {
        return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/stageType/${id}`, {
           
        });
    }

    GetStageTypes(includeDefaults : boolean) {
        return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/stageTypes/${includeDefaults}`, {
           
        });
    }

    PutCompetitionSetupStages(data: Array<ICompetitionSetupStages>) {
        return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages`, data, {
           
       });
   }
    
    GetCompetitionSetupBranding() {
         return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/branding`, {
           
        });
    }

    PutCompetitionSetupBranding(data: ICompetitionSetupBranding) {
         return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/branding`, data, {
           
        });
    }

    GetChallengeSiteLinks() {
         return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/challengeSiteLinks`, {
           
        });
    }

    PutChallengeSiteLinks(data: Array<IBrandingLinks>) {
         return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/challengeSiteLinks`, data, {
           
        });
    }

    updatePlatformChallengeSiteLinks(data: Array<any>) {
        this.platformChallengeSiteLink.next(data);
    }

    platformChallengeSiteListener(): Observable<any> {
        return this.platformChallengeSiteLink.asObservable();
    }

    GetPlatformFooterLinks() {
         return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/platformFooterLinks`, {

        });
    }

    PutPlatformFooterLinks(data: Array<IBrandingLinks>) {
         return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/platformFooterLinks`, data, {
           
        });
    }

    updatePlatformFooterLinks(data: Array<any>) {
        this.platformFooterLink.next(data);
    }

    platformFooterLinksListener(): Observable<any> {
        return this.platformFooterLink.asObservable();
    }

    transformBlobStorageUrl(data: string) {
        return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/file`, data, {
           
        });
    }

    getContentTypes() {
        return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/configuration/competitionSetup/content-types`);
    }

    getContent(contentType: string) {
        return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/configuration/competitionSetup/content`, {
            params: toQueryParams({ contentType })
        });
    }

    saveContent(contentType: string, value: string, metaInfo: string, role?: string) {
        return this.httpClient.post(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/content`, {
            contentType,
            value,
            metaInfo,
            role
        });
    }

    updateContent(id: string, value: string, metaInfo: string, role?: string) {
        return this.httpClient.post(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/content`, {
            id,
            value,
            metaInfo,
            role
        });
    }

    getFormConfiguration(stageId: string) {
        return this.httpClient.get(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages/${stageId}/formConfiguration`);
    }

    saveFormConfiguration(stageId: string, data: { deadlineAdminReminderEnabled: boolean, deadlineParticipantReminderEnabled: boolean }) {
        return this.httpClient.put(`${this.publicConfig.rpBaseUrl}/api/masteradmin/configuration/competitionSetup/stages/formConfiguration`, {
            stageId,
            ...data
        });
    }
}