export default [
  {
    key: "validate.custom",
    calculateValue(context) {
      return "valid = (!input && !component.validate.required) || (input && JSON.parse(input).valid) || '{{component.label}} is required.'";
    },
  },
  {
    weight: 20,
    type: "number",
    input: true,
    key: "customOptions.maxSelection",
    label: "Max Selections",
    tooltip: "Type in the maximum number of selections participants can make.",
    validate: {
      required: true,
    },
  },
];
