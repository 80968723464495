<div class="login-page" [ngClass]="{'initial-loading': showLogin}">
  <div class="login-loader">
    <div class="loader-inner">
      <div class="loader-image">
        <img src="assets/images/carrot-logo-black-cropped.png" alt="Carrot loader indicator...">
      </div>
      <div class="loader-progress"></div>
    </div>
  </div>
  <div class="login-widget" [ngClass]="{'loading': loaderShown}">
    <div class="login-widget-content">
      <div class="login-widget-branding">
        <img [src]="competitionLogo" [alt]="competitionLogoAltText">
        <div class="loader">
          <div class="loader-inner"></div>
        </div>
      </div>
      <div class="login-form-wrap" *ngIf="!ssoAuthDisplayService.ssoGenericScreen.visible">
        <h1 class="title">Forgot Password</h1>
        <p class="subtitle">Enter your email and we'll send you a link to reset your password.</p>
        <div class="action-failed" *ngIf="showErrorMessage">
          <p>{{ errorMessage }}</p>
        </div>
        <div class="input-wrap">
          <label for="user-email" class="input-label">
            <input
              type="text"
              id="user-email"
              class="input-text"
              #userEmailId="ngModel"
              [(ngModel)]="userEmail"
              (keydown)="keyDownHandler($event)"
              (keyup)="userEmail = userEmail.trim()"
              maxlength="256"
              [ngClass]="{
                'errored': (!userEmail?.match(emailRegex) && userEmail?.length > 0) || (userEmail?.length < 1 && userEmailId.touched),
                'errored-real': (!userEmail?.match(emailRegex) && userEmail?.length > 0) || (userEmail?.length < 1 && userEmailId.touched)
              }"
              aria-label="Enter email"
            />
            <span
              class="placeholder input-placeholder"
              [ngClass]="{ 
                'filled': userEmail,
                'errored': (!userEmail?.match(emailRegex) && userEmail?.length > 0) || (userEmail?.length < 1 && userEmailId.touched)
              }"
            >
              Email
            </span>
            <span *ngIf="(!userEmail?.match(emailRegex) && userEmail?.length > 0) || (userEmail?.length < 1 && userEmailId.touched)" class="error-field">
              <span class="error-message" *ngIf="(!userEmail?.match(emailRegex) && userEmail?.length > 0)">
                <span class="icon error-icon">
                  <span class="material-icons" aria-hidden="true">priority_high</span>
                </span>
                <span class="error-text">Please enter a valid email address.</span>
              </span>
              <span class="error-message" *ngIf="userEmail?.length < 1 && userEmailId.touched">
                <span class="icon error-icon">
                  <span class="material-icons" aria-hidden="true">priority_high</span>
                </span>
                <span class="error-text">Please enter a valid email address</span>
              </span>
            </span>
          </label>
        </div>
        <div class="button-wrap">
          <button
            class="button button-primary button-login"
            (click)="onSubmit()"
            [disabled]="!this.userEmail.match(emailRegex)"
          >
            Reset Password
          </button>
        </div>
        <div class="extra-buttons-wrap">
          <span class="text">Remember password?</span>
          <a routerLink="/login" class="button-forgotten-pass">Back to Login</a>
        </div>
      </div>

      <app-auth-generic-screen></app-auth-generic-screen>
      
    </div>
  </div>
</div>
