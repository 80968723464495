export function getLocationEditData(rpBaseUrl: string) {
  const locationEditData = [
    {
      key: "multiple",
      ignore: true,
    },
    {
      key: "defaultValue",
      ignore: true,
    },
    {
      type: "select",
      input: true,
      dataSrc: "url",
      data: {
        url: `${rpBaseUrl}/api/datasets/dropdown/`,
      },
      template: "<span>{{ item.item1 }}</span>",
      valueProperty: "item1",
      clearOnHide: false,
      label: "Location dataset name",
      key: "customOptions.locationDatasetName",
      lazyLoad: false,
      weight: 10,
      tooltip: "The name of the location dataset to be used as datasource.",
    },
    {
      type: "textfield",
      input: true,
      clearOnHide: false,
      label: "Location dataset name",
      key: "customOptions.locationDatasetName",
      lazyLoad: false,
      weight: 10,
      tooltip: "The name of the location dataset to be used as datasource.",
    },
    {
      weight: 15,
      type: "number",
      input: true,
      key: "customOptions.maxSelection",
      label: "Max Selections",
      tooltip:
        "Set a maximum number of locations that can be added. Numbers-only field.",
      validate: {
        required: true,
      },
      defaultValue: 1,
    },
    {
      weight: 20,
      type: "checkbox",
      input: true,
      key: "customOptions.hideKeys",
      label: "Hide Keys in Submissions",
      tooltip:
        "Indicate whether the IDs from the data set need to be present in the export.",
    },
  ];

  return locationEditData;
}
