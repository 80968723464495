import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox-button',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() disabled?: boolean = false;
  @Input() state?: boolean = false;
  @Input() label?: string = '';
  @Input() ariaLabel?: string = '';
  @Output() stateChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  onStateChange() {
    this.stateChange.emit(this.state);
  }

}
