import { Injectable } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { BasicModalComponent } from "../components/modal/basic-modal/basic-modal.component";

@Injectable({
  providedIn: "root",
})
export class NotificationDialogService {
  constructor(private dialogService: DialogService) {}

  public confirm(
    title: string,
    status: string,
    message: string,
    btnOkText: string = "OK",
    dialogSize: string = 'auto',
  ): Promise<boolean> {
    const modalRef = this.dialogService.open(BasicModalComponent, {
      dismissableMask: true,
      styleClass: "dialog hidden-close-dialog",
      width: dialogSize,
      data: {
        title,
        message,
        btnOkText,
        status,
      },
    });
    return new Promise((resolve, reject) => {
      modalRef.onClose.subscribe((res) => resolve(res));
    });
  }
}
