import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class CopyToClipboardService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  copyToClipboard(stringToCopy: string): void {
    navigator.clipboard.writeText(stringToCopy);
    this.snackBar.open('Copied to clipboard!', 'Close', { duration: 5 * 1000 })
  }
}
