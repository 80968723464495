export const NotApplicableLocation = "Not Applicable";
export const NoResultsFoundLocation = "No results found";

export interface Location {
  value: number;
  parentValue: number;
  text: string;
}

export interface LocationWrapped {
  index: number;
  value: { value: any; hideKeys: boolean, valid: boolean };
}

export function createEmptyLocation(value = 0, text = "") {
  return {
    value: value,
    parentValue: 0,
    text: text,
  };
}

export function createNotApplicableLocation() {
  return {
    value: 0,
    parentValue: 0,
    text: NotApplicableLocation,
  };
}

export function createNoResultsFoundLocation() {
  return {
    value: -1,
    parentValue: 0,
    text: NoResultsFoundLocation,
  };
}
