<div class="video" [ngClass]="{'has-empty-value': isControlEmpty}">
  <div class="input-group video-url formio-component-textfield">
    <div ref="element" class="element-wrapper">
      <input
        type="text"
        class="form-control"
        [ngClass]="{
                    'is-invalid': !(loading$ | async) && error,
                    'is-empty': !videoUrl
                  }"
        placeholder="Please enter a public YouTube URL or ID"
        [disabled]="disabled"
        [(ngModel)]="videoUrl"
      />
      <button *ngIf="videoUrl" (click)="clearField()" pTooltip="Remove video" [disabled]="disabled" type="button"
        class="button-icon-round icon-w-fill">
        <span class="icon material-icons">delete</span>
      </button>
      <div *ngIf="loading$ | async" class="d-flex align-items-center spinner-wrapper">
        <div class="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="error" class="formio-errors invalid-feedback" ref="messageContainer">
    <div class="form-text error">{{ error }}</div>
  </div>
  <div class="youtube-player" [ngClass]="{ hidden: !visible }">
    <youtube-player
      *ngIf="videoId"
      [videoId]="videoId"
      (ready)="playerReady($event)"
      (stateChange)="playerStateChange($event)"
    ></youtube-player>
  </div>
</div>