import { BreakpointObserver } from "@angular/cdk/layout";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatStepper, StepperOrientation } from "@angular/material/stepper";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ConfirmationDialogService } from "src/app/shared/services/confirmation-dialog.service";
import { EvaluationJudgingService } from "src/app/shared/services/evaluation-judging.service";
import { TabTitleService } from "src/app/shared/services/tab-title.service";

@Component({
  selector: "app-evaluation-judging-review",
  templateUrl: "./evaluation-judging-review.component.html",
  styleUrls: ["./evaluation-judging-review.component.scss"],
})
export class EvaluationJudgingReviewComponent implements OnInit {
  @ViewChild("stepper") stepper: MatStepper;
  steps: any[] = [];

  queryParamStep = 0;
  linear = true;
  selectedStep = 0;
  totalSteps = 0;
  mediaSubscription: Subscription;
  stepperOrientation: StepperOrientation = "horizontal";
  formsArray = [];
  saving = false;

  stageId: string = '';
  reviewId: string = '';


  stageName = '';
  participantName = '';
  lastUpdated = '';
  participantUserId: string = '';

  wordCount = 10;

  hasUnsavedData: boolean = false;

  $autoSave = new Subject<any>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private confirmationDialogService: ConfirmationDialogService,
    private judgmentService: EvaluationJudgingService,
    private tabTitleService: TabTitleService
  ) {
    // this.mediaSubscription = breakpointObserver
    // 	.observe([Breakpoints.Large, Breakpoints.XLarge])
    // 	.subscribe((result) => (result.matches ? (this.stepperOrientation = 'horizontal') : (this.stepperOrientation = 'vertical')))
  }

  ngOnInit() {
    this.stageId = this.activatedRoute.snapshot.params.id;
    this.reviewId = this.activatedRoute.snapshot.params.reviewId;
    const step = this.activatedRoute.snapshot.queryParams.step
    this.queryParamStep = step && step !== '0' ? Math.abs(step) : null;
    this.initSteps();
    this.$autoSave
      .asObservable()
      .pipe(debounceTime(300))
      .subscribe(async data => {

        this.saving = true;
        const payload = {
          judgementId: this.reviewId,
          step: data.stepCount,
        }

        if (data.type === 'formsreview') {
          Object.assign(payload, { readFormStageId: data.formStageId })
        }

        if (data.type === 'coi') {
          Object.assign(payload, { hasCoi: data.hasCoi, hasCoiConfirmed: data.hasCoiConfirmed })
        }

        if (data.type === 'trait') {
          Object.assign(payload, {
            rubricTrait: {
              rubricTraitId: data.rubricTraitId,
              score: data.score,
              comment: data.comment
            }
          })
        }

        if (data.type === 'overallimpression') {
          Object.assign(payload, { overallImpressionScore: data.score })
        }

        const res: any = await this.judgmentService.saveJudgementSteps(payload).toPromise();
        this.hasUnsavedData = false;
        this.lastUpdated = res.lastUpdated;
        this.saving = false;
        this.prepareSummaryStep(res.data);
      })
  }

  async initSteps() {
    const res: any = await this.judgmentService.getJudgementSteps(this.reviewId).toPromise()
    this.stageName = res.stageName;
    this.participantName = res.participantName;
    this.lastUpdated = res.lastUpdated;
    this.participantUserId = res.participantUserId;
    this.tabTitleService.setTabTitle(`${this.stageName}`);
    this.totalSteps = res.data.length;
    this.queryParamStep = this.queryParamStep > this.totalSteps ? 1 : this.queryParamStep;
    this.linear = res.currentStep === this.totalSteps ? false : true;
    this.wordCount = res.rubricTraitCommentsMinWords ?? 0;
    this.formsArray = res.data.find(step => step.type === "formsreview")?.forms?.map((form: any) => {
      const formUrl = this.getFormUrl(form);
      return {
        label: form.title,
        url: formUrl,
        target: '_blank'
      }
    });
    this.steps = res.data.map((step: any) => {

      step.errors = [];
      step.completed = step.isComplete;

      if (step.type === 'trait') {
        step.rangeDefinition = 'Drag the slider in order to select a score';
        step.rangeArray = [
          { score: 0, text: step.rangeDefinition0 },
          { score: 1, text: step.rangeDefinition1 },
          { score: 2, text: step.rangeDefinition2 },
          { score: 3, text: step.rangeDefinition3 },
          { score: 4, text: step.rangeDefinition4 },
          { score: 5, text: step.rangeDefinition4 },
        ]
      }

      if (step.type === 'trait' && step.score === null) {
        step.rangeChanged = false;
        step.score = 2.5;
      } else if (step.type === 'trait' && step.score !== null) {
        step.rangeChanged = true;
        this.sliderChange(step, step.score);
      }

      if (step.type === 'overallimpression' && step.score === null) {
        step.rangeChanged = false;
        step.score = 50;
      } else if (step.type === 'overallimpression' && step.score !== null) {
        step.rangeChanged = true;
        this.sliderChange(step, step.score);
      }

      return step;
    });
    this.prepareSummaryStep(this.steps)
    setTimeout(() => {
      this.selectedStep = res.currentStep === this.totalSteps && this.queryParamStep ? this.queryParamStep - 1 : res.currentStep - 1;
      // NOT WORKING BECAUSE self register api call returns previous page
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { step: this.selectedStep + 1 },
        queryParamsHandling: "merge",
      });
    }, 0)
  }

  selectionChanged(event: StepperSelectionEvent) {
    this.selectedStep = event.selectedIndex;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { step: event.selectedIndex + 1 },
      queryParamsHandling: "merge",
    });
  }

  async next(step: any) {
    this.steps.forEach(step => step.errors = []);

    if (step.type === "formsreview" && !step.forms?.every((step: any) => step.isRead)) {
      step.errors.push({ tag: "forms", text: "You are required to open and review all forms in order to proceed to the next step." });
    }

    if (step.type === "coi") {

      if (step.hasCoi === null) {
        step.errors.push({ tag: "conflict", text: "You are required to make a selection in order to proceed to the next step." });
      } else if (step.hasCoi === true) {
        const confirm = await this.confirmationDialogService
          .confirm('Conflict of Interest', `Please confirm your conflict of interest with this submission.<br>Once you click CONFIRM below, this submission will no longer be in your queue and you will be assigned a new submission to review.`, 'Confirm', 'Cancel', '600px');
        if (confirm) {
          await this.judgmentService.doCOISwap(this.reviewId).toPromise()
          this.router.navigate([`evaluation/${this.stageId}`]);
        }
        return;
      } else if (step.hasCoi === false) {
        const confirm = await this.confirmationDialogService
          .confirm('Conflict of Interest', `Please confirm you do not have a conflict of interest with this submission.`, 'Confirm', 'Cancel', '400px');
        if (confirm) {
          step.hasCoiConfirmed = true;
          this.hasUnsavedData = true;
          this.$autoSave.next(step);
        } else {
          return;
        }
      }
    }

    if (step.type === "trait") {

      if (!step.rangeChanged) {
        step.errors.push({ tag: "slider", text: "You are required to give a score from 0 to 5." });
      }

      if (this.wordCount > 0 && (!step.comment || step.comment.trim().split(/\s+/).filter(s => s != '').length < this.wordCount)) {
        step.errors.push({ tag: "feedback", text: `You are required to give a comment of minimum ${this.wordCount} words.` });
      }

      if (step.comment && this.hasDuplicateComment(step)) {
        step.errors.push({ tag: "unique-feedback", text: "You are required to give a unique comment." })
      }
    }

    if (step.type === "overallimpression" && !step.rangeChanged) {
      step.errors.push({ tag: "slider", text: "You are required to give a score from 0 to 100." });
    }

    if (step.errors.length > 0) {
      this.stepper.selected.completed = false;
      return;
    }

    this.stepper.selected.completed = true;
    step.completed = true;
    this.stepper.next();
  }

  prepareSummaryStep(data: any[]) {
    const summary = [];
    data
      .filter((step: any) => step.type === 'trait' || step.type === 'overallimpression' || step.type === 'submit')
      .forEach((step: any) => {
        let sum = {
          score: step.type === 'submit' ? step.totalScore : step.score,
          criteria: step.type === 'submit' ? 'Total score' : step.title,
          step: step.stepCount,
          expanded: false,
          showFeedbackOverlay: step.comment && step.comment.length > 80 ? true : false,
          feedback: step.comment
        };
        summary.push(sum);
      });
    const submitStep = this.steps.find(step => step.type === 'submit');
    if (submitStep) {
      submitStep.summary = summary;
    }
  }

  formsClicked(step: any, formStageId: string) {
    if (step.forms?.every((step: any) => step.isRead)) {
      this.removeStepError("forms", step);
    }
    step.formStageId = formStageId;
    this.hasUnsavedData = true;
    this.$autoSave.next(step)
  }

  sliderDisplay(step: any) {
    // used to format the number that is displayed to the user
    return (value: any) => {
      return step.type === "trait" ? value.toFixed(1) : value;
    }
  }

  feedbackChange(feedback: string, step: any) {
    const count = this.countFeedbackWords(feedback);
    if (count >= this.wordCount){
       this.removeStepError("feedback", step);
    } else {
      this.stepper.selected.completed = false;
    }
    if (this.hasDuplicateComment(step)) {
      if (!step.errors.find(e => e.tag === "unique-feedback")) {
        step.errors.push({ tag: "unique-feedback", text: "You are required to give a unique comment." });
      }
      this.stepper.selected.completed = false;
    } else {
      this.removeStepError("unique-feedback", step);
    }
    this.hasUnsavedData = true;
    this.$autoSave.next(step)
  }

  hasDuplicateComment(step: any) {
    const exist = this.steps.find(s => s != step && s.type === "trait" && s.comment === step.comment);
    return exist ? true : false;
  }

  countFeedbackWords(str: string) {
    return str.trim().split(/\s+/).filter(s => s != '').length;
  }

  removeStepError(tag: string, step: any) {
    setTimeout(() => {
      const index = step.errors.findIndex(error => error.tag === tag);
      if (index !== -1) step.errors.splice(index, 1);
    }, 0)
  }

  sliderModelChange(step: any) {
    this.hasUnsavedData = true;
    this.$autoSave.next(step);
  }

  sliderChange(step: any, value: number) {
    if (!step.rangeChanged) {
      step.rangeChanged = true;
      this.removeStepError("slider", step);
      this.hasUnsavedData = true;
      this.$autoSave.next(step);
    }
    if (value !== undefined && value != null && step.type === 'trait') {
      step.rangeDefinition = step.rangeArray[Math.floor(value)].text;
    }
  }

  coiChange(step: any) {
    this.removeStepError('conflict', step)
    this.hasUnsavedData = true;
    this.$autoSave.next(step)
  }

  async saveAndExit() {
    if (this.stepper.selectedIndex + 1 === this.totalSteps) {
      this.confirmationDialogService.confirm('Review Complete', 'Thank you for completing review!<br/>Select YES to confirm this review is complete or select NO to make changes. You can also go back at any time before the evaluation deadline to edit your completed reviews.', 'Yes', 'No', '600px')
        .then(async (confirmed) => {
          if (confirmed) {
            await this.judgmentService.saveJudgementSteps({
              judgementId: this.reviewId,
              // Last step is #8
              step: 8,
            }).toPromise();
            this.router.navigate([`evaluation/${this.stageId}`]);
          }
        })
    } else {
      this.router.navigate([`evaluation/${this.stageId}`]);
    }
  }

  previous() {
    this.stepper.previous();
  }

  goToStep(step: number) {
    // dirty fix for judging with no COI enabled
    // this fixes non sequel stepCounts on steps
    if (this.steps.filter(step => step.type === 'coi').length > 0) {
      this.selectedStep = step - 1;
    } else {
      this.selectedStep = step - 2;
    }
  }

  expandFeedback(sum: any, index: number) {
    sum.expanded = !sum.expanded;
    const clickedTrait = document.querySelectorAll('.summary-table tr')[index + 1];
    const descHeight = clickedTrait.querySelector('.summary-feedback').clientHeight;
    const sumFeedbackWrap = clickedTrait.querySelector('.summary-feedback-wrap');

    let finalHeight: number;

    if (descHeight > 28) {
      finalHeight = descHeight + 5;
      if (sum.expanded === true) {
        sumFeedbackWrap.setAttribute('style', `height: ${finalHeight}px;`);
      } else {
        sumFeedbackWrap.setAttribute('style', '');
      }
    }
  }

  getFormUrl(form: any) {
    return `form/${form.formStageId}/display/${this.participantUserId}`;
  }

  ngOnDestroy() {
    // this.mediaSubscription.unsubscribe();
  }
}
