import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { take } from "rxjs/operators";

@Injectable({ 
    providedIn: 'root' 
})
export class FirebaseAuthWrapperService {

    constructor(
        private afAuth: AngularFireAuth
        ) {
    }

    async refreshJwt(force: boolean = true) {
        const user = await this.afAuth.currentUser;
        await user.getIdTokenResult(force);
    }

    async jwt$() {
        return this.afAuth.idTokenResult
    }

    async getJwt():Promise<string | null | undefined> {
        const jwt = await this.afAuth.idToken
        .pipe(
            take(1)
        )
        .toPromise();
        
        return jwt;
    }
}
